import { Button, Form, Input, Message, Modal, Select } from "@arco-design/web-react";
import { useState } from "react";
import _ from "lodash";

const FormItem = Form.Item;
const TextArea = Input.TextArea;

const SendEmailButton = ({ getEmailContent, getToList, sendEmail }) => {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [emailContent, setEmailContent] = useState();
    const [to, setTo] = useState();
    const [form] = Form.useForm();

    const getEmail = async () => {
        try {
            setLoading(true);
            const content = await getEmailContent();
            if (getToList) {
                const toList = await getToList();
                setTo(_.map(toList, (to) => {
                    return {
                        value: to.email,
                        label: `${to.lastName} ${to.firstName}<${to.email}>`
                    }
                }));
            } else {
                setTo(content.to);
            }
            setEmailContent(content);
            form.setFieldsValue(content);
            setVisible(true)
        } catch (e) {
            Message.error(e.message);
        } finally {
            setLoading(false);
        }
    }

    const send = async () => {
        try {
            const values = form.getFieldsValue();
            var content = { ...emailContent, ...values };
            await sendEmail(content);
            Message.success("Email Sent Successfully")
            setVisible(false);
        } catch (e) {
            Message.error(e.message);
        }
    }

    return (
        <>
            <Button type="primary" onClick={() => getEmail()} loading={loading}>Email</Button>
            <Modal title="Email" style={{ width: 800 }}
                visible={visible}
                onCancel={() => setVisible(false)}
                onOk={() => send()}>
                <Form form={form}>
                    <FormItem field="to" label="To" required rules={[{ required: true }]}>
                        <Select mode="multiple" options={to}
                            filterOption={(inputValue, option) =>
                                option.props.value.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0 ||
                                option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                            }
                        />
                    </FormItem>
                    <FormItem field="subject" label="Subject" required rules={[{ required: true }]}>
                        <Input />
                    </FormItem>
                    <FormItem field="body" label="Body" required rules={[{ required: true }]}>
                        <TextArea style={{ minHeight: 250 }} />
                    </FormItem>
                </Form>
            </Modal>
        </>
    )
}

export default SendEmailButton;