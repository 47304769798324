import { GLAPI } from "@/apis";
import { useCallback, useEffect, useState } from "react";
import { UITable } from "@/components/UI";
import { NumericUtil } from "@/utils";

const GLInfo = (props) => {
    const { type, from } = props;
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);

    const getGLList = useCallback(async () => {
        const values = await GLAPI.query(page, type, from);
        setData(values.records);
        setTotal(values.total);
    }, [page, type, from]);

    const descGLAmount = (glInfo) => {
        const amount = NumericUtil.currency(Math.abs(glInfo.totalAmount));
        return <span>{`${glInfo.itemName}: ${amount}`}</span>
    }

    useEffect(() => {
        getGLList();
    }, [getGLList])

    return (
        <UITable
            columns={[
                { title: "Account", dataIndex: "accountName" },
                { title: "Action", dataIndex: "actionDesc" },
                { title: "Amount", render: (col, record) => descGLAmount(record) },
                { title: "Create Time", dataIndex: "impactDate" }
            ]}
            data={data} total={total} onChange={(val) => setPage(val)}
        />
    )
}

export default GLInfo;