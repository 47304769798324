import numeral from "numeral";
import _ from "lodash";

const stdFormat = '0,0.00[000]';

export const NumericUtil = {
    f: (value, options) => {
        if (_.isNil(value)) return "";
        options = { ...options }
        return (options.prefix ?? "") + numeral(value).format(stdFormat);
    },
    currency: (value) => {
        if (value == null) return "";
        return "$" + numeral(value).format(stdFormat);
    },
    value: (str) => {
        var number = numeral(str);
        return number.value();
    }
}