import { Descriptions } from "@arco-design/web-react"
import { UITable, UITag } from "@/components/UI";
import { CustomerAPI } from "@/apis";
import { useCallback, useEffect, useState } from "react";
import Address from "@/components/Address";

export const CustomerInfo = (props) => {
    const { value } = props;
    const [customer, setCustomer] = useState({});

    const getCustomer = useCallback(async () => {
        const info = await CustomerAPI.get(value.id);
        setCustomer(info);
    }, [value]);

    useEffect(() => {
        getCustomer();
    }, [getCustomer])

    return (
        <>
            <Descriptions colon=":"
                column={2}
                data={[
                    { label: "Customer Name", value: customer.customerName },
                    { label: "Term", value: customer.termDesc },
                ]} />
            <Descriptions column={1} layout="vertical" colon=":"
                labelStyle={{ paddingRight: 36 }}
                data={[
                    {
                        label: "Main Contact",
                        value: <UITable columns={[
                            { title: "First Name", dataIndex: "firstName" },
                            { title: "Last Name", dataIndex: "lastName" },
                            { title: "Tags", render: (col, record) => <UITag type="tag" value={record.tags} /> },
                            { title: "Email", dataIndex: "email" },
                            { title: "Phone", dataIndex: "phone" },
                        ]} data={customer.contacts} />
                    }
                ]} />
            <Descriptions column={1} colon=":"
                labelStyle={{ paddingRight: 36 }}
                data={[
                    {
                        label: "Billing",
                        value: <Address value={{
                            address: customer.billingAddress,
                            city: customer.billingCity,
                            state: customer.billingState,
                            zip: customer.billingZip
                        }} inline />

                    }
                ]} />
            <Descriptions column={1} layout="vertical" colon=":"
                labelStyle={{ paddingRight: 36 }}
                data={[
                    {
                        label: "Shipping",
                        value: <UITable columns={[
                            { title: "Ship to Name", dataIndex: "contactName" },
                            { title: "Address", dataIndex: "address" },
                            { title: "City", dataIndex: "city" },
                            { title: "State", dataIndex: "state" },
                            { title: "Zip", dataIndex: "zip" },
                        ]} data={customer.shipAddresses} />
                    }
                ]} />


        </>
    )
}