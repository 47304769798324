import axios from "axios";
import qs from "qs";

export const baseUrl = process.env.REACT_APP_BASE_URL;

axios.defaults.withCredentials = true;

const instance = axios.create({
    baseURL: baseUrl,
    paramsSerializer: {
        serialize: (params) => qs.stringify(params, { indices: false })
    }
});

const handleResponse = (response) => {
    let responseBody = response.data;
    if (responseBody.status === "OK") {
        return responseBody.data;
    } else {
        let errorMsg = "[" + responseBody.status + "]" + responseBody.message
        console.error(errorMsg);
        return Promise.reject(responseBody);
    }
}

export const get = async (path, params, ...httpParams) => {
    try {
        let response = await instance.get(path, {
            params: params,
            headers: { 'falcontoken': localStorage.getItem("login.token") },
            ...httpParams
        });
        return handleResponse(response);
    } catch (err) {
        return Promise.reject(err.response.data);
    }
}

export const getBlob = async (path, params) => {
    try {
        let response = await instance.get(path, {
            params: params,
            headers: { 'falcontoken': localStorage.getItem("login.token") },
            responseType: 'blob'
        });
        return response;
    } catch (err) {
        return Promise.reject(err.response);
    }
}

export const post = async (path, params, ...httpParams) => {
    try {
        let response = await instance.post(path, params, {
            headers: { 'falcontoken': localStorage.getItem("login.token") },
            ...httpParams
        });
        return handleResponse(response);
    } catch (err) {
        return Promise.reject(err.response.data);
    }
}

export const postBlob = async (path, params) => {
    try {
        let response = await instance.post(path, params, {
            headers: { 'falcontoken': localStorage.getItem("login.token") },
            responseType: 'blob'
        });
        return response;
    } catch (err) {
        return Promise.reject(err.response.data);
    }
}

export const put = async (path, params) => {
    try {
        let response = await instance.put(path, params, {
            headers: { 'falcontoken': localStorage.getItem("login.token") }
        });
        return handleResponse(response);
    } catch (err) {
        return Promise.reject(err.response.data);
    }
}

export const del = async (path, params) => {
    try {
        let response = await instance.delete(path, {
            params: params,
            headers: { 'falcontoken': localStorage.getItem("login.token") }
        });
        return handleResponse(response);
    } catch (err) {
        return Promise.reject(err.response.data);
    }
}