import { Button, Card, Space, Modal, Link, Message } from "@arco-design/web-react";
import { UITable, UIText } from "@/components/UI";
import Status from "@/components/Status";
import { useCallback, useEffect, useRef, useState } from "react";
import { CustomerAPI } from "@/apis";
import { IconEdit } from "@arco-design/web-react/icon";
import { CustomerForm } from "./customer-form";
import { CustomerInfo } from "./customer-info";
import dayjs from "dayjs";

const CustomerLink = ({ value }) => {
    const [visible, setVisible] = useState(false);

    return (
        <>
            <Link onClick={() => setVisible(true)}>{value.customerName}</Link>
            <Modal title={value.customerName} style={{ width: 1100 }}
                visible={visible}
                onOk={() => setVisible(false)}
                onCancel={() => setVisible(false)}>
                <CustomerInfo value={value} />
            </Modal>
        </>

    );
}

const BalanceLink = ({ value }) => {
    const [visible, setVisible] = useState(false);
    const [balanceDetails, setBalanceDetails] = useState([]);

    const onClick = async () => {
        const res = await CustomerAPI.balanceDetails(value.id);
        setBalanceDetails(res);
        setVisible(true);
    }

    return (
        <>
            <Link onClick={onClick}>
                <UIText type="currency" value={value.balance} />
            </Link>
            <Modal title={value.customerName} style={{ width: 1100 }}
                visible={visible}
                onOk={() => setVisible(false)}
                onCancel={() => setVisible(false)}>
                <UITable columns={[
                    {
                        title: "Invoice#", dataIndex: "invoiceNo"
                    },
                    {
                        title: "Due Date",
                        render: (col, record) => <UIText type="date" value={record.dueDate} />
                    },
                    {
                        title: "Aging",
                        render: (col, record) => <span>{dayjs().diff(record.dueDate, 'day')} day(s)</span>
                    },
                    {
                        title: "Total Amount",
                        render: (col, record) => <UIText type="currency" value={record.totalAmount} />
                    },
                    {
                        title: "Amount Due",
                        render: (col, record) => <UIText type="currency" value={record.totalAmount} />
                    }
                ]} data={balanceDetails} />
            </Modal>
        </>
    );
}

const AddCustomerButton = ({ onOk }) => {
    const [visible, setVisible] = useState(false);
    const [customer, setCustomer] = useState();
    const formRef = useRef(null);

    const submit = async () => {
        try {
            const values = await formRef.current.getFormData();
            await CustomerAPI.add(values);
            Message.success("Customer created successfully");
            setVisible(false);
            onOk && onOk();
        } catch (error) {
            Message.error(error.message);
        }
    }

    return (
        <>
            <Button type="primary" onClick={() => {
                setCustomer({ term: 3, contacts: [{}] })
                setVisible(true);
            }}>Add New Customer</Button>
            <Modal title="Add New Customer" visible={visible} style={{ width: 1100 }}
                onCancel={() => setVisible(false)}
                onOk={() => submit()} >
                <CustomerForm value={customer} ref={formRef} />
            </Modal>
        </>
    )
}

const EditCustomerButton = ({ customerId, onOk }) => {
    const [visible, setVisible] = useState(false);
    const [customer, setCustomer] = useState();
    const formRef = useRef(null);

    const submit = async () => {
        try {
            const values = await formRef.current.getFormData();
            await CustomerAPI.update(customerId, values);
            Message.success("Customer updated successfully");
            setVisible(false);
            onOk && onOk();
        } catch (error) {
            Message.error(error.message);
        }
    }

    return (
        <>
            <Button type="text" size="small" onClick={async () => {
                const values = await CustomerAPI.get(customerId);
                setCustomer(values)
                setVisible(true);
            }} icon={<IconEdit />}>Edit</Button>
            <Modal title={customer?.customerName} visible={visible} style={{ width: 1100 }}
                onCancel={() => setVisible(false)}
                onOk={() => submit()} >
                <CustomerForm value={customer} ref={formRef} />
            </Modal>
        </>
    )
}

export const Customer = () => {
    const [status, setStatus] = useState(1);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);

    const getCustomers = useCallback(async () => {
        let res = await CustomerAPI.query(page, status);

        for (const index in res.records) {
            const customer = res.records[index];
            const balance = await CustomerAPI.balance(customer.id);
            customer.balance = balance;
        }

        setTotal(res.total);
        setData(res.records);
    }, [page, status])

    useEffect(() => {
        getCustomers();
    }, [getCustomers]);

    return (
        <Card title="Customer" extra={
            <Space>
                <Status.Select style={{ width: 100 }} value={status} allowAll
                    onChange={val => setStatus(val)}
                />
                <AddCustomerButton onOk={() => getCustomers()} />
            </Space>
        }>
            <UITable columns={[
                {
                    title: "Customer Name", dataIndex: "customerName",
                    render: (col, record) => <CustomerLink value={record} />
                },
                {
                    title: "Status",
                    render: (col, record) => <Status.Tag value={record.status}>{record.statusDesc}</Status.Tag>

                },
                {
                    title: "Balance",
                    render: (col, record) => <BalanceLink value={record} />
                },
                {
                    title: "Operations",
                    width: 100,
                    render: (col, record, index) => (
                        <Space size="mini">
                            <EditCustomerButton customerId={record.id} />
                        </Space>
                    )
                },
            ]} data={data} total={total}
                onChange={(pageNum) => {
                    setPage(pageNum);
                }} />

        </Card>
    );
}
