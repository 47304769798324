import { Button, Divider, Form, Input, Message, Modal, Space } from "@arco-design/web-react";
import { IconDelete, IconEdit } from "@arco-design/web-react/icon";
import { useCallback, useEffect, useState } from "react";
import { UserAPI, CompanyAPI } from "@/apis";
import { UITable } from "@/components/UI";

const FormItem = Form.Item;

export const UserForm = (props) => {
    const [form] = Form.useForm();
    const [id] = useState(props.companyId);
    const defaultUser = {
        "companyId": id,
    }
    const [data, setData] = useState([]);
    const [emailDisabled, setEmailDisabled] = useState(false);

    const onSubmit = async () => {
        let values = await form.validate();
        if (values.id) {
            await UserAPI.update(values);
            Message.success("User updated successfully!");
        } else {
            await UserAPI.add(values);
            Message.success("User created successfully!");
        }

        getUsers(id);
        clearForm();
    }

    const deleteUser = async (user) => {
        await UserAPI.delete(user.id);
        Message.success("User deleted successfully!");
        getUsers(id);
    }

    const getUsers = useCallback(async (companyId) => {
        let values = await CompanyAPI.users(companyId);
        setData(values);
    }, []);

    const clearForm = () => {
        form.resetFields();
        setEmailDisabled(false);
    }

    useEffect(() => {
        if (id) {
            getUsers(id);
        }
    }, [getUsers, id]);

    return (
        <Modal className="user-form" title="User Accounts" footer={null} onOk={onSubmit} visible onCancel={props.onCancel}>
            <UITable columns={[
                { title: "Name", dataIndex: "fullName" },
                { title: "Email", dataIndex: "email" },
                { title: "Phone", dataIndex: "phone" },
                {
                    title: "Operations", dataIndex: "op", width: 100, render: (_, record) => {
                        return (
                            <Space>
                                <Button type="text" onClick={() => {
                                    form.setFieldsValue(record);
                                    setEmailDisabled(true);
                                }} icon={<IconEdit />}>Edit</Button>
                                <Button type="text" status="danger"
                                    onClick={() => deleteUser(record)} icon={<IconDelete />}>Delete</Button>
                            </Space>
                        );
                    }
                }
            ]} data={data} rowKey="id"></UITable>
            <Divider />
            <Form form={form} initialValues={defaultUser} layout="horizontal" onSubmit={onSubmit}>
                <FormItem label="ID" field="id" hidden><Input /></FormItem>
                <FormItem label="CompanyId" field="companyId" hidden><Input /></FormItem>
                <FormItem label="Last Name" field="lastName" rules={[{ required: true, message: "Last name is required" }]}><Input /></FormItem>
                <FormItem label="First Name" field="firstName" rules={[{ required: true, message: "First name is required" }]}><Input /></FormItem>
                <FormItem
                    disabled={emailDisabled}
                    label="Email" field="email" rules={[{ required: true, message: "Email is required" }, { type: "email", message: "Invalid email type" }]}><Input /></FormItem>

                <FormItem label="Phone" field="phone" rules={[{ required: true, message: "Phone is required" }]}><Input /></FormItem>
                <FormItem wrapperCol={{ offset: 5 }}>
                    <Space>
                        <Button type="default" onClick={() => clearForm()}>Reset</Button>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Space>
                </FormItem>
            </Form>
        </Modal >
    );
}