import { useEffect } from "react";
import { useNavigate, useLocation, createSearchParams } from 'react-router-dom';
import { UserAPI } from '@/apis';
import { useDispatch } from "react-redux";
import { updateUserInfo } from '@/store/userInfoSlice';

export const useLogin = (accessNotLogin) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const allowGuest = accessNotLogin || false;

    useEffect(() => {
        const checkLogin = async () => {
            try {
                let info = await UserAPI.info();
                dispatch(updateUserInfo(info));
            } catch {
                if (allowGuest) return;
                navigate({
                    pathname: "/login",
                    search: createSearchParams({ callback: location.pathname }).toString()
                });
            }
        }

        checkLogin();
    }, [navigate, dispatch, location, allowGuest]);
}