import { Button, Card, Form, Grid, Modal, Space, Statistic, Table, Typography } from "@arco-design/web-react";
import { IconCheckCircleFill, IconPen } from "@arco-design/web-react/icon";
import { useCallback, useEffect, useState } from "react";
import { UIText, UIInputNumber, UIDatePicker } from "@/components/UI";
import { useParams, useNavigate } from "react-router-dom";
import { ReconciliationAPI, BankAPI } from "@/apis";
import _ from "lodash";
import Title from "@arco-design/web-react/es/Typography/title";

const { Row, Col } = Grid;
const { Text } = Typography;

const StatementBalanceEditButton = ({ reconciliationId, onSuccess, ...props }) => {
    const [visible, setVisible] = useState(false);
    const [account, setAccount] = useState({});
    const [form] = Form.useForm();

    const getReconciliation = useCallback(async () => {
        const reconciliation = await ReconciliationAPI.get(reconciliationId);
        const account = await BankAPI.account(reconciliation.bankAccountId);
        setAccount(account);
        form.setFieldValue("statementDate", reconciliation.endDate);
        form.setFieldValue("statementBalance", reconciliation.statementBalance);
    }, [reconciliationId, form]);

    const onOk = async () => {
        const values = await form.validate();
        await ReconciliationAPI.updateStatementDateAndBalance(reconciliationId, values.statementDate, values.statementBalance);
        setVisible(false);
        onSuccess && onSuccess();
    }

    useEffect(() => {
        getReconciliation();
    }, [getReconciliation])

    return (
        <>
            <Button type="text" icon={<IconPen style={{ fontSize: 22 }} />} onClick={() => {
                setVisible(true);
            }} />
            <Modal title="Reconciliation" visible={visible}
                onOk={onOk}
                onCancel={() => {
                    setVisible(false);
                }}
            >
                <Typography>
                    <Title heading={5}>{_.capitalize(account.subType)} **** {account.mask}</Title>
                    <Text>Enter the ending balance as it appears on your statement</Text>
                </Typography>
                <Form
                    style={{ marginTop: 28 }}
                    layout="vertical"
                    form={form}
                >
                    <Form.Item label="Statement Date" field="statementDate">
                        <UIDatePicker />
                    </Form.Item>
                    <Form.Item label="Balance Amount" field="statementBalance">
                        <UIInputNumber />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export const ReconciliationDetail = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [reconciliationId] = useState(params.reconciliationId);
    const [reconciliation, setReconciliation] = useState({});
    const [account, setAccount] = useState({});
    const [transactions, setTransactions] = useState([]);
    const [pagination, setPagination] = useState();

    const getTransactions = useCallback(async (page) => {
        const transactions = await ReconciliationAPI.getTransactions(reconciliationId, page);
        setTransactions(transactions.records);
        setPagination((pagination) => ({
            ...pagination,
            current: page,
            total: transactions.total
        }));
    }, [reconciliationId]);

    const getReconciliation = useCallback(async () => {
        const reconciliation = await ReconciliationAPI.get(reconciliationId);
        const account = await BankAPI.account(reconciliation.bankAccountId);
        setReconciliation(reconciliation);
        setAccount(account);
        getTransactions(1);
    }, [reconciliationId, getTransactions]);

    const onReconcile = async () => {
        await ReconciliationAPI.confirm(reconciliationId);
        navigate("/workspace/accounting/reconciliation");
    }

    const updateTransactionStatus = async (transactionId, cleared) => {
        await ReconciliationAPI.updateTransactionStatus(reconciliationId, transactionId, cleared);
        getTransactions(pagination.current);
    }

    useEffect(() => {
        getReconciliation();
    }, [getReconciliation]);

    return (
        <Card title={_.capitalize(account.subType) + `**** ${account.mask} Reconciliation`}
            extra={
                <Space>
                    {reconciliation.status !== 1 &&
                        <Button type="primary"
                            disabled={reconciliation.difference !== 0} onClick={() => onReconcile()}>Reconcile</Button>
                    }
                    <Button onClick={() => navigate(`/workspace/accounting/reconciliation`)}>Back</Button>
                </Space>
            }>
            <Row>
                <Text>Transactions between {reconciliation.startDate} and {reconciliation.endDate}</Text>
            </Row>
            <Row style={{ marginTop: 16 }}>
                <Col span={24} style={{ textAlign: "center" }}>
                    <Space size={60}>
                        <Statistic title="Statement Balance" value={
                            <Space>
                                <UIText type="currency" value={reconciliation.statementBalance} />
                                {reconciliation.status !== 1 &&
                                    <StatementBalanceEditButton reconciliationId={reconciliationId}
                                        onSuccess={() => getReconciliation()}
                                    />
                                }
                            </Space>
                        } />
                        <Statistic title="System Balance" value={<UIText type="currency" value={reconciliation.systemBalance} />} />
                        <Statistic title="Difference"
                            styleValue={{ color: reconciliation.difference === 0 ? "var(--color-success-light-4)" : "var(--color-warning-light-4)" }}
                            value={<UIText type="currency" value={reconciliation.difference} />} />
                    </Space>
                </Col>
            </Row>
            <Row style={{ marginTop: 16 }}>
                <Col span={24}>
                    <Table
                        data={transactions}
                        columns={[{
                            title: 'Date',
                            dataIndex: 'date',
                        }, {
                            title: 'Description',
                            dataIndex: 'description',
                        }, {
                            title: 'Income',
                            render: (text, record) =>
                                record.type === 1 &&
                                <UIText type="currency" value={record.amount} />
                        }, {
                            title: 'Expense',
                            render: (text, record) =>
                                record.type === 2 &&
                                <UIText type="currency" value={record.amount} />
                        }, {
                            title: 'Balance',
                            render: (text, record) => {
                                return (
                                    <UIText type="currency" value={record.balance} />
                                )
                            }
                        }, {
                            title: 'Cleared',
                            render: (text, record) => {
                                return (
                                    <Button type="text"
                                        style={{ color: record.reconciliationCleared === true ? 'var(--color-success-light-4)' : 'var(--color-fill-4)' }}
                                        icon={<IconCheckCircleFill />}
                                        onClick={() => updateTransactionStatus(record.id, !record.reconciliationCleared ?? true)}></Button>
                                )
                            }
                        }]}
                        pagination={pagination}
                        onChange={(pagination) => {
                            getTransactions(pagination.current);
                        }}
                    />
                </Col>
            </Row>
        </Card>
    )
}

