import { Button, Message, Modal, Space, Upload } from "@arco-design/web-react";
import { useState } from "react";
import { ItemAPI } from "@/apis";
import { baseUrl } from "@/utils/api";
import { IconBook, IconUpload } from "@arco-design/web-react/icon";
import { UIEditableTable, UIDatePicker } from "@/components/UI";

const ItemDocButton = ({ itemId }) => {
    const [data, setData] = useState([]);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [visible, setVisible] = useState(false);

    const getDocs = async () => {
        let docs = await ItemAPI.getDocuments(itemId);
        setData(docs);
    }

    const afterDocUpload = (files, file) => {
        switch (file.status) {
            case "error":
                setConfirmLoading(false);
                Message.error("Document upload failed");
                break;
            case "done":
                setConfirmLoading(false);
                getDocs();
                Message.success("Document upload successfully");
                break;
            default:
                break;
        }
    }

    const updateDoc = async (row) => {
        await ItemAPI.updateDocument(row.id, row);
        Message.success("Document updated successfully");
        getDocs();
    }

    const deleteDoc = async (id) => {
        await ItemAPI.deleteDocument(id);
        Message.success("Document deleted successfully");
        setData(data.filter(item => item.id !== id));
    }

    const columns = [
        { title: "Name", dataIndex: "document.docName" },
        {
            title: "Expiration Date", dataIndex: "expiredDate",
            editableRender: (col, record) => (
                <UIDatePicker style={{ width: 200 }} />
            )
        },
    ];

    return (
        <>
            <Button type="text" icon={<IconBook />} size="small"
                onClick={() => {
                    getDocs();
                    setVisible(true);
                }}>Documents</Button>
            <Modal title="Item Documents" visible={visible}
                style={{ width: 700 }}
                onCancel={() => setVisible(false)}
                footer={
                    <Space>
                        <Button onClick={() => setVisible(false)}>Close</Button>
                        <Upload withCredentials={true} showUploadList={null}
                            action={`${baseUrl}/item/document?itemId=${itemId}`}
                            headers={{ 'falcontoken': localStorage.getItem("login.token") }}
                            beforeUpload={() => setConfirmLoading(true)}
                            onChange={afterDocUpload}>
                            <Button type="primary" icon={<IconUpload />}
                                loading={confirmLoading}>Upload Document</Button>
                        </Upload>
                    </Space>
                }
            >
                <UIEditableTable columns={columns} data={data} rowKey="id"
                    onRowDelete={row => deleteDoc(row.id)}
                    onRowSave={updateDoc}></UIEditableTable>
            </Modal >
        </>
    );
}

export default ItemDocButton;