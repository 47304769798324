import { useEffect, useState } from "react";
import { CustomerAPI } from "@/apis";
import { UISelect } from "@/components/UI";
import _ from "lodash";

const CustomerSelect = (props) => {
    const { type, ...restProps } = props;
    const [options, setOptions] = useState([]);

    useEffect(() => {
        const getOptions = async () => {
            const values = await CustomerAPI.actives();
            setOptions(_.orderBy(values, ['customerName'], ['asc']));
        }

        getOptions();
    }, [type]);

    return (
        <UISelect {...restProps}
            showSearch
            filterOption={(inputValue, option) =>
                _.toLower(option.props.value).indexOf(inputValue.toLowerCase()) >= 0 ||
                option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
            }
            records={options}
            labelKey="customerName"
            onChange={(val) => props.onChange && props.onChange(val)}>
        </UISelect>
    );
}

export default CustomerSelect;