import moment from "moment";

export const datePattern = 'l';
export const datetimePattern = 'M/D/YYYY HH:mm:ss';

export const DateUtil = {
    format: (value) => {
        return moment(value).format(datePattern);
    },
    today: () => {
        return moment().format(datePattern);
    },
    date: (value) => {
        return moment(value, datePattern);
    }
}

export const DateTimeUtil = {
    format: (value) => {
        return moment(value).format(datetimePattern);
    },
    get: (value) => {
        return moment(value, datePattern);
    }

}