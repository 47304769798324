import { Button, Card, Descriptions, Message, Popconfirm, Space, Table } from "@arco-design/web-react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { JournalAPI } from "@/apis";
import { UIText, UITable } from "@/components/UI";
import { useCallback, useEffect, useRef, useState } from "react";
import { JournalEntryForm } from "./journal-entry-form";
import _ from "lodash";

export const JournalEntryInfo = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [entryId] = useState(params.entryId);
    const [info, setInfo] = useState({});
    const [editing, setEditing] = useState(false);

    const formRef = useRef(null);

    const getEntry = useCallback(async () => {
        const values = await JournalAPI.get(entryId);
        _.forEach(values.details, o => {
            o.credit = Math.abs(o.credit);
            o.debit = Math.abs(o.debit);
        });
        setInfo(values);
    }, [entryId])

    const summary = (currentData) => {
        return (
            <Table.Summary.Row>
                <Table.Summary.Cell>Total</Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell>
                    <UIText type="currency" value={currentData.reduce((prev, next) => prev + next.debit, 0)} />
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                    <UIText type="currency" value={currentData.reduce((prev, next) => prev + next.credit, 0)} />
                </Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
            </Table.Summary.Row>
        );
    }

    const getMonthNames = (values) => {
        const names = _.map(values, (o) => {
            switch (o) {
                case 1:
                    return "Jan";
                case 2:
                    return "Feb";
                case 3:
                    return "Mar";
                case 4:
                    return "Apr";
                case 5:
                    return "May";
                case 6:
                    return "Jun";
                case 7:
                    return "Jul";
                case 8:
                    return "Aug";
                case 9:
                    return "Sep";
                case 10:
                    return "Oct";
                case 11:
                    return "Nov";
                case 12:
                    return "Dec";
                default:
                    return "";
            }
        })
        return _.join(names, ", ");
    }

    const save = async () => {
        const result = await formRef.current.submit();
        if (result) {
            setEditing(false);
            getEntry();
            Message.success("Journal entry updated successfully");
        }
    }

    const deleteEntry = async () => {
        await JournalAPI.del(entryId);
        navigate("/workspace/accounting/journal-entry")
    }

    useEffect(() => {
        getEntry();
    }, [getEntry])

    return (
        <Card title="Journal Entry" extra={
            editing ?
                <Space>
                    <Button type="primary" onClick={() => save()}>Save</Button>
                    <Button onClick={() => setEditing(false)}>Cancel</Button>
                </Space>
                :
                <Space>
                    <Button type="primary" onClick={() => setEditing(true)}>Edit</Button>
                    <Popconfirm
                        title="Confirm"
                        content="Are you sure you want to delete journal entry?"
                        onOk={() => deleteEntry()}
                    >
                        <Button status="danger" >Delete</Button>
                    </Popconfirm>
                    <Link to="/workspace/accounting/journal-entry" >
                        <Button type="default">Back</Button>
                    </Link>
                </Space>
        }>
            {!editing ?
                <>
                    <Descriptions colon=": " data={[
                        { label: "Date", value: info.date },
                        { label: "Description", value: info.description },
                    ]}
                    />
                    <UITable columns={[
                        { title: "Description", dataIndex: "description" },
                        { title: "Account", dataIndex: "accountName" },
                        { title: "Debit", render: (col, record) => <UIText type="currency" value={record.debit} /> },
                        { title: "Credit", render: (col, record) => <UIText type="currency" value={record.credit} /> },
                        { title: "Customer/Vendor", dataIndex: "sourceName" }
                    ]} data={info.details} pagination={false} summary={summary} />
                    {info.recurring &&
                        <Descriptions colon=": " column={1} data={[
                            { label: "Every", value: info.every + " Month(s)" },
                            { label: "Skip", value: getMonthNames(info.skip) },
                            { label: "Start Date", value: info.startDate },
                            { label: "End At", value: info.endAt },
                            { label: "Recurring Times", value: info.recurrenceTimes },
                        ]}
                        />
                    }
                </>
                : <JournalEntryForm ref={formRef} value={info} />
            }
        </Card>
    )
}