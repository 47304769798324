import { useCallback, useEffect, useState } from "react";
import { Button, Space, Card, Input } from "@arco-design/web-react";
import { CompanyAPI } from "@/apis";
import { UITable } from "@/components/UI";
import { CompanyForm } from "./company-form";
import { UserForm } from "./user-form";
import { IconEdit, IconUser } from "@arco-design/web-react/icon";

const InputSearch = Input.Search;

export const Company = () => {
    const [keyword, setKeyword] = useState();
    const columns = [
        { title: "Company Name", dataIndex: "companyName" },
        { title: "Business Industry", dataIndex: "industry" },
        {
            title: "Operations",
            dataIndex: "op",
            width: 100,
            render: (_, record) => (
                <Space>
                    <Button type="text" icon={<IconEdit />} onClick={() => {
                        setCompanyFormData(record);
                        setCompanyFormVisible(true);
                    }}>Edit</Button>
                    <Button type="text" icon={<IconUser />} onClick={() => {
                        setCompanyId(record.id);
                        setUserFormVisible(true);
                    }}>User Accounts</Button>
                </Space>
            )
        },
    ]
    const [total, setTotal] = useState(0);
    const [data, setData] = useState([]);
    const [companyFormData, setCompanyFormData] = useState();
    const [companyFormVisible, setCompanyFormVisible] = useState(false);
    const [userFormVisible, setUserFormVisible] = useState(false);
    const [companyId, setCompanyId] = useState();

    const refreshTable = useCallback(async (pageNum, companyName) => {
        let res = await CompanyAPI.list(pageNum, { "companyName": companyName });
        setTotal(res.total);
        setData(res.records);
    }, []);

    useEffect(() => {
        refreshTable(1, keyword);
    }, [refreshTable, keyword]);

    const Actions = () => {
        return (
            <Space>
                <InputSearch defaultValue={keyword} searchButton placeholder="Search..."
                    onSearch={(value) => {
                        setKeyword(value);
                    }} />
                <Button type="primary" onClick={() => {
                    setCompanyFormData({})
                    setCompanyFormVisible(true);
                }}>Add Company</Button>
            </Space>
        );
    }

    return (
        <div>
            <Card className="content-card" extra={<Actions />}>
                {companyFormVisible && (
                    <CompanyForm data={companyFormData} onSubmit={() => {
                        setCompanyFormVisible(false);
                        refreshTable(1, keyword);
                    }}
                        onCancel={() => setCompanyFormVisible(false)} />
                )}
                {userFormVisible && (
                    <UserForm companyId={companyId}
                        onCancel={() => setUserFormVisible(false)} />
                )}
                <UITable
                    columns={columns}
                    data={data}
                    total={total}
                    rowKey="id"
                    onChange={(page) => refreshTable(page, keyword)}
                />
            </Card>

        </div>
    );
}