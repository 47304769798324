import { Button, Card, Form, Input, Message, Modal, Space } from "@arco-design/web-react"
import { UITable } from "@/components/UI";
import { CompanyAPI, UserAPI } from "@/apis";
import { useCallback, useEffect, useState } from "react";

const FormItem = Form.Item;

export const Users = (props) => {
    const [data, setData] = useState([]);
    const [userFormVisible, setUserFormVisible] = useState(false);
    const [userForm] = Form.useForm();

    const getUsers = useCallback(async () => {
        const values = await CompanyAPI.users();
        setData(values);
    }, []);

    const resetPassword = async (id) => {
        await UserAPI.resetPassword(id);
        Message.success("Password reset successfully");
    }

    const deleteUser = async (id) => {
        await CompanyAPI.deleteUser(id);
        getUsers();
    }

    const addUser = async () => {
        const values = await userForm.validate();
        await CompanyAPI.addUser(values);
        setUserFormVisible(false);
        getUsers();
    }

    useEffect(() => {
        getUsers();
    }, [getUsers])

    return (
        <Card title="Users" extra={
            <Space>
                <Button type="primary" onClick={() => {
                    userForm.resetFields();
                    setUserFormVisible(true);
                }}>Add New User</Button>
            </Space>
        }>
            <UITable columns={[
                { title: "Name", dataIndex: "fullName" },
                { title: "Email/User Name", dataIndex: "userName" },
                {
                    title: "Operation", dataIndex: "op", width: 100,
                    render: (col, record, index) => {
                        return (
                            <Space>
                                <Button type="primary" status="warning" size="mini"
                                    onClick={() => resetPassword(record.id)}>Reset Password</Button>
                                <Button type="primary" status="danger" size="mini"
                                    onClick={() => deleteUser(record.id)}>Delete</Button>
                            </Space>
                        )
                    }
                },
            ]} data={data} />
            {userFormVisible &&
                <Modal title="User" visible
                    onConfirm={() => addUser()}
                    onCancel={() => setUserFormVisible(false)}>
                    <Form form={userForm} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
                        <FormItem label="First Name" field="firstName" required rules={[{ required: true }]}>
                            <Input />
                        </FormItem>
                        <FormItem label="Last Name" field="lastName" required rules={[{ required: true }]}>
                            <Input />
                        </FormItem>
                        <FormItem label="Email" field="email" required rules={[{ required: true }, { type: "email" }]}>
                            <Input />
                        </FormItem>
                    </Form>
                </Modal>
            }
        </Card>

    )
}