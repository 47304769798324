import { Button, Form, Input, Layout, Message } from "@arco-design/web-react"
import { Header } from '@/components/Header';
import Title from "@arco-design/web-react/es/Typography/title";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserAPI } from "@/apis";

const FormItem = Form.Item;

export const ForgotPassword = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const onFormSubmit = async () => {
        setLoading(true);
        try {
            const values = await form.validate();
            await UserAPI.applyResetPassword(values.username);
        } catch (error) {

        } finally {
            setLoading(false);
        }

        Message.info("You will receive a password recovery link at your email address in a few minutes.");
        navigate("/login");
    }

    return (
        <div className="container">
            <Layout>
                <Layout.Header>
                    <Header />
                </Layout.Header>
                <Layout.Content className="home-page">
                    <div style={{ width: 500, marginLeft: 200 }}>
                        <Title>Forgot Password</Title>
                        <Form colon style={{ marginTop: 50 }} form={form}>
                            <FormItem label="Username" field="username" required rules={[{ required: true }]}>
                                <Input />
                            </FormItem>
                            <FormItem wrapperCol={{ offset: 5 }}>
                                <Button type="primary" loading={loading} onClick={onFormSubmit}>Reset Password</Button>
                            </FormItem>
                        </Form>
                    </div>
                </Layout.Content>
            </Layout>
        </div>
    )
}