import { Button, Card, Input, Link, Modal, Space, Table } from "@arco-design/web-react";
import Status from "@/components/Status";
import SaleOrderLink from "@/components/SaleOrderLink";
import { IconSearch } from "@arco-design/web-react/icon";
import { useCallback, useEffect, useState } from "react";
import { UIText } from "@/components/UI";
import { InventoryAPI } from "@/apis";
import { ItemLink } from "@/components/ItemLink";
import { NavLink } from "react-router-dom";
import _ from "lodash";

const CommittedLink = ({ itemId, children }) => {
    const [visible, setVisible] = useState(false);
    const [data, setData] = useState();

    const getCommitted = async () => {
        const values = await InventoryAPI.getItemCommitted(itemId);
        setData(values);
    }

    return (
        <>
            <Link onClick={() => {
                getCommitted();
                setVisible(true);
            }}>{children}</Link>
            <Modal visible={visible}
                title="Item Committed"
                style={{ width: 600 }}
                footer={
                    <Button type="primary" onClick={() => setVisible(false)}>Close</Button>
                }
                onCancel={() => setVisible(false)}>
                <Table columns={[
                    { title: "SO#", render: (col, record) => <SaleOrderLink soId={record.soId}>{record.soNo}</SaleOrderLink> },
                    { title: "Customer", dataIndex: "customerName" },
                    { title: "Quantity", render: (col, record) => <UIText type="number" value={record.quantity} /> }
                ]} data={data} rowKey="soId" />
            </Modal>
        </>
    )
}

const OnOrderLink = ({ itemId, children }) => {
    const [visible, setVisible] = useState(false);
    const [data, setData] = useState();

    const getDetails = async () => {
        const values = await InventoryAPI.getItemOnOrder(itemId);
        setData(values);
    }

    return (
        <>
            <Link onClick={() => {
                getDetails();
                setVisible(true);
            }}>{children}</Link>
            <Modal visible={visible}
                title="Item Committed"
                style={{ width: 600 }}
                footer={
                    <Button type="primary" onClick={() => setVisible(false)}>Close</Button>
                }
                onCancel={() => setVisible(false)}>
                <Table columns={[
                    { title: "PO#", render: (col, record) => <NavLink to={`/workspace/purchasing/po/${record.poId}`}>{record.poNo}</NavLink> },
                    { title: "Vendor", dataIndex: "vendorName" },
                    { title: "Quantity", render: (col, record) => <UIText type="number" value={record.quantity} /> }
                ]} data={data} rowKey="soId" />
            </Modal>
        </>
    )
}

export const Inventory = () => {
    const [itemName, setItemName] = useState();
    const [status, setStatus] = useState(1);
    const [data, setData] = useState([]);

    const getItems = useCallback(async () => {
        const values = await InventoryAPI.getItems({ itemName, status });
        let items = _.sortBy(values.records, "itemName");
        setData(items);
    }, [itemName, status]);

    useEffect(() => {
        getItems();
    }, [getItems])

    return (
        <Card title="Inventory" extra={
            <Space>
                <Input placeholder="Item Name" onChange={(val) => setItemName(val)} />
                <Status.Select allowAll value={status} onChange={(val) => setStatus(val)} />
                <Button type="primary" icon={<IconSearch />} onClick={() => getItems(itemName, status)} />
            </Space>
        }>
            <Table columns={[
                {
                    title: "Item Number",
                    render: (col, record) => <ItemLink value={record.itemId}>{record.itemNo}</ItemLink>,
                    sorter: (a, b) => {
                        if (a.itemNo > b.itemNo) {
                            return 1;
                        } else if (a.itemNo < b.itemNo) {
                            return -1;
                        } else {
                            return 0;
                        }
                    }
                },
                {
                    title: "Item Name",
                    dataIndex: "itemName",
                    sorter: (a, b) => {
                        if (a.itemName > b.itemName) {
                            return 1;
                        } else if (a.itemName < b.itemName) {
                            return -1;
                        } else {
                            return 0;
                        }
                    }

                },
                { title: "Item Description", dataIndex: "description" },
                {
                    title: "Inventory OnHand",
                    render: (col, record) => <UIText type="number" value={record.onHand} />,
                    sorter: (a, b) => a.onHand - b.onHand
                },
                {
                    title: "Inventory NotAvailable",
                    render: (col, record) => <UIText type="number" value={record.notAvailable} />,
                    sorter: (a, b) => a.notAvailable - b.notAvailable
                },
                {
                    title: "Inventory Committed", render: (col, record) =>
                        <CommittedLink itemId={record.itemId}>
                            <UIText type="number" value={record.committed} />
                        </CommittedLink>,
                    sorter: (a, b) => a.committed - b.committed
                },
                {
                    title: "Inventory Available",
                    render: (col, record) => <UIText type="number" value={record.available} />,
                    sorter: (a, b) => a.available - b.available
                },
                {
                    title: "Inventory On Order", render: (col, record) =>
                        <OnOrderLink itemId={record.itemId}>
                            <UIText type="number" value={record.onOrder} />
                        </OnOrderLink>,
                    sorter: (a, b) => a.onOrder - b.onOrder
                },
            ]}
                rowKey="itemId" data={data}
            />
        </Card>
    );
}