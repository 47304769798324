import { Button, Card, Form, Input, Message, Notification, Space } from "@arco-design/web-react";
import { useCallback, useEffect } from "react";
import { SettingAPI } from "@/apis";
import { IconCopy, IconEyeInvisible } from "@arco-design/web-react/icon";
import copy from 'copy-to-clipboard';

const FormItem = Form.Item;
const FormList = Form.List;

export const Settings = () => {
    const [form] = Form.useForm();

    const getSettings = useCallback(async () => {
        const values = await SettingAPI.getSettings();
        form.setFieldsValue({
            settings: values
        });
    }, [form])

    const save = async () => {
        try {
            const values = await form.validate();
            await SettingAPI.save(values.settings);
            Message.success("Settings saved successfully");
        } catch (error) {
            Message.error(error);
        }
    }

    useEffect(() => {
        getSettings();
    }, [getSettings])

    return (
        <Card title={
            <>
                <IconEyeInvisible style={{ marginRight: 5 }} />Settings
            </>
        } extra={
            <Space>
                <Button type="primary" onClick={() => save()}>Save</Button>
            </Space>
        }>
            <Space>
                <Form form={form} colon>
                    <FormItem>
                        <FormList field="settings">
                            {(fields, { add, remove, move }) => (
                                <>
                                    {fields.map((item, index) => (
                                        <Space key={item.key}>
                                            <FormItem field={`${item.field}.id`} hidden>
                                                <Input />
                                            </FormItem>
                                            <FormItem
                                                labelCol={{ span: 6 }}
                                                wrapperCol={{ span: 18 }}
                                                style={{ width: 800 }}
                                                label={form.getFieldValue(`${item.field}.settingName`)}
                                                field={`${item.field}.settingValue`}>
                                                <Input suffix={<IconCopy onClick={() => {
                                                    copy(form.getFieldValue(`${item.field}.settingValue`));
                                                    Notification.info({
                                                        title: "Notification",
                                                        content: form.getFieldValue(`${item.field}.settingName`) + " Copied!"
                                                    });
                                                }} />} />
                                            </FormItem>
                                        </Space>
                                    ))}
                                </>
                            )}
                        </FormList>
                    </FormItem>
                </Form>

            </Space>
        </Card>
    );
}