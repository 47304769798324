import { Button, Card, Form, Input, InputNumber, Message, Select, Space, Statistic } from "@arco-design/web-react";
import { JournalAPI, VendorAPI, CustomerAPI, AccountAPI } from "@/apis";
import { UIDatePicker, UIInputNumber, UIText, UISelect } from "@/components/UI";
import AccountSelect from "@/components/AccountSelect";
import { IconLeft, IconRight, IconDelete, IconPause, IconPlus } from "@arco-design/web-react/icon";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import _ from "lodash";
import { DateUtil } from "@/utils";

const FormItem = Form.Item;

export const JournalEntryForm = forwardRef((props, ref) => {
    const { value } = props;
    const [form] = Form.useForm();
    const [totalDebits, setTotalDebits] = useState(0);
    const [totalCredits, setTotalCredits] = useState(0);
    const [difference, setDiference] = useState(totalCredits - totalDebits);
    const [recurring, setRecurring] = useState(false);
    const [customers, setCustomers] = useState([]);
    const [vendors, setVendors] = useState([])
    const [sourceOptions, setSourceOptions] = useState([]);

    const calcTotal = useCallback(() => {
        const details = form.getFieldValue("details");
        const debits = _.sumBy(details, o => o.debit ?? 0);
        const credits = _.sumBy(details, o => o.credit ?? 0);
        const diff = credits - debits;
        setTotalDebits(debits);
        setTotalCredits(credits);
        setDiference(diff);
    }, [form]);

    const getEntry = useCallback(async () => {
        if (value && value.id) {
            const values = await JournalAPI.get(value.id);
            _.forEach(values.details, o => {
                o.credit = Math.abs(o.credit);
                o.debit = Math.abs(o.debit);
            });
            const options = [];
            _.forEach(values.details, (o, index) => {
                if (o.sourceType === 1) {
                    options[index] = vendors;
                } else if (o.sourceType === 2) {
                    options[index] = customers;
                }
            });
            setSourceOptions(options);
            form.setFieldsValue(values);
            setRecurring(values.recurring);
            calcTotal();
        } else {
            const values = {
                date: DateUtil.today(),
                recurring: false,
                details: [{}, {}],
                ...value
            };
            form.setFieldsValue(values);
        }
    }, [form, value, calcTotal, vendors, customers]);

    const onAccountChange = async (field, index, val) => {
        const account = await AccountAPI.get(val);
        const options = _.slice(sourceOptions);
        if (account.accountName === "Revenue") {
            form.setFieldValue(`${field}.sourceType`, 2);
            options[index] = customers;
        } else if (account.category === 2) {
            form.setFieldValue(`${field}.sourceType`, 1);
            options[index] = vendors;
        }

        setSourceOptions(options);
    }

    const submit = async () => {
        const values = await form.validate();
        if (difference !== 0) {
            Message.warning("Debits must be equal to credits!");
            return false;
        }
        if (values.id) {
            await JournalAPI.update(values.id, values);
        } else {
            await JournalAPI.add(values);
        }

        return true;
    }

    const mutexEntry = (field, debit) => {
        if (debit) {
            form.clearFields(`${field}.credit`);
        } else {
            form.clearFields(`${field}.debit`);
        }
    }

    useEffect(() => {
        const getOptions = async () => {
            const customers = await CustomerAPI.actives();
            setCustomers(_.map(customers, o => ({ label: o.customerName, value: o.id })));

            const vendors = await VendorAPI.actives()
            setVendors(_.map(vendors, o => ({ label: o.vendorName, value: o.id })));


        }

        getOptions();
    }, []);

    useEffect(() => {
        getEntry();
    }, [getEntry])

    useImperativeHandle(ref, () => ({ submit }));

    return (
        <Card>
            <Form colon form={form} labelCol={{ span: 3 }} wrapperCol={{ span: 21 }}>
                <FormItem field="id" hidden>
                    <Input />
                </FormItem>
                <FormItem label="Date" field="date" rules={[{ required: true }]}>
                    <UIDatePicker />
                </FormItem>
                <FormItem label="Description" field="description">
                    <Input.TextArea />
                </FormItem>
                <FormItem wrapperCol={{ span: 24 }}>
                    <Form.List field="details">
                        {(fields, { add, remove, move }) => {
                            return (
                                <table width="100%">
                                    <thead>
                                        <tr>
                                            <th>Description</th>
                                            <th style={{ width: 280 }}>Account</th>
                                            <th style={{ width: 180 }}>Debit</th>
                                            <th style={{ width: 180 }}>Credit</th>
                                            <th style={{ width: 200 }}>Customer/Vendor</th>
                                            <td style={{ width: 30 }}></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {fields.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        <FormItem field={`${item.field}.description`}>
                                                            <Input />
                                                        </FormItem>
                                                    </td>
                                                    <td>
                                                        <FormItem field={`${item.field}.accountId`}
                                                            rules={[{ required: true }]}>
                                                            <AccountSelect showSearch
                                                                onChange={(val) => onAccountChange(item.field, index, val)} />
                                                        </FormItem>
                                                    </td>
                                                    <td>
                                                        <FormItem field={`${item.field}.debit`}>
                                                            <UIInputNumber placeholder="Debit"
                                                                onChange={(val) => {
                                                                    mutexEntry(item.field, true);
                                                                    calcTotal();
                                                                }} />

                                                        </FormItem>
                                                    </td>
                                                    <td>
                                                        <FormItem field={`${item.field}.credit`}>
                                                            <UIInputNumber placeholder="Credit"
                                                                onChange={(val) => {
                                                                    mutexEntry(item.field, false);
                                                                    calcTotal();
                                                                }} />

                                                        </FormItem>
                                                    </td>
                                                    <td>
                                                        <FormItem field={`${item.field}.sourceType`} hidden>
                                                            <Input />
                                                        </FormItem>
                                                        <FormItem field={`${item.field}.source`}>
                                                            <UISelect options={sourceOptions[index]} showSearch />
                                                        </FormItem>
                                                    </td>
                                                    <td>
                                                        <FormItem>
                                                            <Button type="text" icon={<IconDelete />} status="danger"
                                                                onClick={() => {
                                                                    remove(index);
                                                                    const options = sourceOptions.slice();
                                                                    _.pullAt(options, index);
                                                                    setSourceOptions(options);
                                                                }} />
                                                        </FormItem>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        <tr>
                                            <td>
                                                <Button type="outline" size="mini" shape="round" status="success" icon={<IconPlus />} onClick={() => {
                                                    add({});
                                                }}>Add line</Button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            )
                        }}
                    </Form.List>
                </FormItem>
                <FormItem wrapperCol={{ span: 24 }}>
                    <div style={{ textAlign: "right" }}>
                        <Statistic title="Total debits" groupSeparator value={totalDebits} />
                        <span style={{ marginLeft: 10, marginRight: 10 }}>
                            {totalCredits > totalDebits
                                ? <IconLeft style={{ marginBottom: 3, fontSize: 18 }} />
                                : (totalCredits < totalDebits ? <IconRight style={{ marginBottom: 3, fontSize: 18 }} /> : <IconPause className="rotated90" style={{ marginBottom: 3, fontSize: 18 }} />)}
                        </span>
                        <Statistic title="Total Credits" groupSeparator value={totalCredits} />
                    </div>
                    <div style={{ textAlign: "right" }}>Difference: <UIText type="currency" style={{ color: difference === 0 ? "LightGreen" : "red" }} value={difference} /></div>
                </FormItem>
                <FormItem field="recurring" hidden>
                    <Input />
                </FormItem>
                {recurring &&
                    <>
                        <FormItem label="Every" required >
                            <Space >
                                <FormItem field="every" rules={[{ required: true }]} noStyle>
                                    <Select options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]} style={{ width: 80 }} />
                                </FormItem>
                                <span>Month(s)</span>
                            </Space>
                        </FormItem>
                        <FormItem label="Skip" field="skip">
                            <UISelect.MultiSelect options={[
                                { label: "Jan", value: 1 },
                                { label: "Feb", value: 2 },
                                { label: "Mar", value: 3 },
                                { label: "Apr", value: 4 },
                                { label: "May", value: 5 },
                                { label: "Jun", value: 6 },
                                { label: "Jul", value: 7 },
                                { label: "Aug", value: 8 },
                                { label: "Sep", value: 9 },
                                { label: "Oct", value: 10 },
                                { label: "Nov", value: 11 },
                                { label: "Dec", value: 12 },
                            ]} style={{ width: 300 }} />
                        </FormItem>
                        <FormItem label="Start From" field="startDate">
                            <UIDatePicker />
                        </FormItem>
                        <FormItem label="End At" field="endDate">
                            <UIDatePicker />
                        </FormItem>
                        <FormItem label="Recurring Times" field="recurrenceTimes" wrapperCol={{ span: 3 }}>
                            <InputNumber min={1} />
                        </FormItem>
                    </>
                }
                <FormItem>
                    <Button size="mini" type="outline" status="success" shape="round" onClick={() => {
                        const value = !recurring;
                        setRecurring(value);
                        form.setFieldValue("recurring", value);
                    }}>{recurring ? "Close Function" : "Add Function"}</Button>
                </FormItem>

            </Form>
        </Card >
    )
})
