import { Button, Layout, Typography } from "@arco-design/web-react"
import { Header } from '@/components/Header';
import { useLogin } from '@/pages/Login/useLogin';
import "./index.css";
import { Link } from "react-router-dom";
const { Title, Paragraph } = Typography;

export const Introduction = () => {
    useLogin(true)

    return (
        <div className="container">
            <Layout>
                <Layout.Header>
                    <Header />
                </Layout.Header>
                <Layout.Content className="home-page">
                    <div style={{ marginLeft: 200 }}>
                        <Title>Bee</Title>
                        <Paragraph className="text-3xl font-bold">
                            The ERP takes care of you and your business.
                        </Paragraph>
                    </div>
                    <div style={{ textAlign: "center", marginTop: 150 }}>
                        <Link to={`/login`}>
                            <Button type="primary" className="home-button">Log in</Button>
                        </Link>
                        <Link to={`/register`}>
                            <Button type="outline" className="home-button" style={{ marginLeft: 30 }}>Create New Account</Button>
                        </Link>
                    </div>
                </Layout.Content>
            </Layout>
        </div>
    )
}