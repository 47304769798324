import { Link, Button, Card, Collapse, Divider, Form, Grid, Modal, Space, Timeline, Typography } from "@arco-design/web-react"
import { Fragment, useCallback, useEffect, useState } from "react";
import { BankAPI, ReconciliationAPI } from "@/apis";
import { UIText, UIDatePicker, UIInputNumber } from "@/components/UI";
import _ from "lodash";
import { IconCheckCircleFill, IconExclamationCircleFill, IconPlusCircleFill } from "@arco-design/web-react/icon";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { ReconciliationDetail } from "./reconciliation-detail";

const TimelineItem = Timeline.Item;
const CollapseItem = Collapse.Item;
const { Title, Paragraph, Text } = Typography;
const { Row, Col } = Grid;


const ReconcileButton = ({ bankAccountId, onClick, ...props }) => {
    const [visible, setVisible] = useState(false);
    const [account, setAccount] = useState({});
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const getAccount = useCallback(async () => {
        const account = await BankAPI.account(bankAccountId);
        setAccount(account);
    }, [bankAccountId]);

    const onOk = async () => {
        const values = await form.validate();
        const reconciliationId = await ReconciliationAPI.reconcile(bankAccountId, values);
        navigate(`/workspace/accounting/reconciliation/${reconciliationId}`);
    }

    useEffect(() => {
        getAccount();
    }, [getAccount])

    return (
        <>
            <Button {...props} type="primary" onClick={() => {
                setVisible(true);
            }}>
                Reconcile
            </Button>
            <Modal title="Reconciliation" visible={visible}
                afterClose={() => {
                    form.resetFields();
                }}
                onOk={onOk}
                onCancel={() => {
                    setVisible(false);
                }}
            >
                <Typography>
                    <Title heading={5}>{_.capitalize(account.subType)} **** {account.mask}</Title>
                    <Text>Enter the ending balance as it appears on your statement</Text>
                </Typography>
                <Form
                    style={{ marginTop: 28 }}
                    layout="vertical"
                    form={form}
                >
                    <Form.Item label="Balance Date" field="endDate">
                        <UIDatePicker />
                    </Form.Item>
                    <Form.Item label="Balance Amount" field="statementBalance">
                        <UIInputNumber />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export const Reconciliation = () => {
    const [institutions, setInstitutions] = useState([]);

    const getAccountReconciliation = async (bankAccountId) => {
        const reconciliations = await ReconciliationAPI.reconciliations(bankAccountId);
        const pendingTransactionCount = await ReconciliationAPI.pendingTransactions(bankAccountId);
        return {
            records: reconciliations,
            pendingTransactionCount
        }
    };

    const getInstitutions = useCallback(async () => {
        let institutions = await BankAPI.institutions();

        const accounts = await BankAPI.activeAccounts();
        const accountsByInstitution = _.groupBy(accounts, 'institutionId');
        for (const institution of institutions) {
            institution.accounts = accountsByInstitution[institution.institutionId] ?? [];
            for (const account of institution.accounts) {
                account.reconciliation = await getAccountReconciliation(account.id);
            }
        }

        institutions = _.filter(institutions, (o) => {
            return _.size(o.accounts) > 0;
        });

        setInstitutions(institutions);
    }, []);

    useEffect(() => {
        getInstitutions();
    }, [getInstitutions])

    return (
        <Card title="Reconciliation">
            {_.size(institutions) > 0 &&
                <Collapse accordion
                >
                    {institutions.map((item) => {
                        return (
                            <CollapseItem key={item.institutionId}
                                className="collapse-item"
                                header={
                                    <>
                                        <Text bold>{item.institutionName}</Text>
                                    </>
                                }>
                                {item.accounts && item.accounts.map((account, index) => (
                                    <Fragment key={index}>
                                        <Row>
                                            <Col span={12}>
                                                <Typography>
                                                    {index > 0 &&
                                                        <Divider />
                                                    }
                                                    <Title heading={6}>
                                                        {account.institutionAccountName}
                                                    </Title>

                                                    <Paragraph>
                                                        <Space direction="vertical">
                                                            <Text>{_.capitalize(account.subType)} **** {account.mask}</Text>
                                                            <Text>Current Balance: <UIText type="currency" value={account.balance} /></Text>
                                                        </Space>
                                                    </Paragraph>
                                                </Typography>
                                            </Col>
                                            <Col span={12} style={{ textAlign: "right", top: 30 }}>
                                                <ReconcileButton bankAccountId={account.id} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24}>
                                                <Timeline direction="horizontal" mode="top" >
                                                    {account.reconciliation.records && account.reconciliation.records.map((record, index) => (
                                                        <TimelineItem key={index}
                                                            dot={
                                                                record.status === 1 ?
                                                                    <IconCheckCircleFill style={{ color: "var(--color-success-light-4)", fontSize: 24 }} />
                                                                    :
                                                                    <IconExclamationCircleFill style={{ color: "var(--color-warning-light-4)", fontSize: 24 }} />
                                                            }>
                                                            <Typography>
                                                                <Link href={`/workspace/accounting/reconciliation/${record.id}`} >
                                                                    {record.endDate}
                                                                </Link>
                                                            </Typography>
                                                        </TimelineItem>
                                                    ))}
                                                    <TimelineItem dot={
                                                        <IconPlusCircleFill style={{ color: "var(--color-fill-4)", fontSize: 24 }} />
                                                    }>
                                                        <Typography>
                                                            <Space>
                                                                <Text style={{ color: "var(--color-text-3)" }}>
                                                                    {account.reconciliation.pendingTransactionCount ?? 0} new transactions
                                                                </Text>
                                                            </Space>
                                                        </Typography>
                                                    </TimelineItem>
                                                </Timeline>
                                            </Col>
                                        </Row>
                                    </Fragment>
                                ))}
                            </CollapseItem>
                        )
                    })}
                </Collapse >
            }
        </Card >
    )
}

Reconciliation.Detail = ReconciliationDetail;