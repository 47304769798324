import './admin.css';
import { Layout, Menu } from '@arco-design/web-react';
import { Header } from '@/components/Header';
import { IconFindReplace, IconList } from '@arco-design/web-react/icon';
import { useLogin } from '@/pages/Login/useLogin';
import { Outlet, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import _ from "lodash";

const MenuItem = Menu.Item;

export const Admin = () => {
    useLogin(false);
    const navigate = useNavigate();
    const userInfo = useSelector(state => state.userInfo);

    const onClickMenuItem = (key) => {
        navigate(key);
    }

    useEffect(() => {
        if (userInfo.loaded) {
            const admin = _.indexOf(userInfo.roles, 'admin');
            if (admin < 0) {
                navigate("/workspace");
            }
        }
    }, [userInfo, navigate])

    return (
        <Layout>
            <Layout.Header>
                <Header />
            </Layout.Header>
            <Layout>
                <Layout.Sider className="layout-sider" style={{ "paddingTop": "65px" }}>
                    <Menu onClickMenuItem={onClickMenuItem}>
                        <MenuItem key='company'><IconList />Company</MenuItem>
                        <MenuItem key='bank-audit'><IconFindReplace />Bank Audit</MenuItem>
                    </Menu>
                </Layout.Sider>
                <Layout.Content className="layout-content" style={{ "paddingLeft": "220px", "paddingTop": "65px" }}>
                    <div className='layout-content-wrapper'>
                        <Outlet />
                    </div>
                </Layout.Content>
            </Layout>
        </Layout >
    );
}