import { Button, Card, Space } from "@arco-design/web-react"
import { JournalAPI } from "@/apis";
import { Link, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { UITable } from "@/components/UI";
import { IconEye } from "@arco-design/web-react/icon";
import { JournalEntryAdd } from "./journal-entry-add";
import { JournalEntryInfo } from "./journal-entry-info";

export const JournalEntry = () => {
    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);

    const getEntries = useCallback(async () => {
        const values = await JournalAPI.query(page);
        setData(values.records);
        setTotal(values.total);
    }, [page])

    useEffect(() => {
        getEntries();
    }, [getEntries])

    return (
        <Card title="Journal Entries" extra={
            <Space>
                <Link to="/workspace/accounting/journal-entry/new-entry">
                    <Button type="primary" >Add Journal Entry</Button>
                </Link>
            </Space>
        }>
            <UITable columns={[
                { title: "Date", dataIndex: "date" },
                { title: "Description", dataIndex: "description" },
                {
                    title: "Operations", width: 100, render: (col, record) => (
                        <Space>
                            <Button type="text" icon={<IconEye />} onClick={() => navigate(`/workspace/accounting/journal-entry/${record.id}`)}>Detail</Button>
                        </Space>
                    )
                }
            ]}
                data={data}
                total={total}
                onChange={val => setPage(val)}
            />
        </Card>
    )
}

JournalEntry.Info = JournalEntryInfo;
JournalEntry.Add = JournalEntryAdd;