import './header.css';
import { ReactComponent as Logo } from '@/assets/logo.svg';
import { Avatar, Divider, Dropdown, Menu, Space } from '@arco-design/web-react';
import { IconHome, IconLock, IconPoweroff, IconSettings, IconUser } from '@arco-design/web-react/icon';
import { UserAPI } from '@/apis';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { clearUserInfo } from '@/store/userInfoSlice';
import _ from "lodash";
import { useEffect, useState } from 'react';

const MenuItem = Menu.Item;

export const Header = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const userInfo = useSelector(state => state.userInfo);
    const [selectedKeys, setSelectedKeys] = useState([]);

    const onMenuItemClick = (key) => {
        switch (key) {
            case "home":
                navigate("/");
                break;
            case "password":
                if (userInfo.isAdmin) {
                    navigate("/admin/password");
                } else {
                    navigate("/workspace/password");
                }
                break;
            case "logout":
                logout();
                break;
            case "companyProfile":
                navigate("/workspace/company/profile");
                break;
            default:
                navigate(`/${key}`);
                break;
        }
    }

    const logout = async () => {
        await UserAPI.logout();
        localStorage.removeItem("login.token");
        dispatch(clearUserInfo());
        navigate("/login");
    }


    const userProfileList = (
        <Menu onClickMenuItem={onMenuItemClick}>
            <MenuItem>
                {_.join(_.remove([userInfo.fullName, userInfo.companyName], o => o != null), ', ')}
            </MenuItem>
            {_.indexOf(userInfo.roles, "company-manager") >= 0 &&
                <>
                    <Divider style={{ margin: '4px 0' }} />
                    <MenuItem key="workspace">
                        <IconHome className='dropdown-icon' />
                        Workspace
                    </MenuItem>
                    <MenuItem key="companyProfile">
                        <IconSettings className='dropdown-icon' />
                        Company Profile
                    </MenuItem>
                </>
            }
            <MenuItem key="password">
                <IconLock className='dropdown-icon' />
                Change Password
            </MenuItem>
            <MenuItem key='logout'>
                <IconPoweroff className='dropdown-icon' />
                Logout
            </MenuItem>
        </Menu>
    );

    useEffect(() => {
        const path = location.pathname.substring(1);
        if (path === "") {
            setSelectedKeys(["home"])
        } else {
            setSelectedKeys([path]);
        }
    }, [location])

    return (
        <div className='header'>
            <Menu mode='horizontal' theme='dark'
                selectedKeys={selectedKeys}
                onClickMenuItem={onMenuItemClick}>
                <MenuItem key="0" >
                    <div className='logo'>
                        <Logo className='logo-pic' />
                        <span className='logo-text'>Bee</span>
                    </div>
                </MenuItem>
                <MenuItem key='home'>Home</MenuItem>
                <MenuItem key='products' title="Products">Products
                </MenuItem>
                <MenuItem key='pricing'>Pricing</MenuItem>
                <MenuItem key='about'>About Us</MenuItem>
                <MenuItem key='contact'>Contact Us</MenuItem>
            </Menu>
            <div className="profile">
                <Space>
                    <div className='profile-avatar'>
                        {userInfo.fullName ?
                            <Dropdown droplist={userProfileList}>
                                <Avatar className='default-avatar'>
                                    <IconUser />
                                </Avatar>
                            </Dropdown>
                            :
                            <div style={{ color: "var(--color-fill-1)", marginTop: 8 }}>
                                <IconUser style={{ marginRight: 3 }} />
                                <Link to="/login" style={{ color: "var(--color-fill-1)" }}>Log In</Link>
                            </div>
                        }
                    </div>
                </Space>
            </div>
        </div>
    );
}