import { Button, Form, Input, Layout, Message } from "@arco-design/web-react"
import { Header } from '@/components/Header';
import Title from "@arco-design/web-react/es/Typography/title";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import _ from "lodash";
import { UserAPI } from "@/apis";

const FormItem = Form.Item;

export const ResetPassword = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [valid, setValid] = useState(false);
    const [form] = Form.useForm();

    const resetPassword = async () => {
        setLoading(true);
        try {
            const values = await form.validate();
            const username = searchParams.get("username");
            const expiredTime = searchParams.get("expired");
            const signature = searchParams.get("s");

            await UserAPI.changePasswordNonLogin({
                username: username,
                password: values.password,
                expiredTime: expiredTime,
                signature: signature
            });

            Message.success("Your new password has been changed successfully.")
            navigate("/login");
        } catch (error) {
            Message.error("Failed to update password, please try again.");
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        const username = searchParams.get("username");
        const expiredTime = searchParams.get("expired");
        const signature = searchParams.get("s");
        if (_.isNil(username) || _.isNil(expiredTime) || _.isNil(signature)) {
            Message.error("Invalid reset password url");
            return;
        }

        setValid(true);
    }, [searchParams]);

    return (
        <div className="container" >
            <Layout>
                <Layout.Header>
                    <Header />
                </Layout.Header>
                <Layout.Content className="home-page">
                    <div style={{ width: 500, marginLeft: 200 }}>
                        <Title>Change Your Password</Title>
                        <Form colon style={{ marginTop: 50 }} form={form} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                            <FormItem label="New Password" field="password" required rules={[{ required: true }, {
                                validator: (v, cb) => {
                                    if (!/^(?![^a-zA-Z]+$)(?!\\D+$).{6,32}$/.test(v)) {
                                        return cb("The password must be at least 6 characters and less than 32 characters with a mixture of numbers and letters");
                                    }
                                    cb(null);
                                }
                            }]}>
                                <Input.Password />
                            </FormItem>
                            <FormItem label="Confirm Password" field="confirmPassword" required rules={[{ required: true }, {
                                validator: (v, cb) => {
                                    if (!v) {
                                        return cb('Confirm password is required')
                                    } else if (form.getFieldValue('password') !== v) {
                                        return cb('Confirm password must be equal with password')
                                    }

                                    cb(null);
                                }
                            }]}>
                                <Input.Password />
                            </FormItem>
                            <FormItem wrapperCol={{ offset: 8 }}>
                                <Button type="primary" loading={loading} disabled={!valid} onClick={() => resetPassword()}>Change your password</Button>
                            </FormItem>
                        </Form>
                    </div>
                </Layout.Content>
            </Layout>
        </div >
    )
}