import { Button, Card, Descriptions, Message, Popconfirm, Space } from "@arco-design/web-react";
import { POAPI, ItemReceiptAPI, BillAPI, VendorAPI } from "@/apis";
import { useCallback, useEffect, useRef, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { UITable, UIText, UITag } from "@/components/UI";
import { RefUtil, NumericUtil, FileUtil } from "@/utils";
import { POForm } from "./po-form";
import Address from "@/components/Address";
import SendEmailButton from "@/components/SendEmailButton";

export const POInfo = () => {
    const params = useParams();
    const poId = params.poId;
    const navigate = useNavigate();
    const [info, setInfo] = useState({});
    const [receipts, setReceipts] = useState([]);
    const [receiptsTotal, setReceiptsTotal] = useState(0);
    const [receiptsPage, setReceiptsPage] = useState(1);
    const [bills, setBills] = useState([]);
    const [billsTotal, setBillsTotal] = useState(0);
    const [billsPage, setBillsPage] = useState(1);
    const [editing, setEditing] = useState(false);
    const [printLoading, setPrintLoading] = useState(false);
    const formRef = useRef(null);

    const getInfo = useCallback(async () => {
        const values = await POAPI.get(poId);
        setInfo(values);
    }, [poId]);

    const getReceipts = useCallback(async () => {
        const values = await ItemReceiptAPI.query(receiptsPage, { poId: poId });
        setReceipts(values.records);
        setReceiptsTotal(values.total);

    }, [receiptsPage, poId]);

    const getBills = useCallback(async () => {
        const values = await BillAPI.query(billsPage, { poId: poId });
        setBills(values.records);
        setBillsTotal(values.total);
    }, [billsPage, poId]);

    const updateStatus = async (status) => {
        await POAPI.updateStatus(info.id, status);
        Message.success("Status updated successfully");
        await getInfo();
    }

    const submit = async () => {
        const values = await formRef.current.getFormValues();
        await POAPI.update(values.id, values);
        Message.success("Purchase Order updated successfully");
        getInfo();
        setEditing(false);
    }

    useEffect(() => {
        getInfo();
    }, [getInfo])

    useEffect(() => {
        getReceipts();
    }, [getReceipts])

    useEffect(() => {
        getBills();
    }, [getBills])

    return <Card title={
        <Space>
            <span>{info.poNo}</span>
            <UITag value={info.statusName} />
        </Space>
    } extra={editing ?
        <Space>
            <Button type="primary" onClick={() => submit()}>Save</Button>
            <Button onClick={() => setEditing(false)}>Cancel</Button>
        </Space >
        : <Space>
            {(info.status <= 3) &&
                <Button type="primary" onClick={() => setEditing(true)}>Edit</Button>
            }
            {(info.status === 1) &&
                <Button type="primary" onClick={() => updateStatus(2)}>Issue</Button>
            }
            {(info.status === 2) &&
                <Button type="primary" onClick={() => updateStatus(1)}>Unissue</Button>
            }
            {(info.status <= 3) &&
                <Button type="primary" onClick={() => navigate("/workspace/purchasing/receipt/po/" + info.id)}>Receive PO</Button>
            }
            {(info.status === 3) &&
                <Button type="primary" onClick={() => updateStatus(4)}>Short Close</Button>
            }
            {(info.status === 1 || info.status === 2) &&
                <Popconfirm title="Are you sure you want to void purchasing order?"
                    onOk={() => updateStatus(6)}>
                    <Button type="primary" status="danger">Void</Button>
                </Popconfirm>
            }
            {info.status !== 6 &&
                <Button type="primary" onClick={async () => {
                    setPrintLoading(true);
                    try {
                        const res = await POAPI.print(info.id);
                        FileUtil.download(res.data, `${info.poNo}.pdf`);
                    } catch (error) {
                        Message.error(error.message);
                    } finally {
                        setPrintLoading(false);
                    }
                }} loading={printLoading}>Print</Button>
            }
            {info.status !== 6 &&
                <SendEmailButton getEmailContent={async () => {
                    return await POAPI.getEmail(info.id);
                }} getToList={async () => {
                    const vendor = await VendorAPI.get(info.vendorId);
                    return vendor.contacts;
                }} sendEmail={async (val) => {
                    await POAPI.sendEmail(info.id, val);
                }} />
            }
            <Button onClick={() => navigate("/workspace/purchasing/po")}>Back</Button>
        </Space >
    }>

        {!editing ?
            <>
                <Descriptions colon=":"
                    data={[{
                        label: "Vendor",
                        value: info.vendorName
                    }, {
                        label: "Drop Ship",
                        value: info.dropShipName
                    }, {
                        label: "Ship to",
                        value: <Address value={{
                            name: info.shipToContactName,
                            address: info.shipToAddress,
                            city: info.shipToCity,
                            state: info.shipToState,
                            zip: info.shipToZip,
                        }} inline />
                    }, {
                        label: "PO Date",
                        value: info.poDate
                    }, {
                        label: "Due Date",
                        value: info.dueDate
                    }, {
                        label: "Term",
                        value: info.termName
                    }, {
                        label: "Reference#",
                        value: RefUtil.format(info.reference),
                        span: 2
                    }, {
                        label: "Total Amount",
                        value: NumericUtil.currency(info.totalAmount)
                    }, {
                        label: "Memo",
                        value: info.memo,
                        span: 2
                    }]} />

                <UITable columns={[
                    { title: "Item Number", dataIndex: "itemNo" },
                    { title: "Item Name", dataIndex: "itemName" },
                    { title: "Description", dataIndex: "description" },
                    {
                        title: "Total Qty",
                        render: (col, record) => <UIText type="number" value={record.quantity} />
                    },
                    {
                        title: "Qty Received",
                        render: (col, record) => <UIText type="number" value={record.received} />
                    },
                    {
                        title: "Open",
                        render: (col, record) => <UIText type="number" value={Math.max(record.quantity - record.received, 0)} />
                    },
                    { title: "U/M", dataIndex: "unitName" },
                    { title: "Rate", render: (col, record) => <UIText type="number" value={record.rate} /> },
                    { title: "Amount", render: (col, record) => <UIText type="currency" value={record.amount} /> },
                ]}
                    data={info.items}

                />

                <UITable style={{ marginTop: 15 }} columns={[
                    {
                        title: "Item Receipt#",
                        render: (col, record, index) =>
                            <Link to={"/workspace/purchasing/receipt/" + record.id}>{record.receiptNo}</Link>
                    },
                    { title: "Status", dataIndex: "statusDesc" },
                    { title: "Vendor", dataIndex: "vendorName" },
                    { title: "Receive Date", dataIndex: "receiveDate" },

                ]} data={receipts}
                    total={receiptsTotal}
                    onChange={(page) => { setReceiptsPage(page); }}
                />

                <UITable style={{ marginTop: 15 }} columns={[
                    {
                        title: "Bill#", render: (col, record, index) => {
                            return (
                                <Link to={"/workspace/purchasing/bills/" + record.id}>{record.billNo}</Link>
                            )
                        }
                    },
                    { title: "Bill Date", dataIndex: "billDate" },
                    { title: "Due Date", dataIndex: "dueDate" },
                    { title: "Vendor", dataIndex: "vendorName" },
                    { title: "Total Amount", render: (col, record) => <UIText type="currency" value={record.totalAmount} /> },
                    { title: "Invoice#", dataIndex: "invoiceNo" },
                    { title: "Paid Date", dataIndex: "processDate" },
                    { title: "Clear Date", dataIndex: "clearDate" },

                ]} data={bills}
                    total={billsTotal}
                    onChange={(page) => { setBillsPage(page); }}
                />

            </>
            : <POForm ref={formRef} value={info} />
        }
    </Card >
}