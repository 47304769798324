import { Layout, Typography } from "@arco-design/web-react"
import { Header } from '@/components/Header';
import { useLogin } from '@/pages/Login/useLogin';
const { Title, Paragraph } = Typography;

export const AboutUs = () => {
    useLogin(true)

    return (
        <div className="container">
            <Layout>
                <Layout.Header>
                    <Header />
                </Layout.Header>
                <Layout.Content style={{ "paddingTop": "65px", marginLeft: 100, marginRight: 100 }}>
                    <Title>Why we created Bee?</Title>
                    <Paragraph style={{ wordBreak: "break-word" }}>
                        Bee is a simple, intuitive, complete, and affordable ERP solution for distributors and Manufactures of all industries that deal with services or inventories.
                    </Paragraph>
                    <Paragraph style={{ wordBreak: "break-word" }}>
                        Usability and affordability are the reasons we created Bee. There are ERP, MPR systems on the market. But the systems are so complicated and can be overwhelming to a lot of business owners, and managers. We created Bee that follows the logic and common sense. You don’t need to be an accountant or system admin to run your business on our platform.
                    </Paragraph>
                    <Paragraph style={{ wordBreak: "break-word" }}>
                        For the ERP systems on the market, you would pay tens of thousands if not hundreds of thousands for implementation and thousands per license. This is a huge burden for small business and even midsize business financially. While we don’t charge any implementation fee, our system is also free to use for startups.
                    </Paragraph>
                    <Paragraph style={{ wordBreak: "break-word" }}>
                        By Creating Bee, we are breaking these barriers and offering a complete solution to all businesses. Bee is created to take care of you and your business, NOT a system for you to take care of.
                    </Paragraph>
                </Layout.Content>
            </Layout>
        </div>
    )
}