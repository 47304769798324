import { Alert, Button, Card, Descriptions, Message, Popconfirm, Space } from "@arco-design/web-react"
import { useNavigate, useParams } from "react-router-dom";
import { SOPOAPI, SOAPI, CustomerAPI } from "@/apis";
import { UITable, UIText, UITag } from "@/components/UI";
import { useCallback, useEffect, useRef, useState } from "react";
import { PickOrderForm } from "./pick-order-form";
import { FileUtil } from "@/utils";
import SaleOrderLink from "@/components/SaleOrderLink";
import _ from "lodash";
import SendEmailButton from "@/components/SendEmailButton";

export const PickOrderInfo = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [info, setInfo] = useState({});
    const [so, setSO] = useState();
    const [editing, setEditing] = useState(false);
    const [printLoading, setPrintLoading] = useState(false);
    const formRef = useRef();

    const back = () => {
        navigate("/workspace/sales/so-pick-order");
    }

    const getInfo = useCallback(async () => {
        const sopoInfo = await SOPOAPI.get(params.poId);
        const soInfo = await SOAPI.get(sopoInfo.soId);
        setSO(soInfo);
        _.forEach(sopoInfo.items, (item) => {
            item.soItemQty = _.find(soInfo.items, (soItem) => soItem.id === item.soItemId).quantity;
        })

        setInfo(sopoInfo);
    }, [params]);

    const voidPO = async () => {
        try {
            await SOPOAPI.voidPO(params.poId);
            Message.success("Pick order voided successfully");
            getInfo();
        } catch (error) {
            Message.error(error.message);
        }
    }

    const save = async () => {
        try {
            const values = await formRef.current.getFormValues();
            await SOPOAPI.update(values.id, values);
            Message.success("Pick order updated successfully");
            setEditing(false);
            getInfo();
        } catch (error) {
            Message.error(error.message);
        }
    }

    useEffect(() => {
        getInfo();
    }, [getInfo])

    return (
        <>
            <Card title={<Space>
                <span>{info.pickOrderNo}</span>
                {info.status === 2 &&
                    <UITag value={info.statusDesc} />
                }
            </Space>} extra={
                editing ?
                    <Space>
                        <Button type="primary" onClick={() => save()}>Save</Button>
                        <Button onClick={() => setEditing(false)}>Cancel</Button>
                    </Space>
                    :
                    <Space>
                        {so?.status < 6 && info.status === 1 &&
                            <Button type="primary" onClick={() => navigate(`/workspace/sales/so-fulfillment/so/${so?.id}?sopoId=${params.poId}`)}>Fulfill</Button>
                        }
                        {info.status === 1 &&
                            <Button type="primary" onClick={() => setEditing(true)}>Edit</Button>
                        }
                        {info.status === 1 &&
                            <Popconfirm title="Are you sure you want to void pick order?"
                                onOk={() => voidPO()}>
                                <Button type="primary" status="danger">Void</Button>
                            </Popconfirm>
                        }
                        {info.status === 1 &&
                            <Button type="primary" onClick={async () => {
                                setPrintLoading(true);
                                try {
                                    const res = await SOPOAPI.print(info.id);
                                    FileUtil.download(res.data, `${info.pickOrderNo}.pdf`);
                                } catch (error) {
                                    Message.error(error.message);
                                } finally {
                                    setPrintLoading(false);
                                }
                            }} loading={printLoading}>Print</Button>
                        }
                        {info.status === 1 &&
                            <SendEmailButton getEmailContent={async () => {
                                return await SOPOAPI.getEmail(info.id);
                            }} getToList={async () => {
                                const customer = await CustomerAPI.get(so.customerId);
                                return customer.contacts;
                            }} sendEmail={async (val) => {
                                await SOPOAPI.sendEmail(info.id, val);
                            }} />
                        }
                        <Button onClick={() => back()}>Back</Button>
                    </Space>
            }>
                {!editing ?
                    <>
                        <Descriptions colon=":"
                            data={[{
                                label: "SO#",
                                value: <SaleOrderLink soId={info.soId}>{info.soNo}</SaleOrderLink>
                            }, {
                                label: "Customer Name",
                                value: info.customerName
                            }, {
                                label: "Address",
                                value: info.address
                            }, {
                                label: "SO Due Date",
                                value: info.soDueDate
                            }, {
                                label: "SO Creation Date",
                                value: info.soCreateDate
                            }, {
                                label: "Pick Date",
                                value: info.createTime
                            }, {
                                label: "Fulfillment#",
                                value: info.fulfillment?.soFulfillmentNo
                            }, {
                                label: "Warehouse",
                                value: info.warehouseName
                            }]} />

                        <UITable columns={[
                            {
                                title: "Line#", render: (col, record, index) => {
                                    return <span>{index + 1}</span>
                                }
                            },
                            { title: "Item Number", dataIndex: "itemNo" },
                            { title: "Item Name", dataIndex: "itemName" },
                            { title: "Description", dataIndex: "description" },
                            { title: "SO Qty", render: (col, record) => <UIText type="number" value={record.soItemQty} /> },
                            { title: "Pick Qty", render: (col, record) => <UIText type="number" value={record.pickQty} /> },
                            { title: "UOM", dataIndex: "unitName" },
                            { title: "Warehouse Location", dataIndex: "locationName" },
                            { title: "Lot#", dataIndex: "lot" },
                            { title: "Expiration", dataIndex: "expiredDate" },
                        ]}
                            data={info.items}
                        />

                    </>
                    : <PickOrderForm ref={formRef} value={info} />
                }
            </Card>
            <Alert className="alert-bottom" content="Psst: Pick orders that are not fulfilled within 15 days will be voided by the system automatically to release the reserved inventory. You can manually void pick orders to release the inventory from reservation right away." />
        </>
    )
}