import { get, post, put, del, getBlob, postBlob } from "@/utils/api";

export const DictAPI = {
    getItems: async (dictName) => {
        return await get(`/dict/${dictName}`);
    }
};

export const SettingAPI = {
    getSettings: async () => {
        return await get(`/settings`);
    },
    save: async (params) => {
        return await post(`/settings`, params);
    }
}

export const UserAPI = {
    login: async (username, password) => {
        return await post("/user/login", { username: username, password: password });
    },
    logout: async () => {
        return await post("/user/logout");
    },
    isLogged: async () => {
        return await get("/user/login");
    },
    info: async () => {
        return await get("/user");
    },
    add: async (params) => {
        return await put("/user", params);
    },
    update: async (params) => {
        return await post(`/user/${params.id}`, params);
    },
    delete: async (id) => {
        return await del(`/user/${id}`, null);
    },
    resetPassword: async (id) => {
        return await post(`/user/password/default/${id}`);
    },
    changePassword: async (params) => {
        return await post(`/user/password/change`, params);
    },
    changePasswordNonLogin: async (params) => {
        return await put(`/user/non-login-password/change`, params);
    },
    applyResetPassword: async (username) => {
        return await put(`/user/apply-reset-password/${username}`);
    }
};

export const CompanyAPI = {
    list: async (page, query) => {
        let params = {
            page: page,
            ...query
        }
        return await get("/company", params);
    },
    active: async () => {
        return await get("/company/active");
    },
    info: async () => {
        return await get("/company/info");
    },
    add: async (params) => {
        return await put("/company", params);
    },
    update: async (companyId, params) => {
        if (typeof companyId === 'number') {
            return await post("/company/" + companyId, params);
        } else {
            params = companyId;
            return await post("/company", params);
        }
    },
    updateInfo: async (params) => {
        return await post("/company/info", params);
    },
    updateEmailSettings: async (params) => {
        return await post("/company/email", params);
    },
    users: async (companyId) => {
        return await get("/company/user?companyId=" + (companyId ?? ""));
    },
    addUser: async (params) => {
        return await put("/company/user", params);
    },
    deleteUser: async (id) => {
        return await del(`/company/user/${id}`);
    },
    addresses: async (vendorId) => {
        return await get("/company/ship-address", { vendorId: vendorId });
    },
    register: async (params) => {
        return await put("/company/register", params);
    }
};

export const FileAPI = {
    getFile: async (url) => {
        return await getBlob(url);
    }
};

export const AccountAPI = {
    list: async () => {
        return await get("/account/all");
    },
    get: async (accountId) => {
        return await get(`/account/${accountId}`);
    },
    getByName: async (name) => {
        return await get("/account", { name: name });
    },
    actives: async (params) => {
        const requestParams = { status: 1, accountType: params?.type, category: params?.category };
        return await get("/account/query", requestParams);
    },
    add: async (params) => {
        return await put("/account", params);
    },
    update: async (id, params) => {
        return await post(`/account/${id}`, params);
    },
    close: async (id) => {
        return await post(`/account/${id}/close`);
    },
    open: async (id) => {
        return await post(`/account/${id}/open`);
    },
    getTypes: async () => {
        return await get("/account/types");
    }
};

export const VendorAPI = {
    query: async (page, statusParams) => {
        const params = { page: page, status: statusParams };
        return await get("/vendor", params);
    },
    actives: async (params) => {
        return await get("/vendor/actives");
    },
    get: async (id) => {
        return await get(`/vendor/${id}`);
    },
    add: async (params) => {
        return await put("/vendor", params);
    },
    update: async (id, params) => {
        return await post(`/vendor/${id}`, params);
    },
    enable: async (id) => {
        return await post(`/vendor/${id}/1`);
    },
    disable: async (id) => {
        return await post(`/vendor/${id}/2`);
    },
    getDocuments: async (id) => {
        return await get("/vendor/document", { vendorId: id });
    },
    addDocument: async (id, file) => {
        return await post(`/vendor/document?vendorId=${id}`, { file: file });
    },
    updateDocument: async (id, params) => {
        return await post(`/vendor/document/${id}`, params);
    },
    deleteDocument: async (id) => {
        return await del(`/vendor/document/${id}`);
    }
};

export const ItemAPI = {
    query: async (params) => {
        return await get("/item", params);
    },
    getNonInventoryItems: async (type) => {
        return await get("/item/non-inventory");
    },
    get: async (id) => {
        return await get(`/item/${id}`);
    },
    add: async (params) => {
        return await put("/item", params);
    },
    update: async (id, params) => {
        return await post(`/item/${id}`, params);
    },
    active: async (id) => {
        return await post(`/item/${id}/1`);
    },
    close: async (id) => {
        return await post(`/item/${id}/2`);
    },
    getDocuments: async (id) => {
        return await get("/item/document", { itemId: id });
    },
    addDocument: async (id, file) => {
        return await post(`/item/document?itemId=${id}`, { file: file });
    },
    updateDocument: async (id, params) => {
        return await post(`/item/document/${id}`, params);
    },
    deleteDocument: async (id) => {
        return await del(`/item/document/${id}`)
    },
    uoms: async () => {
        return await get("/item/uom");
    },
    actives: async () => {
        return await get("/item/actives");
    },
}

export const InventoryAPI = {
    getItems: async (params) => {
        return await get("/inventory", params)
    },
    stock: async (itemId) => {
        return await get("/inventory/stock", { itemId: itemId });
    },
    getItemCommitted: async (itemId) => {
        return await get(`/inventory/item/${itemId}/committed`);
    },
    getItemOnOrder: async (itemId) => {
        return await get(`/inventory/item/${itemId}/on-order`);
    },
    lots: async (params) => {
        return await get("/inventory/lots", params);
    },
    itemFulfilledLots: async (itemId) => {
        return await get(`/inventory/item/${itemId}/fulfilled-lots`);
    },
    evaluation: async (page, params) => {
        return await get("/inventory/evaluation", { page, ...params });
    }
}

export const WarehouseAPI = {
    query: async (page, params) => {
        const criteria = { page: page, status: params.status };
        return await get("/warehouse", criteria)
    },
    get: async (id) => {
        return await get(`/warehouse/${id}`);
    },
    add: async (values) => {
        return await put("/warehouse", values);
    },
    update: async (id, values) => {
        return await post(`/warehouse/${id}`, values);
    },
    enable: async (id) => {
        return await post(`/warehouse/${id}/1`);
    },
    disable: async (id) => {
        return await post(`/warehouse/${id}/2`);
    },
    actives: async () => {
        return await get("/warehouse/actives");
    },
    getLocations: async (id, page) => {
        return await get(`/warehouse/${id}/locations`, { page: page })
    },
    activeLocations: async (id, name) => {
        return await get(`/warehouse/${id}/location/actives`, { locationName: name })
    },
    locations: async (id, name) => {
        return await get(`/warehouse/${id}/locations`, { locationName: name })
    },
    addLocation: async (id, params) => {
        return await put(`/warehouse/${id}/location`, params);
    },
    updateLocation: async (id, locationId, params) => {
        return await post(`/warehouse/${id}/location/${locationId}`, params);
    },
    deleteLocation: async (id, locationId) => {
        return await del(`/warehouse/${id}/location/${locationId}`);
    },
    uploadLocations: async (id, file) => {
        return await post(`/warehouse/${id}/locations`, { file: file })
    },
}

export const POAPI = {
    query: async (page, params) => {
        const criteria = { page: page, status: params.status };
        return await get("/po", criteria)
    },
    queryByItemId: async (page, itemId) => {
        const criteria = { page: page };
        return await get(`/po/item/${itemId}`, criteria)
    },
    get: async (id) => {
        return await get(`/po/${id}`);
    },
    add: async (values) => {
        return await put("/po", values);
    },
    update: async (id, values) => {
        return await post(`/po/${id}`, values);
    },
    updateStatus: async (id, status) => {
        return await post(`/po/${id}/status/${status}`);
    },
    print: async (poId) => {
        return await postBlob(`/po/${poId}/print`);
    },
    getEmail: async (poId) => {
        return await post(`/po/${poId}/email/content`);
    },
    sendEmail: async (poId, params) => {
        return await post(`/po/${poId}/email/send`, params);
    },
    getNotBilled: async () => {
        return await get(`/po/not-billed`);
    },
    getVendorLastItem: async (vendorId, itemId) => {
        if (!vendorId || !itemId) return null;
        return await get(`/po/vendor/${vendorId}/item/${itemId}/last`);
    }
}

export const ItemReceiptAPI = {
    query: async (page, params) => {
        return await get("/receipt", { page: page, ...params });
    },
    add: async (params) => {
        return await put("/receipt", params);
    },
    get: async (id) => {
        return await get(`/receipt/${id}`);
    },
    delete: async (id) => {
        return await del(`/receipt/${id}`);
    },
    update: async (id, params) => {
        return await post(`/receipt/${id}`, params);
    },
    item: async (receiptItemId) => {
        return await get(`/receipt/item/${receiptItemId}`);
    },
    received: async (poId) => {
        return await get("/receipt/received", { poId: poId });
    },
    remaining: async (itemId, warehouseId) => {
        return await get("/receipt/remaining", { warehouseId: warehouseId, itemId: itemId });
    },
    notBilled: async () => {
        return await get("/receipt/not-billed");
    },
    print: async (receiptId) => {
        return await postBlob(`/receipt/${receiptId}/print`);
    },
    getEmail: async (receiptId) => {
        return await post(`/receipt/${receiptId}/email/content`);
    },
    sendEmail: async (receiptId, params) => {
        return await post(`/receipt/${receiptId}/email/send`, params);
    }
}

export const BillAPI = {
    query: async (page, params) => {
        return await get("/bill", { page: page, ...params });
    },
    actives: async (params) => {
        return await get("/bill/active", params);
    },
    add: async (params) => {
        return await put("/bill", params);
    },
    update: async (id, params) => {
        return await post(`/bill/${id}`, params);
    },
    get: async (id) => {
        return await get(`/bill/${id}`);
    },
    delete: async (id) => {
        return await del(`/bill/${id}`);
    },
    payments: async (billId) => {
        return await get(`/bill/${billId}/payments`);
    },
    print: async (billId) => {
        return await postBlob(`/bill/${billId}/print`);
    },
    getEmail: async (billId) => {
        return await post(`/bill/${billId}/email/content`);
    },
    sendEmail: async (billId, params) => {
        return await post(`/bill/${billId}/email/send`, params);
    },
    getVendorLastItem: async (vendorId, itemId) => {
        if (!vendorId || !itemId) return null;
        return await get(`/bill/vendor/${vendorId}/item/${itemId}/last`);
    }
}

export const CustomerAPI = {
    query: async (page, status) => {
        const params = { page: page, status: status };
        return await get("/customer", params);
    },
    actives: async () => {
        return await get("/customer/actives");
    },
    get: async (id) => {
        return await get(`/customer/${id}`);
    },
    balance: async (id) => {
        return await get(`/customer/${id}/balance`);
    },
    balanceDetails: async (id) => {
        return await get(`/customer/${id}/balance-details`);
    },
    add: async (params) => {
        return await put("/customer", params);
    },
    update: async (id, params) => {
        return await post(`/customer/${id}`, params);
    },
    enable: async (id) => {
        return await post(`/customer/${id}/1`);
    },
    disable: async (id) => {
        return await post(`/customer/${id}/2`);
    },
    addresses: async (id) => {
        return await get("/customer/address", { customerId: id })
    }
}

export const SOAPI = {
    query: async (page, params) => {
        const criteria = { page: page, status: params.status };
        return await get("/so", criteria);
    },
    actives: async () => {
        return await get("/so/active");
    },
    get: async (id) => {
        return await get(`/so/${id}`);
    },
    inventoryItems: async (id) => {
        return await get(`/so/${id}/items?itemType=1`);
    },
    add: async (values) => {
        return await put("/so", values);
    },
    update: async (id, values) => {
        return await post(`/so/${id}`, values);
    },
    issue: async (id) => {
        return await post(`/so/${id}/issue`);
    },
    unissue: async (id) => {
        return await post(`/so/${id}/unissue`);
    },
    shortClose: async (id) => {
        return await post(`/so/${id}/short-close`);
    },
    voidSO: async (id) => {
        return await post(`/so/${id}/void`);
    },
    fulfillments: async (id) => {
        return await get(`/so/${id}/fulfillment`);
    },
    noInvoiceFulfillments: async (soId) => {
        return await get(`/so/${soId}/no-invoice-fulfillment`);
    },
    fulfillmentItems: async (soId) => {
        return await get(`/so/${soId}/fulfillment-items`);
    },
    print: async (soId) => {
        return await postBlob(`/so/${soId}/print`);
    },
    getEmail: async (soId) => {
        return await post(`/so/${soId}/email/content`);
    },
    sendEmail: async (soId, params) => {
        return await post(`/so/${soId}/email/send`, params);
    },
    getCustomerLastSOItem: async (customerId, itemId) => {
        if (!customerId || !itemId) return;
        return await get(`/so/customer/${customerId}/item/${itemId}/last`);
    },
    getNonInventoryItems: async (soId) => {
        return await get(`/so/${soId}/items?itemType=2`);
    }
}

export const SOPOAPI = {
    query: async (page, params) => {
        return await get("/so-pick-order", { page: page, ...params });
    },
    estimate: async (soId, warehouseId) => {
        return await get(`/so-pick-order/estimate/${soId}?warehouseId=${warehouseId}`);
    },
    get: async (id) => {
        return await get("/so-pick-order/" + id);
    },
    getBySO: async (id) => {
        return await get("/so-pick-order/so/" + id);
    },
    getUnfulfilledBySO: async (soId) => {
        return await get(`/so-pick-order/so/${soId}/unfulfilled`);
    },
    add: async (values) => {
        return await put("/so-pick-order", values);
    },
    update: async (sopoId, params) => {
        return await post(`/so-pick-order/${sopoId}`, params);
    },
    print: async (sopoId) => {
        return await postBlob(`/so-pick-order/${sopoId}/print`);
    },
    getEmail: async (id) => {
        return await post(`/so-pick-order/${id}/email/content`);
    },
    sendEmail: async (id, params) => {
        return await post(`/so-pick-order/${id}/email/send`, params);
    },
    voidPO: async (sopoId) => {
        return await post(`/so-pick-order/${sopoId}/void`);
    }
}

export const SOFulfillmentAPI = {
    query: async (page, params) => {
        return await get("/so-fulfillment", { page: page, ...params });
    },
    items: async (page, params) => {
        return await get(`/so-fulfillment/items`, { page: page, ...params });
    },
    get: async (id) => {
        return await get(`/so-fulfillment/${id}`);
    },
    add: async (values) => {
        return await put("/so-fulfillment", values);
    },
    update: async (id, values) => {
        return await post(`/so-fulfillment/${id}`, values);
    },
    voidFulfillment: async (fulfillmentId) => {
        return await post(`/so-fulfillment/${fulfillmentId}/void`)
    },
    print: async (fulfillmentId) => {
        return await postBlob(`/so-fulfillment/${fulfillmentId}/print`);
    },
    getEmail: async (fulfillmentId) => {
        return await post(`/so-fulfillment/${fulfillmentId}/email/content`);
    },
    sendEmail: async (fulfillmentId, params) => {
        return await post(`/so-fulfillment/${fulfillmentId}/email/send`, params);
    }
}

export const InvoiceAPI = {
    query: async (page, params) => {
        return await get("/invoice", { page: page, ...params });
    },
    get: async (id) => {
        return await get(`/invoice/${id}`);
    },
    add: async (values) => {
        return await put("/invoice", values);
    },
    update: async (id, values) => {
        return await post(`/invoice/${id}`, values);
    },
    voidInvoice: async (id) => {
        return await post(`/invoice/${id}/void`);
    },
    print: async (invoiceId) => {
        return await postBlob(`/invoice/${invoiceId}/print`);
    },
    getEmail: async (id) => {
        return await post(`/invoice/${id}/email/content`);
    },
    sendEmail: async (id, params) => {
        return await post(`/invoice/${id}/email/send`, params);
    },
    actives: async (params) => {
        return await get("/invoice/actives", params);
    },
    payments: async (invoiceId) => {
        return await get(`/invoice/${invoiceId}/payments`);
    },
    getCustomerLastInvoiceItem: async (customerId, itemId) => {
        if (!customerId || !itemId) return;
        return await get(`/invoice/customer/${customerId}/item/${itemId}/last`);
    },
    getItemsBySoId: async (soId) => {
        return await get(`/invoice/so/${soId}/items`);
    }
}

export const TransactionAPI = {
    query: async (page, params) => {
        const criteria = { page: page, ...params };
        return await get("/transaction", criteria);
    },
    get: async (id) => {
        return await get(`/transaction/${id}`);
    },
    add: async (values) => {
        return await put("/transaction", values);
    },
    update: async (id, params) => {
        await post(`/transaction/${id}`, params);
    },
    updateCategory: async (id, params) => {
        await post(`/transaction/${id}/category`, params);
    },
    review: async (id) => {
        await post(`/transaction/${id}/reviewed`);
    },
    unreview: async (id) => {
        await post(`/transaction/${id}/unreviewed`);
    },
    delete: async (id) => {
        await del(`/transaction/${id}`);
    },
    addReceipt: async (params) => {
        return await put("/transaction/receipt", params);
    },
    deleteReceipt: async (tid, rid) => {
        return await del(`/transaction/${tid}/receipt/${rid}`);
    },
    downloadReceipt: async (docId) => {

    },
    relatedTransactions: async (transactionId) => {
        return await get(`/transaction/${transactionId}/related-transactions`);
    }
}

export const JournalAPI = {
    query: async (page, params) => {
        const criteria = { page: page, ...params };
        return await get("/journal", criteria);
    },
    details: async (page, params) => {
        const criteria = { page: page, ...params };
        return await get("/journal/details", criteria);
    },
    get: async (id) => {
        return await get(`/journal/${id}`);
    },
    add: async (values) => {
        return await put("/journal", values);
    },
    update: async (id, params) => {
        await post(`/journal/${id}`, params);
    },
    del: async (id) => {
        await del(`/journal/${id}`);
    }
}

export const ReportAPI = {
    getSalesItems: async (page) => {
        return await get("/report/sales-items", { page: page });
    },
    updateItemForecast: async (itemId, params) => {
        params = { ...params, id: itemId };
        return await post("/report/item-forecast", params);
    },
    getIncomeStatement: async (start, end) => {
        return await get("/report/income-statement", { startDate: start, endDate: end });
    },
    getBalanceSheet: async (date) => {
        return await get("/report/balance-sheet", { date: date });
    }
}

export const GLAPI = {
    query: async (page, type, from, params) => {
        const criteria = { page: page, type: type, from: from, ...params };
        return await get("/gl", criteria);
    }
}

export const BankAPI = {
    connect: async () => {
        return post("/bank/connect");
    },
    delete: async (bankAccountId) => {
        return del(`/bank/account/${bankAccountId}`);
    },
    addAccount: async (params) => {
        return post("/bank/account/add", params);
    },
    accounts: async () => {
        return get("/bank/accounts");
    },
    activeAccounts: async () => {
        return get("/bank/active-accounts");
    },
    account: async (bankAccountId) => {
        return get(`/bank/account/${bankAccountId}`);
    },
    institutions: async () => {
        return get("/bank/institutions");
    },
    bindSystemAccount: async (accountId, systemAccountId) => {
        return post(`/bank/account/${accountId}/bind?systemAccountId=${systemAccountId}`);
    },
    changeAutoTransSync: async (accountId, open) => {
        return post(`/bank/account/${accountId}/auto-trans-sync?open=${open}`);
    },
    updateSyncStartDate: async (bankAccountId, startDate) => {
        return post(`/bank/account/${bankAccountId}/sync-start-date?syncStartDate=${startDate}`);
    },
    getAudits: async (params) => {
        return get(`/bank/audit`, params);
    }
}

export const ReconciliationAPI = {
    reconcile: async (bankAccountId, params) => {
        return post(`/reconciliation?bankAccountId=${bankAccountId}`, params);
    },
    reconciliations: async (bankAccountId) => {
        return get(`/reconciliation?bankAccountId=${bankAccountId}`);
    },
    pendingTransactions: async (bankAccountId) => {
        return get(`/reconciliation/pending-reconciliation-transaction-count?bankAccountId=${bankAccountId}`);
    },
    get: async (reconciliationId) => {
        return get(`/reconciliation/${reconciliationId}`);
    },
    getTransactions: async (reconciliationId, page) => {
        return get(`/reconciliation/${reconciliationId}/transactions`, { page: page });
    },
    updateStatementDateAndBalance: async (reconciliationId, statementDate, statementBalance) => {
        return post(`/reconciliation/${reconciliationId}/statement-date-balance?statementDate=${statementDate}&statementBalance=${statementBalance}`);
    },
    confirm: async (reconciliationId) => {
        return post(`/reconciliation/${reconciliationId}/confirm`);
    },
    updateTransactionStatus: async (reconciliationId, transactionId, cleared) => {
        return post(`/reconciliation/${reconciliationId}/transaction-reconciliation-status?transactionId=${transactionId}&cleared=${cleared}`);
    }
}

export const ContactAPI = {
    submitMessage: async (params) => {
        return put(`/contact/message`, params);
    }
}