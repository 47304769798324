import { useEffect, useState } from 'react';
import { Layout, Menu } from '@arco-design/web-react'
import { IconBook, IconDriveFile, IconEdit, IconMobile, IconSelectAll, IconSettings } from '@arco-design/web-react/icon';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Header } from '@/components/Header';
import { useLogin } from '@/pages/Login/useLogin';
import './home.css';
import { ReactComponent as IconBank } from "@/assets/icons/housing-subsidy.svg";
import _ from "lodash";

const MenuItem = Menu.Item;
const SubMenu = Menu.SubMenu;

export const Home = () => {
    useLogin();
    const location = useLocation();
    const navigate = useNavigate();
    const [selectedKeys, setSelectedKeys] = useState(["items"]);
    const [openKeys, setOpenKeys] = useState(["material"]);

    const onClickMenuItem = (key, event, keyPath) => {
        setSelectedKeys([key]);
        navigate(_.reverse(keyPath).join('/'));
    }

    const onClickSubMenu = (key, openKeys, keyPath) => {
        setOpenKeys(openKeys);
    }

    useEffect(() => {
        const path = location.pathname.substring(1);
        const items = _.split(path, '/', 3);
        if (items.length >= 3) {
            setOpenKeys([items[1]]);
            setSelectedKeys([items[2]]);
        }
    }, [location])

    return (
        <div >
            <Layout>
                <Layout.Header>
                    <Header />
                </Layout.Header>
                <Layout>
                    <Layout.Sider className="layout-sider">
                        <Menu onClickMenuItem={onClickMenuItem}
                            style={{ paddingTop: 65 }}
                            onClickSubMenu={onClickSubMenu}
                            openKeys={openKeys}
                            selectedKeys={selectedKeys}>
                            <SubMenu key="material" title={
                                <span className='top-left-menu'>
                                    <IconBook />Material
                                </span>
                            }>
                                <MenuItem key="items">Items</MenuItem>
                                {/* <MenuItem key="boms">BOMs</MenuItem> */}
                                <MenuItem key="inventory">Inventory</MenuItem>
                                {/* <MenuItem key="inventory-adjustment">Inventory Adjustment</MenuItem> */}
                                <MenuItem key="recall">Recall</MenuItem>
                            </SubMenu>
                            <SubMenu key="purchasing" title={
                                <span className='top-left-menu'>
                                    <IconSelectAll />Purchasing
                                </span>
                            }>
                                <MenuItem key="vendor">Vendor</MenuItem>
                                <MenuItem key="po">Purchase Order</MenuItem>
                                <MenuItem key="receipt">Item Receipt</MenuItem>
                                <MenuItem key="bills">Bills</MenuItem>
                            </SubMenu>
                            {/* <SubMenu key="manufacturing" title={
                                <span className='top-left-menu'>
                                    <IconHome />Manufacturing
                                </span>
                            }>
                                <MenuItem key="wo">Work Order</MenuItem>
                                <MenuItem key="wo-pick-order">Pick Order</MenuItem>
                                <MenuItem key="wo-fulfillment">Fulfillment(WO)</MenuItem>
                            </SubMenu> */}
                            <SubMenu key="sales" title={
                                <span className='top-left-menu'>
                                    <IconMobile />Sales
                                </span>
                            }>
                                <MenuItem key="customer">Customer</MenuItem>
                                <MenuItem key="so">Sales Order</MenuItem>
                                <MenuItem key="so-pick-order">Pick Order</MenuItem>
                                <MenuItem key="so-fulfillment">Fulfillment</MenuItem>
                                <MenuItem key="invoice">Invoices</MenuItem>
                            </SubMenu>
                            <SubMenu key="accounting" title={
                                <span className='top-left-menu'>
                                    <IconEdit />Accounting
                                </span>
                            }>
                                <MenuItem key="transaction">Transactions</MenuItem>
                                <MenuItem key="reconciliation">Account Reconciliation</MenuItem>
                                <MenuItem key="journal-entry">Journal Entries</MenuItem>
                                <MenuItem key="coa">Chart of Accounts</MenuItem>
                            </SubMenu>
                            <SubMenu key="reporting" title={
                                <span className='top-left-menu'>
                                    <IconDriveFile />Reporting
                                </span>
                            }>
                                <MenuItem key="balance-sheet">Balance Sheet</MenuItem>
                                <MenuItem key="income-statement">Income Statement</MenuItem>
                                <MenuItem key="inventory-evaluation">Inventory Evaluation</MenuItem>
                                <MenuItem key="sales-forecast">Sales Forecast</MenuItem>
                                {/* <MenuItem key="doccenter">Doc Center</MenuItem> */}
                            </SubMenu>
                            <SubMenu key="banking" title={
                                <span className='top-left-menu'>
                                    <IconBank className='arco-icon' />Banking
                                </span>
                            }>
                                <MenuItem key="bank-connection">Bank Connection</MenuItem>
                            </SubMenu>
                            {/* <SubMenu key="payroll" title={
                                <span className='top-left-menu'>
                                    <IconApps />Payroll
                                </span>
                            }>
                                <MenuItem key="employee">Employee</MenuItem>
                                <MenuItem key="tax-setting">Tax Setting</MenuItem>
                                <MenuItem key="timecard">Timecard</MenuItem>
                                <MenuItem key="payroll-history">Payroll History</MenuItem>
                                <MenuItem key="run-payroll">Run Payroll</MenuItem>
                            </SubMenu> */}
                            <SubMenu key="settings" title={
                                <span className='top-left-menu'>
                                    <IconSettings />Settings
                                </span>
                            }>
                                {/* <MenuItem key="fifo-lifo">FIFO/LIFO</MenuItem> */}
                                <MenuItem key="warehouse">Warehouse</MenuItem>
                            </SubMenu>
                        </Menu>
                    </Layout.Sider>
                    <Layout.Content className="layout-content" style={{ "paddingLeft": "220px", "paddingTop": "65px" }}>
                        <div className='layout-content-wrapper'>
                            <Outlet />
                        </div>
                    </Layout.Content>
                </Layout>
            </Layout>
        </div >
    )
}