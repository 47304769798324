import { useCallback, useEffect, useState } from "react";
import { ItemReceiptAPI } from "@/apis";
import { UITable, UIText } from "@/components/UI";
import { Button, Input, Modal } from "@arco-design/web-react";
import { IconZoomIn } from "@arco-design/web-react/icon";

const InventoryLotSelect = ({ warehouseId, getItemId, getPickQty, onChange, onSelect, value }) => {
    const [data, setData] = useState([]);
    const [visible, setVisible] = useState(false);
    const [lot, setLot] = useState();

    const getReceiptItem = useCallback(async () => {
        if (value) {
            const values = await ItemReceiptAPI.item(value);
            setLot(values.lot);
        }
    }, [value]);

    const getLots = useCallback(async () => {
        let itemId = getItemId();
        if (itemId) {
            const values = await ItemReceiptAPI.remaining(itemId, warehouseId);
            setData(values);
        }
    }, [warehouseId, getItemId])

    useEffect(() => {
        getReceiptItem();
    }, [getReceiptItem])

    return (
        <>
            <Input.Search readOnly searchButton={true} value={lot} onSearch={() => setVisible(true)} />
            <Modal visible={visible} style={{ width: 800 }}
                afterOpen={getLots}
                closeIcon={false}
                footer={null}
                onCancel={() => setVisible(false)}>
                <UITable columns={[
                    { title: "Item Name", dataIndex: "itemName" },
                    { title: "Location", dataIndex: "locationName" },
                    { title: "Qty", render: (col, record) => <UIText type="number" value={record.remainingQty - record.pickedUnfulfilledQty} /> },
                    { title: "Lot#", dataIndex: "lot" },
                    { title: "Received Date", dataIndex: "receivedDate" },
                    { title: "Expiration Date", dataIndex: "expiredDate" },
                    {
                        title: "", width: 20, render: (col, record) => {
                            let pickQty = getPickQty();
                            if (record.remainingQty >= pickQty) {
                                return (
                                    <Button type="text" icon={<IconZoomIn />} onClick={() => {
                                        setVisible(false);
                                        onChange && onChange(record.id);
                                        onSelect && onSelect(record);
                                    }} />
                                )

                            }
                        }
                    },
                ]}
                    data={data}
                />
            </Modal>
        </>
    )
}

export default InventoryLotSelect;