import { useEffect, useState } from "react";
import { AccountAPI, DictAPI } from "@/apis";
import { Select, Tag, Tooltip } from "@arco-design/web-react";
import { UISelect } from "@/components/UI";
import _ from "lodash";

const AccountSelect = (props) => {
    const { type, category, maxTagCount, ...restProps } = props;
    const [options, setOptions] = useState([]);
    const [grouping] = useState(_.isNil(type));
    const [focus, setFocus] = useState(false);
    const [accounts, setAccounts] = useState([]);
    const [values, setValues] = useState([]);

    useEffect(() => {
        const getOptions = async () => {
            const activeAccounts = await AccountAPI.actives();
            let values = activeAccounts;
            if (!_.isNil(type) || !_.isNil(category)) {
                let types = _.concat([], type);
                types = _.map(types, o => parseInt(o));
                let categories = _.concat([], category);
                categories = _.map(categories, o => parseInt(o));
                values = _.filter(activeAccounts, (item) => types.indexOf(item.accountType) >= 0 || categories.indexOf(item.category) >= 0);
            }

            setAccounts(values);

            if (grouping) {
                const accountTypes = await DictAPI.getItems("account-type");
                _.forEach(accountTypes, (o) => {
                    o.items = _.filter(values, (item) => item.accountType === o.value);
                })
                const types = _.filter(accountTypes, o => o.items != null && o.items.length > 0);
                setOptions(types);
            } else {
                setOptions(values);
            }
        }

        getOptions();
    }, [type, category, grouping]);

    if (grouping) {
        return (
            <Select {...restProps}
                maxTagCount={maxTagCount}
                renderTag={({ label, closable, onClose }, index) => {
                    let tip = label;
                    if (index >= maxTagCount) {
                        tip = _.slice(values, maxTagCount);
                        tip = _.join(_.map(tip, (item) => _.find(accounts, (o) => o.id === item).accountName), ", ");
                    }
                    return (
                        <Tooltip mini
                            color="var(--color-primary-light-4)"
                            content={tip}>
                            <Tag
                                color={focus ? "gray" : "var(--color-white)"}
                                closable={closable}
                                onClose={onClose}
                                style={{ margin: '2px 6px 2px 0' }}>
                                <span style={{ color: "var(--color-text-2)" }}>{label}</span>
                            </Tag>
                        </Tooltip>
                    )
                }}
                filterOption={(inputValue, option) => {
                    return option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                }}
                onFocus={(e) => {
                    setFocus(true);
                    props.onFocus && props.onFocus(e);
                }}
                onBlur={(e) => {
                    setFocus(false);
                    props.onBlur && props.onBlur(e);
                }}
                onChange={(val) => {
                    setValues(val);
                    props.onChange && props.onChange(val);
                }}>
                {options.map((group, index) => {
                    return (
                        <Select.OptGroup label={group.label} key={index}>
                            {group.items.map((option, itemIndex) => (
                                <Select.Option key={`${index}-${itemIndex}`} value={option.id}>
                                    {option.accountName}
                                </Select.Option>
                            ))}
                        </Select.OptGroup>
                    )
                })}
            </Select>
        )
    } else {
        return (
            <UISelect {...restProps}
                maxTagCount={maxTagCount}
                records={options}
                labelKey="accountName"
                onChange={(val) => props.onChange && props.onChange(val)}
            />
        )
    }
}

export default AccountSelect;