import { Card, Checkbox, Space } from "@arco-design/web-react";
import { Fragment, useCallback, useEffect, useState } from "react";
import { ReportAPI, CompanyAPI } from "@/apis";
import { UIText, UIDatePicker } from "@/components/UI";
import "../index.css";
import _ from "lodash";
import { DateUtil, DateTimeUtil } from "@/utils";
import AccountTransactionLink from "@/components/AccountTransactionLink";
import { Link } from "react-router-dom";

export const BalanceSheet = () => {
    const [showZeroAccount, setShowZeroAccount] = useState(false);
    const [companyDate, setCompanyDate] = useState();
    const [date, setDate] = useState(DateUtil.today());
    const [statement, setStatement] = useState();

    const getStatement = useCallback(async () => {
        const values = await ReportAPI.getBalanceSheet(date);
        if (!showZeroAccount) {
            _.forEach(values.currentAssetBalances, (o) => {
                o.accounts = _.filter(o.accounts, (o) => o.totalAmount !== 0);
            });

            _.forEach(values.longTermAssetBalances, (o) => {
                o.accounts = _.filter(o.accounts, (o) => o.totalAmount !== 0);
            });

            _.forEach(values.currentLiabilityBalances, (o) => {
                o.accounts = _.filter(o.accounts, (o) => o.totalAmount !== 0);
            });

            _.forEach(values.longTermLiabilityBalances, (o) => {
                o.accounts = _.filter(o.accounts, (o) => o.totalAmount !== 0);
            });

            values.equityBalance.accounts = _.filter(values.equityBalance.accounts, (o) => o.totalAmount !== 0);
        }
        setStatement(values);
    }, [date, showZeroAccount]);

    useEffect(() => {
        getStatement();
    }, [getStatement])

    useEffect(() => {
        const getCompany = async () => {
            const company = await CompanyAPI.info();
            let date = DateTimeUtil.get(company.createTime).subtract(2, 'year');
            date = DateUtil.format(date);
            setCompanyDate(date);
        }

        getCompany();
    }, [])

    return (
        <Card title="Balance Sheet" extra={
            <Space>
                <Checkbox checked={showZeroAccount} onChange={(val) => setShowZeroAccount(val)}>Show 0 Value Accounts</Checkbox>
                <UIDatePicker placeholder="As of Date" value={date}
                    allowClear={false}
                    onChange={(val) => setDate(val)} />
            </Space>
        }>
            {statement &&
                <table>
                    <tbody>
                        <tr>
                            <td></td>
                            <td className="tree-node-value">{date}</td>
                        </tr>
                        <tr className="tree-node-title">
                            <td className="tree-node-label-1">Assets</td>
                            <td></td>
                        </tr>
                        <tr className="tree-node-title">
                            <td className="tree-node-label-2">Current Assets</td>
                            <td></td>
                        </tr>
                        {statement.currentAssetBalances.map((balance, index) => {
                            return (
                                <Fragment key={index}>
                                    <tr>
                                        <td className="tree-node-label-3">{balance.accountTypeName}</td>
                                        <td></td>
                                    </tr>
                                    {balance.accounts.map((account) => {
                                        return (
                                            <tr key={account.accountId}>
                                                <td className="tree-node-label-4">{account.accountName}</td>
                                                <td className="tree-node-value">
                                                    <AccountTransactionLink accountId={account.accountId} endDate={date}>
                                                        <UIText type="currency" value={account.totalAmount} />
                                                    </AccountTransactionLink>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    <tr>
                                        <td className="tree-node-label-3">Total {balance.accountTypeName}</td>
                                        <td className="tree-node-value"><UIText type="currency" value={balance.totalAmount} /></td>
                                    </tr>
                                </Fragment>
                            )
                        })}
                        <tr className="tree-node-title">
                            <td className="tree-node-label-2">Total Current Assets</td>
                            <td className="tree-node-value"><UIText type="currency" value={statement.totalCurrentAssets} /></td>
                        </tr>
                        <tr className="tree-node-title">
                            <td className="tree-node-label-2">Long-Term Assets</td>
                            <td></td>
                        </tr>
                        {statement.longTermAssetBalances.map((balance, index) => {
                            return (
                                <Fragment key={index}>
                                    <tr>
                                        <td className="tree-node-label-3">{balance.accountTypeName}</td>
                                        <td></td>
                                    </tr>
                                    {balance.accounts.map((account) => {
                                        return (
                                            <tr key={account.accountId}>
                                                <td className="tree-node-label-4">{account.accountName}</td>
                                                <td className="tree-node-value">
                                                    <AccountTransactionLink accountId={account.accountId} endDate={date}>
                                                        <UIText type="currency" value={account.totalAmount} />
                                                    </AccountTransactionLink>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    <tr>
                                        <td className="tree-node-label-3">Total {balance.accountTypeName}</td>
                                        <td className="tree-node-value"><UIText type="currency" value={balance.totalAmount} /></td>
                                    </tr>
                                </Fragment>
                            )
                        })}
                        <tr className="tree-node-title">
                            <td className="tree-node-label-summary-2">Total Long-Term Assets</td>
                            <td className="tree-node-value"><UIText type="currency" value={statement.totalLongTermAssets} /></td>
                        </tr>
                        <tr className="tree-node-title">
                            <td className="tree-node-label-1">Total Assets</td>
                            <td className="tree-node-value"><UIText type="currency" value={statement.totalAssets} /></td>
                        </tr>
                        <tr className="tree-node-title">
                            <td className="tree-node-label-1">Liabilities</td>
                            <td></td>
                        </tr>
                        <tr className="tree-node-title">
                            <td className="tree-node-label-2">Current Liabilities</td>
                            <td></td>
                        </tr>
                        {statement.currentLiabilityBalances.map((balance, index) => {
                            return (
                                <Fragment key={index}>
                                    <tr>
                                        <td className="tree-node-label-3">{balance.accountTypeName}</td>
                                        <td></td>
                                    </tr>
                                    {balance.accounts.map((account) => {
                                        return (
                                            <tr key={account.accountId}>
                                                <td className="tree-node-label-4">{account.accountName}</td>
                                                <td className="tree-node-value">
                                                    <AccountTransactionLink accountId={account.accountId} endDate={date}>
                                                        <UIText type="currency" value={account.totalAmount} />
                                                    </AccountTransactionLink>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    <tr>
                                        <td className="tree-node-label-3">Total {balance.accountTypeName}</td>
                                        <td className="tree-node-value"><UIText type="currency" value={balance.totalAmount} /></td>
                                    </tr>
                                </Fragment>
                            )
                        })}
                        <tr className="tree-node-title">
                            <td className="tree-node-label-summary-2">Total Current Liabilities</td>
                            <td className="tree-node-value"><UIText type="currency" value={statement.totalCurrentLiabilities} /></td>
                        </tr>
                        <tr className="tree-node-title">
                            <td className="tree-node-label-2">Long-Term Liabilities</td>
                            <td></td>
                        </tr>
                        {statement.longTermLiabilityBalances.map((balance, index) => {
                            return (
                                <Fragment key={index}>
                                    <tr>
                                        <td className="tree-node-label-3">{balance.accountTypeName}</td>
                                        <td></td>
                                    </tr>
                                    {balance.accounts.map((account) => {
                                        return (
                                            <tr key={account.accountId}>
                                                <td className="tree-node-label-4">{account.accountName}</td>
                                                <td className="tree-node-value">
                                                    <AccountTransactionLink accountId={account.accountId} endDate={date}>
                                                        <UIText type="currency" value={account.totalAmount} />
                                                    </AccountTransactionLink>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    <tr>
                                        <td className="tree-node-label-3">Total {balance.accountTypeName}</td>
                                        <td className="tree-node-value"><UIText type="currency" value={balance.totalAmount} /></td>
                                    </tr>
                                </Fragment>
                            )
                        })}
                        <tr className="tree-node-title">
                            <td className="tree-node-label-summary-2">Total Long-Term Liabilities</td>
                            <td className="tree-node-value"><UIText type="currency" value={statement.totalLongTermLiabilities} /></td>
                        </tr>
                        <tr className="tree-node-title">
                            <td className="tree-node-label-1">Total Liabilities</td>
                            <td className="tree-node-value"><UIText type="currency" value={statement.totalLiabilities} /></td>
                        </tr>
                        <tr className="tree-node-title">
                            <td className="tree-node-label-1">Equity</td>
                            <td></td>
                        </tr>
                        {statement.equityBalance.accounts.map((account, index) => {
                            return (
                                <tr key={index}>
                                    <td className="tree-node-label-2">{account.accountName}</td>
                                    <td className="tree-node-value">
                                        {account.accountName === "Retained Income"
                                            ?
                                            <Link style={{ textDecoration: "none" }}
                                                to={`/workspace/reporting/income-statement?startDate=${companyDate}&endDate=${date}`}>
                                                <UIText type="currency" value={account.totalAmount} />
                                            </Link>
                                            :
                                            <AccountTransactionLink accountId={account.accountId} endDate={date}>
                                                <UIText type="currency" value={account.totalAmount} />
                                            </AccountTransactionLink>
                                        }
                                    </td>
                                </tr>
                            )
                        })}
                        <tr className="tree-node-title">
                            <td className="tree-node-label-summary-1">Total Equity</td>
                            <td className="tree-node-value"><UIText type="currency" value={statement.equityBalance.totalAmount} /></td>
                        </tr>
                    </tbody>
                </table>
            }
        </Card>
    )
}
