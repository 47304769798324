import { Button, Card, Message, Space } from "@arco-design/web-react"
import { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BillForm } from "./bill-form";
import { ItemReceiptAPI, VendorAPI, BillAPI } from "@/apis";
import { DateUtil, TermUtil } from "@/utils";
import _ from "lodash";

export const BillAdd = () => {
    const navigate = useNavigate();
    const params = useParams();
    const receiptId = params.receiptId;
    const [bill, setBill] = useState();
    const formRef = useRef(null);

    const save = async () => {
        try {
            const values = await formRef.current.submit();
            const billId = await BillAPI.add(values);
            Message.success("Bill created successfully");
            navigate("/workspace/purchasing/bills/" + billId);
        } catch (error) {
            Message.error(error.message);
        }
    }

    const load = useCallback(async () => {
        if (receiptId) {
            const receipt = await ItemReceiptAPI.get(receiptId);
            const vendor = await VendorAPI.get(receipt.vendorId);

            const values = {
                vendorId: receipt.vendorId,
                vendorAddress: vendor.billingAddress,
                billDate: receipt.receiveDate,
                receiptId: receipt.id,
                receiptNo: receipt.receiptNo,
                poNo: receipt.poNo,
                term: vendor.term ?? 1,
                reference: receipt.reference || []
            };
            values.dueDate = DateUtil.format(TermUtil.dueDate(values.billDate, values.term));

            let billItems = [];
            for (const index in receipt.items) {
                const item = receipt.items[index];
                const found = _.find(billItems, o => o.itemId === item.itemId && o.cost === item.rate);
                if (found) {
                    found.quantity += item.quantity;
                    found.amount = found.quantity * found.cost;
                } else {
                    billItems.push({
                        key: billItems.length,
                        itemId: item.itemId,
                        description: item.description,
                        quantity: item.quantity,
                        unit: item.unit,
                        unitName: item.unitName,
                        cost: item.rate,
                        amount: item.amount
                    });
                }
            }

            values.items = billItems;
            setBill(values);
        } else {
            setBill({
                billDate: DateUtil.today(),
            });
        }
    }, [receiptId]);

    useEffect(() => {
        load();
    }, [load]);

    return (
        <Card title="Add New Bill" extra={
            <Space>
                <Button type="primary" onClick={() => save()}>Save</Button>
                <Link to="/workspace/purchasing/bills">
                    <Button >Back</Button>
                </Link>
            </Space>
        }>
            {bill &&
                <BillForm ref={formRef} value={bill} />
            }
        </Card>
    )
}
