import { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PickOrderForm } from "./pick-order-form";
import { SOAPI, SOPOAPI } from "@/apis";
import { Button, Card, Message, Space } from "@arco-design/web-react";

export const PickOrderAdd = () => {
    const params = useParams();
    const navigate = useNavigate();
    const formRef = useRef(null);
    const [po, setPO] = useState();

    const getSO = useCallback(async () => {
        const values = await SOAPI.get(params.soId);
        let info = {
            soId: values.id,
            soNo: values.soNo,
            customerName: values.customerName,
            address: `${values.shipToAddress}, ${values.shipToCity}, ${values.shipToState} ${values.shipToZip}`,
            soDueDate: values.dueDate,
            soCreateDate: values.soDate,
        }
        setPO(info)
    }, [params])

    const save = async () => {
        try {
            const values = await formRef.current.getFormValues();
            const id = await SOPOAPI.add(values);
            Message.success("SO pick order created successfully");
            navigate(`/workspace/sales/so-pick-order/${id}`);
        } catch (e) {
            Message.error(e.message);
        }
    }

    useEffect(() => {
        getSO();
    }, [getSO])

    return (
        <Card title="SO Pick Order" extra={
            <Space>
                <Button type="primary" onClick={() => save()}>Save</Button>
                <Link to={`/workspace/sales/so/${params.soId}`}>
                    <Button>Cancel</Button>
                </Link>
            </Space>
        }>
            {po &&
                <PickOrderForm ref={formRef} value={po} />
            }
        </Card>

    )
}