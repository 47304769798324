import { useEffect, useState } from "react";
import _ from "lodash";
import { Select } from "@arco-design/web-react";

const AddressSelect = (props) => {
    const [options, setOptions] = useState([]);
    const { value, addresses, ...restProps } = props;

    useEffect(() => {
        const getOptions = async () => {
            const values = _.map(addresses, (item) => {
                return item.contactName
            });
            setOptions(values);
        }

        getOptions();
    }, [addresses]);


    return (
        <Select {...restProps}
            allowClear
            value={value}
            triggerProps={{
                autoAlignPopupWidth: false,
                autoAlignPopupMinWidth: true,
                position: 'bl',
            }}
            onChange={val => {
                let addr = _.find(addresses, item => item.contactName === val);
                if (addr) {
                    props.onAddrChange && props.onAddrChange(addr);
                }
                props.onChange && props.onChange(val);
            }}
            options={options}>
        </Select>
    );
}

const Address = (props) => {
    const { value, inline, ...restProps } = props;

    if (inline) {
        return (
            <span {...restProps}>
                {value.name &&
                    <span>{value.name}. </span>
                }
                {`${value.address}, ${value.city}, ${value.state} ${value.zip}`}
            </span>
        )

    }

    return (
        <div {...restProps}>
            {value.name &&
                <>
                    <span>{value.name}</span><br />
                </>
            }
            {value.address &&
                <>
                    <span>{value.address},</span><br />
                </>
            }
            {value.city &&
                <span>{value.city}, {value.state} {value.zip}</span>
            }
        </div>
    )
}

Address.Select = AddressSelect;

export default Address;