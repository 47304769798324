import { Card, Space } from "@arco-design/web-react";
import { useCallback, useEffect, useState } from "react";
import { ItemReceiptAPI } from "@/apis";
import { Link } from "react-router-dom";
import { UITable, UISelect } from "@/components/UI";
import { ReceiptInfo } from "./receipt-info";
import { ReceiptAdd } from "./receipt-add";

export const ItemReceipt = () => {
    const [status, setStatus] = useState(null);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);

    const getReceipts = useCallback(async () => {
        const values = await ItemReceiptAPI.query(page, { status: status });
        setData(values.records);
        setTotal(values.total);

    }, [page, status]);

    useEffect(() => {
        getReceipts();
    }, [getReceipts]);


    return (
        <Card title="Item Receipts" extra={
            <Space>
                <UISelect options={[
                    { label: "Not Billed", value: 1 },
                    { label: "Partial", value: 2 },
                    { label: "Fully Billed", value: 3 },
                ]} allowAll
                    value={status}
                    placeholder="Status" style={{ width: 200 }}
                    onChange={(val) => {
                        setStatus(val);
                    }} />

            </Space>
        }>
            <UITable columns={[
                {
                    title: "Item Receipt#",
                    render: (col, record, index) =>
                        <Link to={"/workspace/purchasing/receipt/" + record.id}>{record.receiptNo}</Link>
                },
                { title: "PO#", dataIndex: "poNo" },
                { title: "Status", dataIndex: "statusDesc" },
                { title: "Vendor", dataIndex: "vendorName" },
                { title: "Receive Date", dataIndex: "receiveDate" },
            ]} total={total} data={data} onChange={pageNum => setPage(pageNum)}
            />
        </Card>
    );
}

ItemReceipt.Info = ReceiptInfo;
ItemReceipt.Add = ReceiptAdd;