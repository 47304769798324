import { useEffect, useState } from "react";
import { VendorAPI } from "@/apis";
import { UISelect } from "@/components/UI";
import _ from "lodash";

export const VendorSelect = (props) => {
    const { type, ...restProps } = props;
    const [options, setOptions] = useState([]);

    useEffect(() => {
        const getOptions = async () => {
            const values = await VendorAPI.actives();
            setOptions(_.orderBy(values, ['vendorName'], ['asc']));
        }

        getOptions();
    }, [type]);

    return (
        <UISelect {...restProps}
            records={options}
            labelKey="vendorName"
            showSearch
            onChange={(val) => props.onChange && props.onChange(val)}>
        </UISelect >
    );
}

export default VendorSelect;