import { Button, Card, Message, Space } from "@arco-design/web-react";
import { useEffect, useRef, useState } from "react";
import { ItemReceiptAPI, POAPI } from "@/apis";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ReceiptForm } from "./receipt-form";
import { DateUtil } from "@/utils";
import _ from "lodash";

export const ReceiptAdd = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [info, setInfo] = useState();
    const [poNo, setPoNo] = useState();
    const formRef = useRef(null);

    useEffect(() => {

        const init = async () => {
            const values = await POAPI.get(params.poId);
            const receiveds = await ItemReceiptAPI.received(params.poId);
            _.forEach(values.items, (item) => {
                const receivedQuantity = receiveds[item.itemId];
                if (receivedQuantity) {
                    item.quantity = Math.max(0, item.quantity - receivedQuantity);
                    item.amount = item.quantity * item.rate;
                }
            });
            values.items = _.filter(values.items, item => item.quantity > 0);

            setPoNo(values.poNo);
            setInfo({
                vendorId: values.vendorId,
                vendorName: values.vendorName,
                dropShip: values.dropShip,
                shipToId: values.shipToId,
                shipToContactName: values.shipToContactName,
                shipToAddress: values.shipToAddress,
                shipToCity: values.shipToCity,
                shipToState: values.shipToState,
                shipToZip: values.shipToZip,
                poId: values.id,
                poNo: values.poNo,
                reference: values.reference,
                items: _.map(values.items, item => ({
                    itemId: item.itemId,
                    itemName: item.itemName,
                    itemNo: item.itemNo,
                    description: item.description,
                    unit: item.unit,
                    quantity: item.quantity,
                    rate: item.rate,
                    amount: item.amount
                })),
                receiveDate: DateUtil.today()
            });
        }

        init();
    }, [params])

    return <Card title={`Item Receipt [${poNo}]`} extra={
        <Space>
            <Button type="primary" onClick={async () => {
                const values = await formRef.current.getFormData();
                const receiptId = await ItemReceiptAPI.add(values);
                Message.success("Receipt created successfully");
                navigate(`/workspace/purchasing/receipt/${receiptId}`);
            }}>Save</Button>
            <Link to={`/workspace/purchasing/po/${params.poId}`}>
                <Button>Cancel</Button>
            </Link>
        </Space>
    }>
        {info &&
            <ReceiptForm ref={formRef} value={info} />
        }
    </Card>
}