import { Button, Card, DatePicker, Space } from "@arco-design/web-react";
import { UITable, UIText } from "@/components/UI";
import { useCallback, useState } from "react";
import { InventoryAPI } from "@/apis";
import { ItemSelect } from "@/components/ItemSelect";
import { LotSelect } from "@/components/LotSelect";
import { Link } from "react-router-dom";
import dayjs from "dayjs";

export const InventoryEvaluation = () => {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);

    const [itemId, setItemId] = useState();
    const [lot, setLot] = useState("");
    const [dateRange, setDateRange] = useState(null);

    const search = useCallback(async () => {
        if (!itemId) return;
        const result = await InventoryAPI.evaluation(page, { itemId, lot, dateRange });
        for (let index = 0; index < result.length; index++) {
            const element = result[index];
            const prev = index <= 0 ? { endQty: 0, endValue: 0 } : result[index - 1];
            element.id = index;
            element.endQty = prev.endQty + element.changeQty;
            element.endValue = prev.endValue + element.amount;
        }
        setData(result);
    }, [page, itemId, lot, dateRange]);

    const getDocUrl = (docId, docType) => {
        switch (docType) {
            case "Receipt":
                return `/workspace/purchasing/receipt/${docId}`;
            case "Fulfillment":
                return `/workspace/sales/so-fulfillment/${docId}`;
            default:
                return null;
        }
    }

    return (
        <Card title="Inventory Evaluation" extra={
            <Space>
                <ItemSelect style={{ width: 180 }} onChange={setItemId} placeholder="Item Name" />
                <LotSelect style={{ width: 180 }} itemId={itemId} onChange={setLot} placeholder="Lot" />
                <DatePicker.RangePicker
                    defaultValue={[dayjs().add(-30, 'day'), dayjs()]}
                    format="M/D/YYYY"
                    onChange={(val) => setDateRange(val)}
                />
                <Button type="primary" onClick={search}>Search</Button>
            </Space>
        }>
            <UITable columns={[
                { title: "Date", dataIndex: "date" },
                { title: "Doc#", render: (col, record) => <Link to={getDocUrl(record.docId, record.docType)}>{record.docNo}</Link> },
                { title: "Lot#", dataIndex: "lot" },
                { title: "Qty Change", render: (col, record) => <UIText type="number" value={record.changeQty} /> },
                { title: "Amount", render: (col, record) => <UIText type="currency" value={record.amount} /> },
                { title: "End Qty", render: (col, record) => <UIText type="number" value={record.endQty} /> },
                { title: "End Value", render: (col, record) => <UIText type="currency" value={record.endValue} /> },
            ]}
                data={data} onChange={setPage}
            />
        </Card>
    );
}
