import { Button, Card, Descriptions, Message, Modal, Popconfirm, Space } from "@arco-design/web-react";
import { SOAPI, SOPOAPI, SOFulfillmentAPI, ItemAPI, CustomerAPI } from "@/apis";
import { useCallback, useEffect, useRef, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { UITable, UITag, UIText } from "@/components/UI";
import { RefUtil, NumericUtil, FileUtil } from "@/utils";
import { SaleOrderForm } from "./so-form";
import Address from "@/components/Address";
import _ from "lodash";
import qs from "qs";
import SendEmailButton from "@/components/SendEmailButton";

const CreateInvoiceButton = ({ soInfo }) => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [visible, setVisible] = useState(false);
    const [fulfillmentIds, setFulfillmentIds] = useState();

    const getFulfillments = async () => {
        const values = await SOAPI.noInvoiceFulfillments(soInfo.id);
        setData(values);
    }

    const createInvoice = async () => {
        let hasInventoryItem = false;
        for (const i in soInfo.items) {
            const item = await ItemAPI.get(soInfo.items[i].itemId);
            if (item.itemType === 1) {
                hasInventoryItem = true;
                break;
            }
        }
        if (!hasInventoryItem) {
            navigate(`/workspace/sales/invoice/so/${soInfo.id}`);
            return;
        }

        const noInvoiceFulfillments = await SOAPI.noInvoiceFulfillments(soInfo.id);
        if (_.isEmpty(noInvoiceFulfillments)) {
            Message.warning("Please fulfill this SO first then create invoice");
            return;
        }
        if (noInvoiceFulfillments.length === 1) {
            navigate(`/workspace/sales/invoice/so/${soInfo.id}?fulfillmentId=${noInvoiceFulfillments[0].id}`);
            return;
        }

        getFulfillments();
        setVisible(true);
    }

    return (
        <>
            <Button type="primary" onClick={() => createInvoice()}>Create Invoice</Button>
            <Modal visible={visible} closeIcon={false}
                onCancel={() => setVisible(false)}
                onOk={() => {
                    const params = qs.stringify({ fulfillmentId: fulfillmentIds }, { arrayFormat: 'repeat' });
                    navigate(`/workspace/sales/invoice/so/${soInfo.id}?${params}`)
                }}
            >
                <UITable columns={[
                    { title: "Fulfillment Order#", dataIndex: "soFulfillmentNo" },
                    { title: "Date", dataIndex: "fulfillmentDate" }
                ]}
                    rowSelection={{
                        type: "checkbox",
                        onChange: (selectedRowKeys, selectedRows) => {
                            setFulfillmentIds(selectedRowKeys)
                        }
                    }}
                    data={data} />
            </Modal>
        </>
    )
}

export const SaleOrderInfo = () => {
    const params = useParams();
    const navigate = useNavigate();
    const soId = params.soId;
    const [info, setInfo] = useState({});
    const [fulfillmentList, setFulfillmentList] = useState([]);
    const [fulfillmentListTotal, setFulfillmentListTotal] = useState(0);
    const [fulfillmentListPage, setFulfillmentListPage] = useState(1);
    const [poList, setPOList] = useState([]);
    const [poListTotal, setPOListTotal] = useState(0);
    const [poListPage, setPOListPage] = useState(1);
    const [printLoading, setPrintLoading] = useState(false);

    const [editing, setEditing] = useState(false);
    const formRef = useRef(null);

    const getInfo = useCallback(async () => {
        const values = await SOAPI.get(soId);
        const fulfillmentItems = await SOAPI.fulfillmentItems(soId);
        const fulfilledItemQuantities = _.chain(fulfillmentItems).groupBy("itemId").map((v, k) => {
            return {
                itemId: k,
                quantity: _.sumBy(v, "qtyFulfilled")
            }
        }).value();
        _.forEach(values.items, (v) => {
            const fulfilledItem = _.find(fulfilledItemQuantities, { itemId: v.itemId.toString() });
            v.fulfilledQty = fulfilledItem != null ? fulfilledItem.quantity : 0;
        });

        setInfo(values);
    }, [soId]);

    const issue = async () => {
        await SOAPI.issue(info.id);
        Message.success("Sale order issued successfully");
        getInfo();
    }

    const unissue = async () => {
        await SOAPI.unissue(info.id);
        Message.success("Sale order unissued successfully");
        getInfo();
    }

    const shortClose = async () => {
        await SOAPI.shortClose(info.id);
        Message.success("Sale order short closed successfully");
        getInfo();
    }

    const voidSO = async () => {
        await SOAPI.voidSO(info.id);
        Message.success("Sale order voided successfully");
        getInfo();
    }

    const save = async () => {
        try {
            const values = await formRef.current.getFormData();
            await SOAPI.update(values.id, values);
            Message.success("Sale Order updated successfully");
            getInfo();
            setEditing(false);
        } catch (error) {
            Message.error(error.messge);
        }
    }

    const getFulfillments = useCallback(async () => {
        const values = await SOFulfillmentAPI.query(fulfillmentListPage, { soId: soId });
        setFulfillmentList(values.records);
        setFulfillmentListTotal(values.total);
    }, [fulfillmentListPage, soId])

    const getPOs = useCallback(async () => {
        const values = await SOPOAPI.query(poListPage, { soId: soId });
        setPOList(values.records);
        setPOListTotal(values.total);
    }, [poListPage, soId])

    useEffect(() => {
        getInfo();
    }, [getInfo])

    useEffect(() => {
        getFulfillments();
    }, [getFulfillments]);

    useEffect(() => {
        getPOs();
    }, [getPOs]);

    return <Card title={
        <Space>
            <span>{info.soNo}</span>
            <UITag value={info.statusName} />
        </Space>
    } extra={editing
        ? <Space>
            <Button type="primary" onClick={() => save()}>Save</Button>
            <Button onClick={() => setEditing(false)}>Cancel</Button>
        </Space>
        : <Space>
            {info.status !== 10 &&
                <Button type="primary" onClick={() => setEditing(true)}>Edit</Button>
            }
            {info.status === 1 &&
                <Button type="primary" onClick={() => issue()}>Issue</Button>
            }
            {info.status === 2 &&
                <Button type="primary" onClick={() => unissue()}>Unissue</Button>
            }
            {info.status < 6 &&
                <Button type="primary" onClick={() => navigate(`/workspace/sales/so-pick-order/so/${info.id}`)}>Pick</Button>
            }
            {info.status < 6 &&
                <Button type="primary" onClick={() => navigate(`/workspace/sales/so-fulfillment/so/${info.id}`)}>Fulfill SO</Button>
            }
            {(info.status === 4 || info.status === 5) &&
                <Button type="primary" onClick={() => shortClose()}>Short Close</Button>
            }
            {info.status !== 10 &&
                <CreateInvoiceButton soInfo={info} />
            }
            {info.status < 3 &&
                <Popconfirm title="Are you sure you want to void sale order?"
                    onOk={() => voidSO()}>
                    <Button type="primary" status="danger">Void</Button>
                </Popconfirm>
            }
            {info.status !== 10 &&
                <Button type="primary" onClick={async () => {
                    setPrintLoading(true);
                    try {
                        const res = await SOAPI.print(info.id);
                        FileUtil.download(res.data, `${info.soNo}.pdf`);
                    } catch (error) {
                        Message.error(error.message);
                    } finally {
                        setPrintLoading(false);
                    }
                }} loading={printLoading}>Print</Button>
            }
            {info.status !== 10 &&
                <SendEmailButton getEmailContent={async () => {
                    return await SOAPI.getEmail(info.id);
                }} getToList={async () => {
                    const customer = await CustomerAPI.get(info.customerId);
                    return customer.contacts;
                }} sendEmail={async (val) => {
                    await SOAPI.sendEmail(info.id, val);
                }} />
            }
            <Button onClick={() => navigate("/workspace/sales/so")}>Back</Button>
        </Space>
    }>
        {editing
            ? <SaleOrderForm ref={formRef} value={info} />
            : <>
                <Descriptions colon=":"
                    data={[{
                        label: "Customer",
                        value: info.customerName
                    }, {
                        label: "Ship to",
                        value: <Address value={{
                            name: info.shipToContactName,
                            address: info.shipToAddress,
                            city: info.shipToCity,
                            state: info.shipToState,
                            zip: info.shipToZip
                        }} inline />
                    }, {
                        label: "SO Date",
                        value: info.soDate
                    }, {
                        label: "Due Date",
                        value: info.dueDate
                    }, {
                        label: "Term",
                        value: info.termName
                    }, {
                        label: "SO Total",
                        value: NumericUtil.currency(info.totalAmount)
                    }, {
                        label: "Reference#",
                        value: RefUtil.format(info.reference),
                        span: 3
                    }, {
                        label: "Memo",
                        value: info.memo,
                        span: 3
                    }]} />

                <UITable columns={[
                    { title: "Item Number", dataIndex: "itemNo" },
                    { title: "Item Name", dataIndex: "itemName" },
                    { title: "Description", dataIndex: "description" },
                    {
                        title: "Qty",
                        render: (col, record) => <UIText type="number" value={record.quantity} />
                    },
                    {
                        title: "Fulfilled Qty",
                        render: (col, record) => <UIText type="number" value={record.fulfilledQty} />
                    },
                    {
                        title: "Open Qty",
                        render: (col, record) => <UIText type="number" value={record.quantity - record.fulfilledQty} />
                    },
                    { title: "U/M", dataIndex: "unitName" },
                    { title: "Rate", dataIndex: "rate" },
                    { title: "Amount", render: (col, record) => <UIText type="currency" value={record.amount} /> },
                ]}
                    data={info.items}
                />

                <UITable style={{ marginTop: 15 }} columns={[
                    {
                        title: "SO Fulfillment#",
                        render: (col, record) =>
                            <Link to={"/workspace/sales/so-fulfillment/" + record.id}>{record.soFulfillmentNo}</Link>
                    },
                    { title: "Status", dataIndex: "statusDesc" },
                    { title: "Fulfillment Date", dataIndex: "fulfillmentDate" },
                ]} data={fulfillmentList} total={fulfillmentListTotal} onChange={(val) => setFulfillmentListPage(val)} />

                <UITable style={{ marginTop: 15 }} columns={[
                    {
                        title: "Pick Order#",
                        render: (col, record) => {
                            return <Link to={"/workspace/sales/so-pick-order/" + record.id}>{record.pickOrderNo}</Link>
                        }
                    },
                    { title: "Status", dataIndex: "statusDesc" },
                    { title: "Warehouse", dataIndex: "warehouseName" },
                ]} data={poList} total={poListTotal} onChange={(val) => setPOListPage(val)} />

            </>
        }
    </Card>
}