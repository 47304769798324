import { Card, Checkbox, DatePicker, Space } from "@arco-design/web-react";
import { IconInfoCircle } from "@arco-design/web-react/icon";
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from "react";
import { ReportAPI } from "@/apis";
import { UIText, UISelect } from "@/components/UI";
import "../index.css";
import _ from "lodash";
import AccountTransactionLink from "@/components/AccountTransactionLink";
import { useSearchParams } from "react-router-dom";

const pattern = "M/D/YYYY";

export const IncomeStatement = () => {
    const [searchParams] = useSearchParams();
    const start = searchParams.get("startDate");
    const end = searchParams.get("endDate");
    const [showZeroAccount, setShowZeroAccount] = useState(false);
    const [rangeDate, setRangeDate] = useState([start, end]);
    const [statement, setStatement] = useState();
    const [period, setPeriod] = useState();

    const onPeriodChanged = (val) => {
        let start, end;
        switch (val) {
            case 1:
                start = dayjs().startOf('year');
                end = dayjs().endOf('year');
                break;
            case 2:
                start = dayjs().startOf('month');
                end = dayjs().endOf('month');
                break;
            case 3:
                start = dayjs().startOf('quarter');
                end = dayjs().endOf('quarter');
                break;
            case 4:
                start = dayjs().subtract(1, 'year').startOf('year');
                end = dayjs().subtract(1, 'year').endOf('year');
                break;
            case 5:
                start = dayjs().subtract(1, 'month').startOf('month');
                end = dayjs().subtract(1, 'month').endOf('month');
                break;
            case 6:
                start = dayjs().subtract(1, 'quarter').startOf('quarter');
                end = dayjs().subtract(1, 'quarter').endOf('quarter');
                break;
            default:
                break;
        }

        const dateRange = [start.format(pattern), end.format(pattern)];
        setRangeDate(dateRange);
        setPeriod(val);
    }

    const getStatement = useCallback(async () => {
        if (rangeDate[0] == null) {
            onPeriodChanged(2);
        }

        const values = await ReportAPI.getIncomeStatement(rangeDate[0], rangeDate[1]);
        if (!showZeroAccount) {
            values.incomeStats = _.filter(values.incomeStats, (o) => o.totalAmount !== 0);
            values.cogsStats = _.filter(values.cogsStats, (o) => o.totalAmount !== 0);
            values.expenseStats = _.filter(values.expenseStats, (o) => o.totalAmount !== 0);
        }
        setStatement(values);
    }, [rangeDate, showZeroAccount]);

    useEffect(() => {
        getStatement();
    }, [getStatement])

    return (
        <Card title={
            <Space>
                <span>Income Statement</span>
                <Checkbox style={{ marginLeft: 20, fontWeight: "normal" }} checked={showZeroAccount} onChange={(val) => setShowZeroAccount(val)}>Show 0 Value Accounts</Checkbox>
            </Space>
        } extra={
            <Space>
                <UISelect style={{ width: 120 }} value={period} placeholder="Period" options={[
                    { label: "This Year", value: 1 },
                    { label: "This Month", value: 2 },
                    { label: "This Quarter", value: 3 },
                    { label: "Last Year", value: 4 },
                    { label: "Last Month", value: 5 },
                    { label: "Last Quarter", value: 6 },
                ]} onChange={(val) => {
                    onPeriodChanged(val);
                }} />
                <DatePicker.RangePicker prefix={<IconInfoCircle />}
                    shortcutsPlacementLeft
                    format="M/D/YYYY"
                    value={rangeDate}
                    onChange={(val) => setRangeDate(val)}
                />
            </Space>
        }>
            {statement &&
                <table>
                    <tbody>
                        <tr>
                            <td></td>
                            <td>{_.join(rangeDate, " - ")}</td>
                        </tr>
                        <tr className="tree-node-title">
                            <td className="tree-node-label-1">Income</td>
                            <td></td>
                        </tr>
                        {statement.incomeStats.map((account, index) => {
                            return (
                                <tr key={index}>
                                    <td className="tree-node-label-3">{account.accountName}</td>
                                    <td className="tree-node-value">
                                        <AccountTransactionLink accountId={account.accountId} startDate={rangeDate[0]} endDate={rangeDate[1]}>
                                            <UIText type="currency" value={account.totalAmount} />
                                        </AccountTransactionLink>
                                    </td>
                                </tr>
                            )
                        })}
                        <tr>
                            <td className="tree-node-label-summary-2">Total Income</td>
                            <td className="tree-node-value-summary"><UIText type="currency" value={statement.totalIncome} /></td>
                        </tr>
                        <tr className="tree-node-title">
                            <td className="tree-node-label-1">Cost of Goods Sold</td>
                            <td></td>
                        </tr>
                        {statement.cogsStats.map((account, index) => {
                            return (
                                <tr key={index}>
                                    <td className="tree-node-label-3">{account.accountName}</td>
                                    <td className="tree-node-value">
                                        <AccountTransactionLink accountId={account.accountId} startDate={rangeDate[0]} endDate={rangeDate[1]}>
                                            <UIText type="currency" value={account.totalAmount} />
                                        </AccountTransactionLink>
                                    </td>
                                </tr>
                            )
                        })}
                        <tr >
                            <td className="tree-node-label-summary-2">Total COGS</td>
                            <td className="tree-node-value-summary"><UIText type="currency" value={statement.totalCOGS} /></td>
                        </tr>
                        <tr >
                            <td className="tree-node-label-summary-2">Gross Profit</td>
                            <td className="tree-node-value-summary"><UIText type="currency" value={statement.grossProfit} /></td>
                        </tr>
                        <tr className="tree-node-title">
                            <td className="tree-node-label-1">Expense</td>
                            <td></td>
                        </tr>
                        {statement.expenseStats.map((account, index) => {
                            return (
                                <tr key={index}>
                                    <td className="tree-node-label-3">{account.accountName}</td>
                                    <td className="tree-node-value">
                                        <AccountTransactionLink accountId={account.accountId} startDate={rangeDate[0]} endDate={rangeDate[1]}>
                                            <UIText type="currency" value={account.totalAmount} />
                                        </AccountTransactionLink>
                                    </td>
                                </tr>
                            )
                        })}
                        <tr>
                            <td className="tree-node-label-summary-2">Total Expense</td>
                            <td className="tree-node-value-summary"><UIText type="currency" value={statement.totalExpense} /></td>
                        </tr>
                        <tr className="tree-node-title">
                            <td className="tree-node-label-summary-1">Net Income</td>
                            <td className="tree-node-value-summary"><UIText type="currency" value={statement.netIncome} /></td>
                        </tr>
                    </tbody>
                </table>
            }
        </Card>
    )
}