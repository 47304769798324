export const FileUtil = {
    download: (data, filename) => {
        const objUrl = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = objUrl;
        link.setAttribute('download', filename)
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        link.parentElement.removeChild(link);
    }
}