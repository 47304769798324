import { Button, Form, Grid, Input, Select, Space } from "@arco-design/web-react"
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { UIInputNumber } from "@/components/UI";
import UOMSelect from "@/components/UOMSelect";
import { SOAPI, SOPOAPI } from "@/apis";
import { IconDelete, IconPlus } from "@arco-design/web-react/icon";
import _ from "lodash";
import WarehouseSelect from "@/components/WarehouseSelect";
import InventoryLotSelect from "@/components/InventoryLotSelect";
import { NumericUtil } from "@/utils";

const FormItem = Form.Item;
const Row = Grid.Row;
const Col = Grid.Col;

export const PickOrderForm = forwardRef((props, ref) => {
    const { value } = props;
    const [form] = Form.useForm();
    const [soItems, setSOItems] = useState([]);
    const [items, setItems] = useState();
    const [warehouseId, setWarehouseId] = useState();

    const onWarehouseChange = async (val) => {
        setWarehouseId(val);
        const values = await SOPOAPI.estimate(value.soId, val);
        _.forEach(values, (item) => {
            item.soItemQty = _.find(soItems, (soItem) => soItem.id === item.soItemId).quantity;
        })
        let items = _.sortBy(values, "itemName");
        form.setFieldValue("items", items);
    }

    const onLotSelect = (field, lot) => {
        form.setFieldValue(`${field}.locationName`, lot.locationName);
        form.setFieldValue(`${field}.expiredDate`, lot.expiredDate);
    }

    const getFormValues = async () => {
        return await form.validate();
    }

    useEffect(() => {
        const init = async () => {
            const so = await SOAPI.get(value.soId);
            _.forEach(value.items, (item) => {
                item.soItemQty = _.find(so.items, (soItem) => soItem.id === item.soItemId).quantity;
            })

            setSOItems(so.items);
            setItems(_.map(_.sortBy(so.items, "itemName"), (item) => ({
                label: `${item.itemName}(${NumericUtil.currency(item.rate)})`,
                value: item.id
            })));
            setWarehouseId(value.warehouseId);
            form.setFieldsValue(value);
        }
        init();
    }, [value, form])

    useImperativeHandle(ref, () => ({
        getFormValues
    }))

    return (
        <>
            <Form form={form} colon
                labelCol={{ span: 0 }}
                wrapperCol={{ span: 24 }}>
                <FormItem field="id" hidden>
                    <Input />
                </FormItem>
                <FormItem>
                    <Row gutter={8}>
                        <Col offset={18} span={6}>
                            <FormItem label="Warehouse" field="warehouseId" required rules={[{ required: true }]}>
                                <WarehouseSelect onChange={onWarehouseChange} />
                            </FormItem>
                        </Col>
                    </Row>
                    <Row gutter={8}>
                        <Col span={4}>
                            <FormItem field="soId" hidden>
                                <Input />
                            </FormItem>
                            <FormItem label="SO#" field="soNo" labelCol={{ span: 6 }}>
                                <Input readOnly />
                            </FormItem>
                        </Col>
                        <Col span={8}>
                            <FormItem label="Customer Name" field="customerName" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                                <Input readOnly />
                            </FormItem>
                            <FormItem label="Address" field="address" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                                <Input readOnly />
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label="SO Due Date" field="soDueDate" labelCol={{ span: 12 }}>
                                <Input readOnly />
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label="SO Creation Date" field="soCreateDate" labelCol={{ span: 12 }}>
                                <Input readOnly />
                            </FormItem>
                        </Col>
                    </Row>
                </FormItem>
                <FormItem label="Items" labelCol={{ span: 1 }}>
                    <Form.List field="items">
                        {(fields, { add, remove, move }) =>
                            <>
                                <table width="100%">
                                    <thead>
                                        <tr>
                                            <th>Line#</th>
                                            <th width="80">Item Number</th>
                                            <th>Item Name</th>
                                            <th>Description</th>
                                            <th width="120">SO Qty</th>
                                            <th width="120">Pick Qty</th>
                                            <th width="60">UOM</th>
                                            <th width="150">Warehouse Location</th>
                                            <th width="190">Lot#</th>
                                            <th width="95">Expiration</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {fields.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        <span>#{index + 1}</span>
                                                        <FormItem field={item.field + ".id"} hidden>
                                                            <Input />
                                                        </FormItem>
                                                    </td>
                                                    <td>
                                                        <FormItem field={item.field + ".itemId"} hidden>
                                                            <Input />
                                                        </FormItem>
                                                        <FormItem field={item.field + ".itemNo"} noStyle>
                                                            <Input readOnly />
                                                        </FormItem>
                                                    </td>
                                                    <td>
                                                        <FormItem field={item.field + ".soItemId"} noStyle>
                                                            <Select options={items}
                                                                showSearch
                                                                filterOption={(inputValue, option) => option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0}
                                                                triggerProps={{
                                                                    autoAlignPopupWidth: false,
                                                                    autoAlignPopupMinWidth: true
                                                                }}
                                                                onChange={(val) => {
                                                                    const soItem = _.find(soItems, o => o.id === val);
                                                                    form.setFieldValue(`${item.field}.itemId`, soItem.itemId);
                                                                    form.setFieldValue(`${item.field}.itemNo`, soItem.itemNo);
                                                                    form.setFieldValue(`${item.field}.description`, soItem.description);
                                                                    form.setFieldValue(`${item.field}.quantity`, soItem.quantity);
                                                                    form.setFieldValue(`${item.field}.unit`, soItem.unit);
                                                                }} />
                                                        </FormItem>
                                                    </td>
                                                    <td>
                                                        <FormItem field={item.field + ".description"} noStyle>
                                                            <Input readOnly />
                                                        </FormItem>
                                                    </td>
                                                    <td>
                                                        <FormItem field={item.field + ".soItemQty"} noStyle>
                                                            <UIInputNumber readOnly />
                                                        </FormItem>
                                                    </td>
                                                    <td>
                                                        <FormItem field={item.field + ".pickQty"} required rules={[{ required: true }]} noStyle>
                                                            <UIInputNumber />
                                                        </FormItem>
                                                    </td>

                                                    <td>
                                                        <FormItem field={item.field + ".unit"} noStyle>
                                                            <UOMSelect disabled />
                                                        </FormItem>
                                                    </td>
                                                    <td>
                                                        <FormItem field={item.field + ".locationName"} noStyle>
                                                            <Input readOnly />
                                                        </FormItem>
                                                    </td>
                                                    <td>
                                                        <Space>
                                                            <FormItem field={item.field + ".receiptItemId"} noStyle layout="inline">
                                                                <InventoryLotSelect
                                                                    warehouseId={warehouseId}
                                                                    getItemId={() => {
                                                                        return form.getFieldValue(`${item.field}.itemId`);
                                                                    }}
                                                                    getPickQty={() => {
                                                                        return form.getFieldValue(`${item.field}.pickQty`);
                                                                    }}
                                                                    onSelect={(val) => onLotSelect(item.field, val)} />
                                                            </FormItem>
                                                        </Space>
                                                    </td>
                                                    <td>
                                                        <FormItem field={item.field + ".expiredDate"} noStyle>
                                                            <Input readOnly />
                                                        </FormItem>
                                                    </td>
                                                    <td>
                                                        <FormItem noStyle>
                                                            <Button type="text" status="danger" icon={<IconDelete />}
                                                                onClick={() => {
                                                                    remove(index);
                                                                }}></Button>
                                                        </FormItem>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                <FormItem>
                                    <Button type="text" icon={<IconPlus />} onClick={() => add()}>Add Item</Button>
                                </FormItem>
                            </>
                        }
                    </Form.List>
                </FormItem>
            </Form>
        </>
    )
})