import { Button, Card, Space } from "@arco-design/web-react"
import { InvoiceInfo } from "./invoice-info";
import { InvoiceAdd } from "./invoice-add";
import { InvoiceBlank } from "./invoice-blank";
import { UITable, UISelect, UIText } from "@/components/UI";
import { useCallback, useEffect, useState } from "react";
import { InvoiceAPI } from "@/apis";
import { Link } from "react-router-dom";
import SaleOrderLink from "@/components/SaleOrderLink";

export const Invoice = () => {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [status, setStatus] = useState("Open");

    const getList = useCallback(async () => {
        let statusList;
        switch (status) {
            case "Open":
                statusList = [1, 2, 3, 4];
                break;
            case "Paid":
                statusList = [5];
                break;
            case "Current":
                statusList = [1];
                break;
            case "Current Partial":
                statusList = [2];
                break;
            case "Past Due":
                statusList = [3];
                break;
            case "Past Due Partial":
                statusList = [4];
                break;
            case "Voided":
                statusList = [6];
                break;
            default:
                statusList = null;
        }

        const values = await InvoiceAPI.query(page, { status: statusList });
        setData(values.records);
        setTotal(values.total);
    }, [page, status]);

    useEffect(() => {
        getList();
    }, [getList]);

    return (
        <Card title="Invoices" extra={
            <Space>
                <UISelect value={status} options={["Open", "Paid", "Current", "Current Partial", "Past Due", "Past Due Partial", "Voided"]}
                    allowAll placeholder="Status" style={{ width: 150 }} onChange={val => setStatus(val)} />
                <Link to={`/workspace/sales/invoice/blank`}>
                    <Button type="primary">Add New Invoice</Button>
                </Link>
            </Space>
        }>
            <UITable columns={[
                {
                    title: "Invoice#", render: (col, record, index) => (
                        <Link to={"/workspace/sales/invoice/" + record.id}>{record.invoiceNo}</Link>
                    )
                },
                { title: "Status", dataIndex: "statusName" },
                { title: "Invoice Date", dataIndex: "invoiceDate" },
                { title: "Due Date", dataIndex: "dueDate" },
                { title: "Customer", dataIndex: "customerName" },
                { title: "Total Amount", render: (col, record) => <UIText type="currency" value={record.totalAmount} /> },
                { title: "SO#", render: (col, record) => <SaleOrderLink soId={record.soId}>{record.soNo}</SaleOrderLink> },
                { title: "Date Paid", dataIndex: "paidDate" },
            ]} data={data} total={total} onChange={(val) => setPage(val)} />
        </Card>
    )
}

Invoice.Blank = InvoiceBlank;
Invoice.Add = InvoiceAdd;
Invoice.Info = InvoiceInfo;