import { Select, Tag, Tooltip } from "@arco-design/web-react";
import { useCallback, useEffect, useState } from "react";
import { DictAPI } from "@/apis";
import _ from "lodash";

export const UISelect = (props) => {
    const [data, setData] = useState([]);
    const {
        value,
        options,
        records,
        valueKey,
        labelKey,
        allowAll,
        readonly,
        order,
        desc,
        ...restProps
    } = props;
    const [selectedValue, setSelectedValue] = useState(value);

    useEffect(() => {
        let values;
        if (options) {
            values = options;
        } else {
            values = _.map(records, (item) => {
                return {
                    value: item[valueKey ?? "id"],
                    label: item[labelKey ?? valueKey ?? "id"]
                }
            });
        }

        if (order) {
            values = _.orderBy(values, [order], [desc ?? "asc"]);
        }

        if (allowAll) {
            values.unshift({ label: "All", value: null });
        }

        setData(values);

    }, [records, valueKey, labelKey, options, allowAll, order, desc]);

    useEffect(() => {
        setSelectedValue(value);
    }, [value])

    return (
        <Select {...restProps}
            defaultValue={selectedValue}
            value={selectedValue}
            filterOption={(inputValue, option) => {
                return option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
            }}
            triggerProps={{
                autoAlignPopupWidth: false,
                autoAlignPopupMinWidth: true,
                position: 'bl',
            }}
            onChange={val => {
                setSelectedValue(val);
                props.onChange && props.onChange(val);
            }}
            options={data}
        />
    );
}

const UIMultiSelect = (props) => {

    return (
        <UISelect {...props}
            mode="multiple"
            onChange={val => props.onChange && props.onChange(val)}
        />
    );
}

const UIDictSelect = (props) => {
    const [options, setOptions] = useState([]);
    const { type, ...restProps } = props;

    const getOptions = useCallback(async () => {
        let values = await DictAPI.getItems(type);
        setOptions(values);
    }, [type]);


    useEffect(() => {
        getOptions();
    }, [getOptions]);

    return (
        <UISelect {...restProps}
            options={options}
            onChange={val => props.onChange && props.onChange(val)}
        />
    );
}

const UIDictMultiSelect = (props) => {
    const [focus, setFocus] = useState(false);
    const [values, setValues] = useState([]);

    const [options, setOptions] = useState([]);
    const { type, maxTagCount, ...restProps } = props;
    const [tagCount] = useState(maxTagCount ?? 2);

    const getOptions = useCallback(async () => {
        let values = await DictAPI.getItems(type);
        setOptions(values);
    }, [type]);

    useEffect(() => {
        getOptions();
    }, [getOptions]);

    return (
        <UISelect {...restProps}
            mode="multiple"
            maxTagCount={tagCount}
            options={options}
            renderTag={({ label, closable, onClose }, index) => {
                let tip = label;
                if (index >= 1) {
                    tip = _.slice(values, tagCount);
                    tip = _.join(_.map(tip, (item) => _.find(options, (o) => o.value === item).label), ", ");
                }
                return (
                    <Tooltip mini
                        color="var(--color-primary-light-4)"
                        content={tip}>
                        <Tag
                            color={focus ? "gray" : "var(--color-white)"}
                            closable={closable}
                            onClose={onClose}
                            style={{ margin: '2px 6px 2px 0' }}>
                            <span style={{ color: "var(--color-text-2)" }}>{label}</span>
                        </Tag>
                    </Tooltip>
                )
            }}
            onFocus={(e) => {
                setFocus(true);
                props.onFocus && props.onFocus(e);
            }}
            onBlur={(e) => {
                setFocus(false);
                props.onBlur && props.onBlur(e);
            }}
            onChange={val => {
                setValues(val);
                props.onChange && props.onChange(val);
            }}
        />
    );
}

UISelect.MultiSelect = UIMultiSelect;
UISelect.DictSelect = UIDictSelect;
UISelect.DictMultiSelect = UIDictMultiSelect;