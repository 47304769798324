import { Button, Card, Form, Input, Space, Grid, Message } from "@arco-design/web-react"
import { IconDelete, IconPlus } from "@arco-design/web-react/icon";
import { useEffect } from "react";
import { CompanyAPI } from "@/apis";

const FormItem = Form.Item;
const Row = Grid.Row;
const Col = Grid.Col;

export const CompanyInfo = ({ value }) => {
    const [form] = Form.useForm();

    const save = async () => {
        const values = await form.validate();
        await CompanyAPI.update(values);
        Message.success("Company information saved successfully");
    }

    useEffect(() => {
        const values = {
            shipAddresses: [{}],
            ...value
        }
        form.setFieldsValue(values);
    }, [form, value]);

    return (
        <Card title="Company Info" extra={
            <Space>
                <Button type="primary" onClick={() => save()}>Save</Button>
            </Space>
        }>
            <Form form={form} colon>
                <Row>
                    <Col span={8}>
                        <FormItem label="Bill to" labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}
                            field="billContactName">
                            <Input />
                        </FormItem>
                        <FormItem label="Address" labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}
                            field="billAddress">
                            <Input />
                        </FormItem>
                        <FormItem label="EIN" labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}
                            field="ein" rules={[{
                                validator(value, cb) {
                                    if (!/[A-Za-z0-9]{2}-[A-Za-z0-9]{7}/.test(value)) {
                                        return cb("Invalid format, please enter 9 digit EIN");
                                    }
                                    return cb();
                                }
                            }]}>
                            <Input placeholder="XX-XXXXXXX"
                                normalize={v => v && v.length >= 3 && v.indexOf("-") < 0 ? (v.substring(0, 2) + "-" + v.substring(2, 9)) : v}
                            />
                        </FormItem>
                    </Col>
                    <Col span={16}>
                        <FormItem label="Ship to" className="form-item-row" labelCol={{ span: 3 }} wrapperCol={{ span: 21 }}>
                            <Form.List field="shipAddresses">
                                {(fields, { add, remove, move }) => {
                                    return <>
                                        {fields.map((item, index) => {
                                            return (
                                                <Space key={item.key} style={{ width: "100%" }}>
                                                    <FormItem field={item.field + ".id"}>
                                                        <Input hidden />
                                                    </FormItem>
                                                    <FormItem field={item.field + ".contactName"}>
                                                        <Input placeholder="Name" />
                                                    </FormItem>
                                                    <FormItem field={item.field + ".address"} style={{ width: 300 }}>
                                                        <Input placeholder="Street Address" />
                                                    </FormItem>
                                                    <FormItem field={item.field + ".city"} >
                                                        <Input placeholder="City" />
                                                    </FormItem>
                                                    <FormItem field={item.field + ".state"} style={{ width: 60 }}>
                                                        <Input placeholder="State" />
                                                    </FormItem>
                                                    <FormItem field={item.field + ".zip"} style={{ width: 80 }}>
                                                        <Input placeholder="Zipcode" />
                                                    </FormItem>
                                                    <FormItem>
                                                        <Button type="text" size="mini" status="danger" icon={<IconDelete />} onClick={() => remove(index)} />
                                                    </FormItem>
                                                </Space>
                                            )
                                        })}
                                        <FormItem>
                                            <Button type="text" icon={<IconPlus />} size="mini" onClick={() => add()}>Add Address</Button>
                                        </FormItem>
                                    </>
                                }}
                            </Form.List>

                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span={6}>
                        <FormItem label="PO Number Prefix" labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}
                            field="poPrefix">
                            <Input />
                        </FormItem>
                    </Col>

                    <Col span={10}>
                        <FormItem label="Item Receipt Number Prefix" labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}
                            field="poReceiptPrefix">
                            <Input />
                        </FormItem>
                    </Col>

                    <Col span={8}>
                        <FormItem label="Bill Prefix" labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}
                            field="billPrefix">
                            <Input />
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span={6}>
                        <FormItem label="SO Number Prefix" labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}
                            field="soPrefix">
                            <Input />
                        </FormItem>
                    </Col>
                    <Col span={10}>
                        <FormItem label="Invoice Number Prefix" labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}
                            field="invoicePrefix">
                            <Input />
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem label="SO Fulfillment Prefix" labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}
                            field="soFulfillmentPrefix">
                            <Input />
                        </FormItem>
                    </Col>
                </Row>
            </Form>
        </Card>

    )
}