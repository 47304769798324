import './login.css';
import { Layout } from '@arco-design/web-react'
import { LoginForm } from './form';
import { Header } from '@/components/Header';

export const Login = () => {
    return (
        <div className="container">
            <Layout>
                <Layout.Header>
                    <Header />
                </Layout.Header>
                <Layout.Content className='login-content-container'>
                    <div className='login-content'>
                        <LoginForm />
                    </div>
                </Layout.Content>
            </Layout>
        </div>
    );
}