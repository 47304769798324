import { Button, Form, Grid, Input, Space } from "@arco-design/web-react";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import { POAPI, CompanyAPI, CustomerAPI } from "@/apis";
import { UISelect, UIInputNumber, UIDatePicker } from "@/components/UI";
import { IconDelete, IconPlus } from "@arco-design/web-react/icon";
import Address from "@/components/Address";
import UOMSelect from "@/components/UOMSelect";
import WarehouseSelect from "@/components/WarehouseSelect";
import _ from "lodash";

const FormItem = Form.Item;
const Row = Grid.Row;
const Col = Grid.Col;
const TextArea = Input.TextArea;
const LocationSelect = WarehouseSelect.LocationSelect;

export const ReceiptForm = forwardRef((props, ref) => {
    const [form] = Form.useForm();
    const { value } = props;
    const [items, setItems] = useState([]);
    const [shipAddresses, setShipAddresses] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [dropShip, setDropShip] = useState(value ? value.dropShip : null);
    const [warehouseId, setWarehouseId] = useState(value ? value.warehouseId : null);

    const getCustomers = useCallback(async () => {
        const values = await CustomerAPI.actives();
        setCustomers(values);
    }, []);

    const getAddresses = useCallback(async (dropShip) => {
        if (dropShip) {
            const customer = await CustomerAPI.get(dropShip);
            setShipAddresses(customer.shipAddresses);
        } else {
            const companyShipToList = await CompanyAPI.addresses();
            setShipAddresses(companyShipToList);
        }
    }, []);

    const calcAmount = (index) => {
        const rate = form.getFieldValue("items[" + index + "].rate");
        const quantity = form.getFieldValue("items[" + index + "].quantity");
        if (rate && quantity) {
            const amount = rate * quantity;
            form.setFieldValue("items[" + index + "].amount", amount);
        }
    }

    const getFormData = async () => {
        const values = await form.validate();
        return values;
    }

    useEffect(() => {

        const getPOInfo = async () => {
            const values = await POAPI.get(value.poId);
            setItems(values.items);
            setDropShip(values.dropShip);
        }

        getPOInfo();
    }, [form, value])

    useEffect(() => {
        getAddresses(dropShip);
    }, [getAddresses, dropShip])

    useEffect(() => {
        getCustomers();
    }, [getCustomers])

    useImperativeHandle(ref, () => ({
        getFormData
    }))

    return (
        <Form form={form} colon
            labelCol={{ span: 0 }}
            wrapperCol={{ span: 24 }}
            initialValues={value}
        >
            <FormItem field="id" hidden>
                <Input />
            </FormItem>
            <FormItem field="poId" hidden>
                <Input />
            </FormItem>
            <FormItem>
                <Row gutter={8}>
                    <Col span={8}>
                        <FormItem label="Vendor" field="vendorName" labelCol={{ span: 9 }}>
                            <Input readOnly />
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem label="Drop Ship" field="dropShip" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                            <UISelect records={customers} labelKey="customerName"
                                onChange={val => {
                                    setDropShip(val);
                                    form.clearFields("shipToContactName");
                                }} />
                        </FormItem>
                        <FormItem label="Ship To" field="shipToContactName" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
                            required rules={[{ required: true }]}>
                            <Address.Select addresses={shipAddresses} onAddrChange={val => {
                                form.setFieldValue("shipToAddress", val.address);
                                form.setFieldValue("shipToCity", val.city);
                                form.setFieldValue("shipToState", val.state);
                                form.setFieldValue("shipToZip", val.zip);
                            }} />
                        </FormItem>
                        <FormItem field="shipToAddress" hidden>
                            <Input />
                        </FormItem>
                        <FormItem field="shipToCity" hidden>
                            <Input />
                        </FormItem>
                        <FormItem field="shipToState" hidden>
                            <Input />
                        </FormItem>
                        <FormItem field="shipToZip" hidden>
                            <Input />
                        </FormItem>
                        <FormItem shouldUpdate noStyle>
                            {(value) => {
                                return (
                                    <FormItem wrapperCol={{ offset: 8 }}>
                                        <Address value={{
                                            name: value.shipToContactName,
                                            address: value.shipToAddress,
                                            city: value.shipToCity,
                                            state: value.shipToState,
                                            zip: value.shipToZip,
                                        }} />
                                    </FormItem>

                                )
                            }}
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem label="From PO" field="poNo" labelCol={{ span: 8 }} >
                            <Input readOnly />
                        </FormItem>
                        <FormItem label="Receive Date" field="receiveDate" labelCol={{ span: 8 }} required rules={[{ required: true }]}>
                            <UIDatePicker />
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <FormItem label="Reference" labelCol={{ span: 3 }}>
                            <Form.List field="reference">
                                {(fields, { add, remove, move }) =>
                                    <>
                                        {fields.map((item, index) => {
                                            return (
                                                <FormItem key={item.key} style={{ marginBottom: 0 }}>
                                                    <Space className="form-item-row">
                                                        <FormItem field={item.field + ".type"}>
                                                            <UISelect.DictSelect type="reference-type" order="label" style={{ width: 200 }} showSearch />
                                                        </FormItem>
                                                        <FormItem field={item.field + ".number"}>
                                                            <Input />
                                                        </FormItem>
                                                        <Button type="text" status="danger" icon={<IconDelete />} onClick={() => remove(index)}></Button>
                                                    </Space>
                                                </FormItem>
                                            )
                                        })}
                                        <Button type="text" icon={<IconPlus />} onClick={() => add()}>Add Reference</Button>
                                    </>
                                }
                            </Form.List>
                        </FormItem>
                        <FormItem label="Warehouse Name" labelCol={{ span: 3 }} wrapperCol={{ span: 4 }} field="warehouseId" required rules={[{ required: true }]}>
                            <WarehouseSelect addLink={true} onChange={(val) => {
                                setWarehouseId(val);
                            }} />
                        </FormItem>
                        <FormItem label="Memo" labelCol={{ span: 3 }} field="memo">
                            <TextArea />
                        </FormItem>
                    </Col>
                </Row>
            </FormItem>
            <FormItem label="Items" labelCol={{ span: 3 }} labelAlign="left">
                <Form.List field="items">
                    {(fields, { add, remove, move }) =>
                        <>
                            <table width="100%">
                                <thead>
                                    <tr>
                                        <th width="120">Item Number</th>
                                        <th>Item Name</th>
                                        <th>Description</th>
                                        <th>U/M</th>
                                        <th>Qty</th>
                                        <th>Rate</th>
                                        <th>Amount</th>
                                        <th>Location</th>
                                        <th>Lot#</th>
                                        <th>Expiration Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {fields.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <FormItem field={item.field + ".id"} hidden>
                                                        <Input />
                                                    </FormItem>
                                                    <FormItem field={item.field + ".itemNo"} noStyle>
                                                        <Input readOnly />
                                                    </FormItem>
                                                </td>
                                                <td>
                                                    <FormItem field={item.field + ".itemId"} required rules={[{ required: true }]} noStyle>
                                                        <UISelect records={items} valueKey="itemId" labelKey="itemName" style={{ minWidth: 120 }}
                                                            onChange={async (val) => {
                                                                const item = _.find(items, item => item.itemId === val);
                                                                form.setFieldValue("items[" + index + "].itemNo", item.itemNo);
                                                                form.setFieldValue("items[" + index + "].itemName", item.itemName);
                                                                form.setFieldValue("items[" + index + "].description", item.description);
                                                                form.setFieldValue("items[" + index + "].unit", item.unit);
                                                                form.setFieldValue("items[" + index + "].rate", item.rate);
                                                            }} />
                                                    </FormItem>
                                                    <FormItem field={item.field + ".itemName"} hidden>
                                                        <Input />
                                                    </FormItem>
                                                </td>
                                                <td>
                                                    <FormItem field={item.field + ".description"} noStyle>
                                                        <Input readOnly />
                                                    </FormItem>
                                                </td>
                                                <td>
                                                    <FormItem field={item.field + ".unit"} noStyle>
                                                        <UOMSelect disabled />
                                                    </FormItem>
                                                </td>
                                                <td>
                                                    <FormItem field={item.field + ".quantity"} noStyle>
                                                        <UIInputNumber onBlur={() => calcAmount(index)} />
                                                    </FormItem>
                                                </td>
                                                <td>
                                                    <FormItem field={item.field + ".rate"} noStyle>
                                                        <UIInputNumber onBlur={() => calcAmount(index)} />
                                                    </FormItem>
                                                </td>
                                                <td>
                                                    <FormItem field={item.field + ".amount"} noStyle>
                                                        <UIInputNumber />
                                                    </FormItem>
                                                </td>
                                                <td>
                                                    <FormItem field={item.field + ".locationId"} noStyle>
                                                        <LocationSelect onlyActive={false} warehouseId={warehouseId} style={{ width: 110 }} />
                                                    </FormItem>
                                                </td>
                                                <td>
                                                    <FormItem field={item.field + ".lot"} noStyle>
                                                        <Input />
                                                    </FormItem>
                                                </td>
                                                <td>
                                                    <FormItem field={item.field + ".expiredDate"} noStyle>
                                                        <UIDatePicker />
                                                    </FormItem>
                                                </td>
                                                <td>
                                                    <FormItem noStyle>
                                                        <Button type="text" status="danger" icon={<IconDelete />}
                                                            onClick={() => remove(index)}></Button>
                                                    </FormItem>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            <FormItem>
                                <Button type="text" icon={<IconPlus />} onClick={() => add()}>Add Item</Button>
                            </FormItem>
                        </>
                    }
                </Form.List>
            </FormItem>
        </Form>
    )
})