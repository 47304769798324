import { Button, Card, Message, Modal, Space } from "@arco-design/web-react";
import { UITable, UISelect, UIText } from "@/components/UI";
import { useCallback, useEffect, useRef, useState } from "react";
import { POAPI, CompanyAPI } from "@/apis";
import { POForm } from "./po-form";
import { POInfo } from "./po-info";
import { DateUtil } from "@/utils";
import { Link, useNavigate } from "react-router-dom";
import _ from "lodash";

export const PO = () => {
    const [status, setStatus] = useState(-1);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);

    const getPOs = useCallback(async () => {
        let statusList = [status];
        if (status === -1) {
            statusList = [1, 2, 3];
        }

        const values = await POAPI.query(page, { status: statusList });
        setData(values.records);
        setTotal(values.total);

    }, [status, page]);

    useEffect(() => {
        getPOs();
    }, [getPOs]);

    return (
        <Card title="Purchasing Orders" extra={
            <Space>
                <UISelect options={[
                    { label: "Open", value: -1 },
                    { label: "Created", value: 1 },
                    { label: "Issued", value: 2 },
                    { label: "Partial", value: 3 },
                    { label: "Short Closed", value: 4 },
                    { label: "Fulfilled", value: 5 },
                    { label: "Voided", value: 6 },
                ]} allowAll
                    value={status}
                    placeholder="Status" style={{ width: 200 }}
                    onChange={(val) => {
                        setStatus(val);
                    }} />
                <AddPurchaseOrderButton />
            </Space>
        }>
            <UITable columns={[
                {
                    title: "PO#",
                    render: (col, record, index) =>
                        <Link to={"/workspace/purchasing/po/" + record.id}>{record.poNo}</Link>
                },
                { title: "Status", dataIndex: "statusName" },
                { title: "Vendor", dataIndex: "vendorName" },
                { title: "PO Date", dataIndex: "poDate" },
                { title: "Due Date", dataIndex: "dueDate" },
                {
                    title: "Total Amount", dataIndex: "totalAmount",
                    render: (col, record) => <UIText type="currency" value={record.totalAmount} />
                },
            ]} total={total} data={data} onChange={pageNum => setPage(pageNum)}
            />

        </Card>
    );
}

const AddPurchaseOrderButton = (props) => {
    const [visible, setVisible] = useState(false);
    const navigate = useNavigate();
    const formRef = useRef(null);
    const [poInfo, setPOInfo] = useState();

    const addPO = async () => {
        var po = {
            poDate: DateUtil.today(),
            items: [{}]
        };
        var addr = null;
        const companyShipToList = await CompanyAPI.addresses();
        if (_.size(companyShipToList) > 0) {
            addr = companyShipToList[0];
        }

        if (addr) {
            po.shipToId = addr.id;
            po.shipToContactName = addr.contactName;
            po.shipToAddress = addr.address;
            po.shipToCity = addr.city;
            po.shipToState = addr.state;
            po.shipToZip = addr.zip;
        }
        setPOInfo(po);
        setVisible(true);
    }

    const submit = async () => {
        try {
            const values = await formRef.current.getFormValues();
            const id = await POAPI.add(values);
            Message.success("Purchase Order created successfully");
            navigate(`/workspace/purchasing/po/${id}`);
        } catch (error) {
            Message.error(error.message);
        }
    }

    return (
        <>
            <Button type="primary" onClick={() => addPO()}>Create New PO</Button>
            <Modal title="Create New PO" visible={visible} style={{ width: 1100 }}
                maskClosable={false}
                onCancel={() => setVisible(false)}
                onOk={() => submit()}>
                <POForm ref={formRef} value={poInfo} />
            </Modal>
        </>
    )
}

PO.Info = POInfo;