import { Layout, Typography } from "@arco-design/web-react"
import { Header } from '@/components/Header';
import { useLogin } from '@/pages/Login/useLogin';
const { Title, Paragraph } = Typography;

export const Products = () => {
    useLogin(true)

    return (
        <div className="container">
            <Layout>
                <Layout.Header>
                    <Header />
                </Layout.Header>
                <Layout.Content style={{ "paddingTop": "65px", marginLeft: 100, marginRight: 100 }}>

                    <Title>Product Features</Title>
                    <Paragraph>
                        Sourcing
                    </Paragraph>
                    <Paragraph>
                        Purchasing
                    </Paragraph>
                    <Paragraph>
                        Manufacturing
                    </Paragraph>
                    <Paragraph>
                        Sales/Fulfilling
                    </Paragraph>
                    <Paragraph>
                        Accounting
                    </Paragraph>
                    <Paragraph>
                        Reporting
                    </Paragraph>
                    <Paragraph>
                        HR
                    </Paragraph>
                </Layout.Content>
            </Layout>
        </div>
    )
}

