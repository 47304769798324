import { ItemAPI } from "@/apis";
import { Select } from "@arco-design/web-react";
import { useCallback, useEffect, useState } from "react";
import _ from "lodash";

export const ItemSelect = ({ value, onChange, ...props }) => {
    const [data, setData] = useState([]);

    const searchItem = useCallback(async () => {
        const result = await ItemAPI.actives();
        setData(_.map(result, (item) => ({
            label: item.itemName,
            value: item.id
        })));
    }, []);

    useEffect(() => {
        searchItem();
    }, [searchItem]);

    return (
        <Select
            {...props}
            onChange={onChange}
            showSearch
            filterOption={(inputValue, option) =>
                option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0}
            options={data}
        >

        </Select>
    );
}