import { Button, Card, DatePicker, Form, Grid, Input, Message, Modal, Space, Table, Tooltip } from "@arco-design/web-react";
import { IconBranch, IconCheckCircle, IconClose, IconDelete, IconEdit, IconFilter, IconInfoCircle, IconSearch } from "@arco-design/web-react/icon";
import { useCallback, useEffect, useRef, useState } from "react";
import { UIText, UISelect, UIInputNumber } from "@/components/UI";
import { TransactionAPI } from "@/apis";
import TransactionCategorySelect from "@/components/TransactionCategorySelect";
import AccountSelect from "@/components/AccountSelect";
import { TransactionForm } from "./transaction-form";
import "./index.css";
import { Link } from "react-router-dom";
import { DateUtil } from "@/utils";

const { Row, Col } = Grid;
const FormItem = Form.Item;

export const Transaction = () => {
    const [accounts, setAccounts] = useState();
    const [queryParams, setQueryParams] = useState({});

    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState();

    const onAccountSelect = (val) => {
        const values = { ...queryParams, accountIds: val }
        setQueryParams(values);
    }

    const getList = useCallback(async (page) => {
        const current = page ?? 1;
        const values = await TransactionAPI.query(current, queryParams);
        setData(values.records);
        setPagination((pagination) => ({ ...pagination, current: current, total: values.total }));
    }, [queryParams]);

    const review = async (id) => {
        await TransactionAPI.review(id);
        getList(pagination.current);
    }

    const unreview = async (id) => {
        await TransactionAPI.unreview(id);
        getList(pagination.current);
    }

    const deleteTransaction = async (id) => {
        await TransactionAPI.delete(id);
        Message.success("Transaction Deleted Successfully");
        getList(pagination.current);
    }

    const updateTransactionCategory = async (transactionId, values) => {
        await TransactionAPI.updateCategory(transactionId, values);
        getList(pagination.current);
    }

    useEffect(() => {
        getList();
    }, [getList]);

    return (
        <>
            <Card title="Transactions" extra={
                <Space>
                    <NewTransactionButton type="1" onSubmit={() => {
                        getList(pagination.current);
                    }}>
                        Add Income
                    </NewTransactionButton>
                    <NewTransactionButton type="2" onSubmit={() => {
                        getList(pagination.current);
                    }}>
                        Add Expense
                    </NewTransactionButton>
                    <Link to="/workspace/accounting/journal-entry/new-entry">
                        <Button type="primary" >Add Journal Entry</Button>
                    </Link>
                </Space>
            }>
                <Form>
                    <Row>
                        <Col span={6}>
                            <AccountSelect placeholder="Select Accounts..." mode="multiple" maxTagCount={2}
                                value={accounts}
                                onChange={(val) => {
                                    setAccounts(val);
                                    onAccountSelect(val);
                                }} />
                        </Col>
                        <Col offset={12} span={6} style={{ textAlign: "right" }}>
                            <Space>
                                <TransactionFilterButton value={queryParams}
                                    onSubmit={(val) => {
                                        const values = { ...queryParams, ...val };
                                        setQueryParams(values);
                                    }} />

                                <TransactionSearchButton value={queryParams}
                                    onSubmit={(val) => {
                                        const values = { ...queryParams, ...val };
                                        setQueryParams(values);
                                    }} />

                                <Button type="text" icon={<IconClose />} onClick={() => {
                                    setAccounts([]);
                                    setQueryParams({});
                                    getList();
                                }}>Clear</Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
                <Row style={{ marginTop: 10 }}>
                    <Col span={24}>
                        <Table columns={[
                            { title: "Date", width: 120, dataIndex: "date" },
                            { title: "Description", dataIndex: "description" },
                            { title: "Account", dataIndex: "accountName" },
                            {
                                title: "Category", render: (col, record) => (
                                    <>
                                        <TransactionCategorySelect style={{ width: 250 }} placeholder="Please Select..."
                                            type={record.type === 1 ? "income" : "expense"}
                                            amount={record.amount}
                                            value={{ category: record.category, categoryValue: record.categoryValue, valueType: record.categoryValueType }}
                                            transactionId={record.id}
                                            onChange={val => updateTransactionCategory(record.id, val)} />

                                        {record.splitTrans &&
                                            <Tooltip content="Split Transaction">
                                                <IconBranch style={{ fontSize: 24, marginLeft: 5, verticalAlign: 'middle' }} />
                                            </Tooltip>
                                        }
                                    </>
                                )
                            },
                            {
                                title: "Amount",
                                width: 130,
                                render: (col, record) =>
                                    <UIText type="currency" value={record.amount} />
                            },
                            {
                                title: "Operations", width: 120, render: (col, record) => (
                                    <Space>
                                        {record.status === 2
                                            ? <Button type="text" status="success" icon={<IconCheckCircle />} onClick={() => unreview(record.id)}></Button>
                                            : <Button type="text" icon={<IconCheckCircle />} onClick={() => review(record.id)}></Button>
                                        }
                                        <EditTransactionButton value={record} onSubmit={() => getList()} />
                                        <Button type="text" status="danger" icon={<IconDelete />} onClick={() => deleteTransaction(record.id)} />
                                    </Space>
                                )
                            },
                        ]}
                            data={data}
                            pagination={pagination}
                            onChange={(pagination) => {
                                getList(pagination.current);
                            }}
                        />
                    </Col>
                </Row>
            </Card>
        </>
    )
}

const NewTransactionButton = ({ children, type, onSubmit }) => {
    const [visible, setVisible] = useState(false);
    const [transaction, setTransaction] = useState();
    const formRef = useRef();

    const submit = async () => {
        try {
            const values = await formRef.current.getFormValues();
            await TransactionAPI.add(values);
            setVisible(false);
            Message.success("Transaction Created Successfully");
            onSubmit && onSubmit();
        } catch (error) {
            Message.error(error.message);
        }
    }

    return (
        <>
            <Button type="primary" onClick={() => {
                setTransaction({ type: parseInt(type), date: DateUtil.today() });
                setVisible(true);
            }}>{children}</Button>
            <Modal title={children} visible={visible}
                maskClosable={false}
                onCancel={() => setVisible(false)}
                onOk={() => submit()}
            >
                <TransactionForm ref={formRef} value={transaction} />
            </Modal>
        </>
    )
}

const EditTransactionButton = ({ value, onSubmit }) => {
    const [visible, setVisible] = useState(false);
    const [transaction, setTransaction] = useState();
    const formRef = useRef();

    const submit = async () => {
        try {
            const values = await formRef.current.getFormValues();
            await TransactionAPI.update(value.id, values);
            setVisible(false);
            Message.success("Transaction Updated Successfully");
            onSubmit && onSubmit();
        } catch (error) {
            Message.error(error.message);
        }
    }

    return (
        <>
            <Button type="text" icon={<IconEdit />} onClick={() => {
                setTransaction({ ...value });
                setVisible(true);
            }} />
            <Modal title="Transaction" visible={visible}
                maskClosable={false}
                onCancel={() => {
                    setVisible(false);
                }}
                onOk={() => {
                    if (value.description === "Transaction created automatically") {
                        Modal.confirm({
                            title: "Confirm",
                            content: "Warning: This is a system calculated amount. Please make sure to match the bank account balance before you make this change.",
                            okText: "Update",
                            cancelText: "Cancel",
                            onOk: () => {
                                submit();
                            }
                        })
                    } else {
                        submit();
                    }
                }}
            >
                <TransactionForm ref={formRef} value={transaction} />
            </Modal>
        </>
    )
}

const TransactionFilterButton = (props) => {
    const { value } = props;
    const [type, setType] = useState();
    const [form] = Form.useForm();
    const [visible, setVisible] = useState(false);

    const submit = async () => {
        const values = await form.validate();
        setVisible(false);
        props.onSubmit && props.onSubmit(values)
    }

    useEffect(() => {
        form.setFieldsValue(value);
    }, [form, value])

    return (
        <>
            <Button type="text" icon={<IconFilter />} onClick={() => setVisible(true)}>Filter</Button>
            <Modal title="Filters" visible={visible}
                onCancel={() => setVisible(false)}
                footer={
                    <>
                        <Space>
                            <Button onClick={() => setVisible(false)}>Cancel</Button>
                            <Button onClick={() => form.clearFields()}>Clear Criteria</Button>
                            <Button type="primary" onClick={submit}>OK</Button>
                        </Space>
                    </>
                }>
                <Form form={form} colon>
                    <FormItem field="status" label="Status">
                        <UISelect.DictSelect type="transaction-status" allowClear />
                    </FormItem>
                    <FormItem field="type" label="Type">
                        <UISelect.DictSelect type="transaction-type" allowClear
                            onChange={(val) => setType(val)} />
                    </FormItem>
                    <FormItem field="category" label="Category">
                        <TransactionCategorySelect style={{ width: 250 }} allowClear
                            type={type === 1 ? "income" : "expense"}
                        />
                    </FormItem>
                    <FormItem field="dateRange" label="Date Range">
                        <DatePicker.RangePicker prefix={<IconInfoCircle />}
                            shortcutsPlacementLeft
                            format="M/D/YYYY"
                        />
                    </FormItem>
                </Form>
            </Modal>
        </>
    )
}

const TransactionSearchButton = (props) => {
    const { value } = props;
    const [form] = Form.useForm();
    const [visible, setVisible] = useState(false);

    const submit = async () => {
        const values = await form.validate();
        props.onSubmit && props.onSubmit(values)
    }

    useEffect(() => {
        form.setFieldsValue(value);
    }, [form, value])

    return (
        <>
            <Button type="text" icon={<IconSearch />} onClick={() => setVisible(true)}>Search</Button>
            <Modal title="Search" visible={visible}
                onCancel={() => setVisible(false)}
                footer={
                    <>
                        <Space>
                            <Button onClick={() => setVisible(false)}>Cancel</Button>
                            <Button onClick={() => form.clearFields()}>Clear Criteria</Button>
                            <Button type="primary" onClick={submit}>OK</Button>
                        </Space>
                    </>
                }>
                <Form form={form} colon>
                    <FormItem field="amount" label="Amount">
                        <UIInputNumber allowClear />
                    </FormItem>
                    <FormItem field="description" label="Description">
                        <Input allowClear />
                    </FormItem>
                    <FormItem field="note" label="Note">
                        <Input allowClear />
                    </FormItem>
                </Form>
            </Modal>
        </>
    )
}

/*
const ReceiptButton = ({ transactionId }) => {
    const [form] = Form.useForm();
    const [visible, setVisible] = useState(false);

    const getTransaction = useCallback(async () => {
        const values = await TransactionAPI.get(transactionId);
        form.setFieldValue("fileList", _.map(values.receipts, o => {
            return {
                uid: o.id,
                name: o.documentName,
                url: o.url
            }
        }));
    }, [transactionId, form]);

    const deleteReceipt = async (file) => {
        let receiptId = file.uid;
        if (file.response) {
            receiptId = file.response.data;
        }
        await TransactionAPI.deleteReceipt(transactionId, receiptId);
        Message.success("Receipt Deleted Successfully");
        getTransaction();
    }

    useEffect(() => {
        getTransaction();
    }, [getTransaction])

    return (
        <>
            <Modal title="Transaction Receipt" visible={visible}
                onCancel={() => setVisible(false)}
                footer={<Button onClick={() => setVisible(false)}>Close</Button>}>
                <Form form={form} layout="vertical">
                    <Form.Item field="fileList" triggerPropName="fileList">
                        <Upload
                            withCredentials={true}
                            drag
                            multiple
                            name="file"
                            action={`${baseUrl}/transaction/${transactionId}/receipt`}
                            showUploadList={{
                                fileName: (file) => {
                                    return (
                                        <Space>
                                            {file.name}
                                            <a href={file.url} download={file.name}><IconDownload /></a>
                                        </Space>
                                    );
                                },
                            }}
                            onRemove={(file) => deleteReceipt(file)}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}
*/