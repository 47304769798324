import { DateUtil } from "./date";

export const TermUtil = {
    dueDate: (date, term) => {
        if (!date) return null;
        if (!term) return date;
        let days = 0;
        switch (term) {
            case 1:
                break;
            case 2:
                days = 15
                break;
            case 3:
                days = 30
                break;
            default:
                break;
        }
        return DateUtil.date(date).add(days, 'days').valueOf();
    }
}