import { TransactionAPI, DictAPI, InvoiceAPI, AccountAPI, BillAPI } from "@/apis"
import { Cascader } from "@arco-design/web-react"
import _ from "lodash";
import { useCallback, useEffect, useState } from "react"
import { NumericUtil } from "@/utils";

const TransactionCategorySelect = (props) => {
    const { value, placeholder, type, amount, transactionId, ...restProps } = props;
    const [options, setOptions] = useState([]);
    const [current, setCurrent] = useState([]);

    const getAccounts = useCallback(async () => {
        return await AccountAPI.actives({});
    }, []);

    const getInvoices = useCallback(async () => {
        return await InvoiceAPI.actives();
    }, []);

    const getBills = useCallback(async () => {
        return await BillAPI.actives();
    }, []);

    const getReletedTrans = useCallback(async () => {
        if (transactionId) {
            return await TransactionAPI.relatedTransactions(transactionId);
        }

        return [];
    }, [transactionId])

    const onValueChange = (val) => {
        if (val.length <= 1) return;
        let categoryValue = _.split(val[1], '-');
        props.onChange && props.onChange({
            category: val[0],
            categoryValue: parseInt(categoryValue[0]),
            categoryValueType: parseInt(categoryValue[1])
        });
    }

    const getOptions = useCallback(async (type) => {

        let categories = await DictAPI.getItems(`transaction-category`);
        const accounts = await getAccounts();

        const invoices = await getInvoices();
        const bills = await getBills();
        const sortedInvoices = _.orderBy(invoices, ['id'], ['desc']);
        const sortedBills = _.orderBy(bills, ['id'], ['desc']);
        const releatedTransactions = await getReletedTrans();
        const bankCCLoanAccounts = _.filter(accounts, o => {
            return _.includes([5, 10], o.accountType);
        });
        const expenseAccounts = _.filter(accounts, { category: 2 });
        const incomeAccounts = _.filter(accounts, { category: 1 });
        const equityAccounts = _.filter(accounts, (account) => account.category === 5 && account.accountName !== "Retained Income");

        if (type === "income") {
            categories = _.filter(categories, (c) => {
                return c.value < 6;
            });
            _.find(categories, { value: 1 }).children = _.map(sortedInvoices, o => {
                return {
                    value: o.id,
                    label: `${o.invoiceNo}(${NumericUtil.currency(o.totalAmount)})`
                }
            });

            _.find(categories, { value: 2 }).children = _.concat(_.map(releatedTransactions, o => {
                return {
                    value: `${o.id}-1`,
                    label: `${o.date} ${o.description}`
                }
            }), _.map(_.concat(bankCCLoanAccounts, equityAccounts), o => {
                return {
                    value: o.id,
                    label: o.accountName
                }
            }));

            _.find(categories, { value: 3 }).children = _.map(sortedBills, o => {
                return {
                    value: o.id,
                    label: `${o.billNo}(${NumericUtil.currency(o.totalAmount)})`
                }
            });

            _.find(categories, { value: 4 }).children = _.map(expenseAccounts, o => {
                return {
                    value: o.id,
                    label: o.accountName
                }
            });

            _.find(categories, { value: 5 }).children = _.map(incomeAccounts, o => {
                return {
                    value: o.id,
                    label: o.accountName
                }
            });

        } else {
            categories = _.filter(categories, (c) => {
                return c.value >= 6;
            });
            _.find(categories, { value: 6 }).children = _.map(sortedBills, o => {
                return {
                    value: o.id,
                    label: `${o.billNo}(${NumericUtil.currency(o.totalAmount)})`
                }
            });

            _.find(categories, { value: 7 }).children = _.concat(_.map(releatedTransactions, o => {
                return {
                    value: `${o.id}-1`,
                    label: `${o.date} ${o.description}`
                }
            }), _.map(bankCCLoanAccounts, o => {
                return {
                    value: o.id,
                    label: o.accountName
                }
            }));

            _.find(categories, { value: 8 }).children = _.map(equityAccounts, o => {
                return {
                    value: o.id,
                    label: o.accountName
                }
            });

            _.find(categories, { value: 9 }).children = _.map(sortedInvoices, o => {
                return {
                    value: o.id,
                    label: `${o.invoiceNo}(${NumericUtil.currency(o.totalAmount)})`
                }
            });

            _.find(categories, { value: 10 }).children = _.map(incomeAccounts, o => {
                return {
                    value: o.id,
                    label: o.accountName
                }
            });

            _.find(categories, { value: 11 }).children = _.map(expenseAccounts, o => {
                return {
                    value: o.id,
                    label: o.accountName
                }
            });
        }

        setOptions(categories);
    }, [getAccounts, getInvoices, getBills, getReletedTrans]);

    useEffect(() => {
        getOptions(type);
    }, [getOptions, type])

    useEffect(() => {
        const c = [value?.category, _.join(_.compact([value?.categoryValue, value?.valueType]), '-')];
        setCurrent(c);
    }, [value])

    return (
        <Cascader {...restProps}
            value={current}
            options={options}
            filterOption={(input, node) => {
                let value = _.toLower(input);
                return _.toLower(node.value.toString()).indexOf(value) > -1 || _.toLower(node.label).indexOf(value) > -1;
            }}
            showSearch
            onChange={(val) => onValueChange(val)}
            renderFormat={(valueShow) => `${valueShow.length <= 0 ? placeholder : valueShow[valueShow.length - 1]}`} />
    )
}

export default TransactionCategorySelect;