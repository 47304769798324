import { Form, Grid, Input } from "@arco-design/web-react";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import { UIInputNumber, UISelect } from "@/components/UI";
import AccountSelect from "@/components/AccountSelect";
import UOMSelect from "@/components/UOMSelect";
import VendorSelect from "@/components/VendorSelect";
import Status from "@/components/Status";
import { ItemAPI } from "@/apis";
import _ from "lodash";

const FormItem = Form.Item;
const TextArea = Input.TextArea;

export const ItemForm = forwardRef(({ value }, ref) => {
    const [form] = Form.useForm();
    const [uomName, setUOMName] = useState();
    const [itemType, setItemType] = useState();
    const [cogsAccountLabel, setCogsAccountLabel] = useState();

    const setDefault = useCallback(async () => {
        form.resetFields();
        let values = { ...value, defaultMarginPercent: value.defaultMargin * 100 };
        onUOMChange(values.unit);
        onItemTypeChange(values.itemType);
        form.setFieldsValue(values);
    }, [form, value])

    const onUOMChange = async (val) => {
        if (val) {
            const values = await ItemAPI.uoms();
            setUOMName(_.find(values, item => item.id === val).name);
        } else {
            setUOMName(null);
        }
    };

    const calcMargin = () => {
        const cost = form.getFieldValue("stdCost");
        const price = form.getFieldValue("salePrice");
        if (cost == null || price == null || price === 0) return;

        const margin = (price - cost) / price;
        form.setFieldsValue({ defaultMargin: margin, defaultMarginPercent: margin * 100 });
    }

    const calcSalePrice = () => {
        const cost = form.getFieldValue("stdCost");
        const margin = form.getFieldValue("defaultMargin");
        if (cost == null || margin == null || margin === 1) return;

        const price = cost / (1 - margin);
        form.setFieldsValue({ salePrice: price });
    }

    const onItemTypeChange = (value) => {
        setItemType(value);
        setCogsAccountLabel(value === 2 ? "COGS/Expense Account" : "COGS Account");
    }

    const getValues = async () => {
        return await form.validate();
    }


    useImperativeHandle(ref, () => ({
        getValues
    }));

    useEffect(() => {
        setDefault();
    }, [setDefault])

    return (
        <Form form={form} colon
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 15 }}
        >
            <FormItem field="id" hidden>
                <Input />
            </FormItem>
            {value.id &&
                <FormItem field="status" label="Status" required rules={[{ required: true }]}>
                    <Status.Select />
                </FormItem>
            }
            <FormItem field="itemType" label="Item Type" required rules={[{ required: true }]}>
                <UISelect.DictSelect type="item-type" onChange={onItemTypeChange} />
            </FormItem>
            <FormItem field="itemNo" label="Item Number" required rules={[{ required: true }]}>
                <Input />
            </FormItem>
            <FormItem field="itemName" label="Item Name" required rules={[{ required: true }]}>
                <Input />
            </FormItem>
            <FormItem field="description" label="Description">
                <TextArea />
            </FormItem>
            <FormItem field="stdCost" label="Standard Cost">
                <UIInputNumber precision={5} onChange={val => {
                    const margin = form.getFieldValue("defaultMargin");
                    const price = form.getFieldValue("salePrice");
                    if (margin != null) {
                        calcSalePrice();
                    } else if (price != null) {
                        calcMargin();
                    }
                }} />
            </FormItem>
            <FormItem field="unit" label="Unit of Measure(UOM)" required rules={[{ required: true }]}>
                <UOMSelect onChange={onUOMChange} />
            </FormItem>
            <FormItem field="defaultMarginPercent" label="Default Margin">
                <UIInputNumber precision={3} onChange={val => {
                    form.setFieldValue("defaultMargin", val / 100)
                    calcSalePrice();
                }} suffix="%" />
            </FormItem>
            <FormItem field="defaultMargin" hidden>
                <Input />
            </FormItem>
            <FormItem field="salePrice" label="Sales Price">
                <UIInputNumber precision={5} onChange={val => calcMargin()} />
            </FormItem>
            <FormItem field="cogsAccountId" label={cogsAccountLabel} required rules={[{ required: true }]}>
                {itemType === 2 ?
                    <AccountSelect category={2} />
                    :
                    <AccountSelect type="3" />
                }
            </FormItem>
            <FormItem field="incomeAccountId" label="Income Account" required rules={[{ required: true }]}>
                <AccountSelect type="1" />
            </FormItem>
            {itemType === 1 &&
                <FormItem field="inventoryAccountId" label="Inventory Account" required rules={[{ required: true }]}>
                    <AccountSelect type="7" />
                </FormItem>
            }
            <FormItem label="Safety Stock Level(UOM)">
                {() => {
                    if (uomName) {
                        return (
                            <FormItem field="safetyStockLevelUom">
                                <UIInputNumber suffix={uomName} />
                            </FormItem>
                        )
                    }
                    return (
                        <FormItem field="safetyStockLevel">
                            <UIInputNumber suffix="days" />
                        </FormItem>
                    )
                }}
            </FormItem>
            <FormItem label="Preferred Vendor" style={{ marginBottom: 0 }}>
                <Grid.Row gutter={16}>
                    <Grid.Col span={12}>
                        <FormItem field="preferredVendorId">
                            <VendorSelect />
                        </FormItem>
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <FormItem field="preferredVendorLeadTime" labelAlign="left" label="Lead Time(days)">
                            <Input />
                        </FormItem>
                    </Grid.Col>
                </Grid.Row>
            </FormItem>
        </Form>
    )
})