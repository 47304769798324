import { Layout, Typography } from "@arco-design/web-react"
import { Header } from '@/components/Header';
import { useLogin } from '@/pages/Login/useLogin';
const { Title, Paragraph } = Typography;

export const Resource = () => {
    useLogin(true)

    return (
        <div className="container">
            <Layout>
                <Layout.Header>
                    <Header />
                </Layout.Header>
                <Layout.Content style={{ "paddingTop": "65px", marginLeft: 100, marginRight: 100 }}>
                    <Title>Resources</Title>
                    <Paragraph>
                        Is there an implementation fee for Falconsys?<br />
                        A: No, there is no implementation fee for our system.
                    </Paragraph>
                    <Paragraph>
                        Is FalconSys a robust ERP system?<br />
                        A: Yes, the system is created by supply chain experts that have decades of industry experiences and expertise. What’s more important? We are constantly listening to our customers and adding new features.
                    </Paragraph>
                    <Paragraph>
                        If I want to migrate from another system to FalconSys, can I get help from your team?<br />
                        A: Yes, we have team members that can guide you through the migration process.
                    </Paragraph>
                    <Paragraph>
                        Data/Accounting Migration Steps:
                        <ol>
                            <li>Chart of Accounts</li>
                            <li>Customers</li>
                            <li>Vendors</li>
                            <li>Items</li>
                            <li>Inventory – Inventory/Migration Offset Account</li>
                            <li>POs</li>
                            <li>SOs</li>
                            <li>Open Bills – AP/Migration Offset Account</li>
                            <li>Payroll Liability – Payroll Expense/Payroll Liability</li>
                            <li>Open Invoices – Revenue/AR</li>
                            <li>Link Bank Account – BankAccount/Migration Offset Account</li>
                            <li>Link CC – Expense/CC liability</li>
                        </ol>
                    </Paragraph>
                </Layout.Content>
            </Layout>
        </div>
    )
}

