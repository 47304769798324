import { Button, Form, Grid, Input, Space, Tabs } from "@arco-design/web-react"
import { ItemAPI, CustomerAPI, SOAPI, InvoiceAPI } from "@/apis";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import { UISelect, UIDatePicker, UIInputNumber } from "@/components/UI";
import UOMSelect from "@/components/UOMSelect";
import CustomerSelect from "@/components/CustomerSelect";
import AccountSelect from "@/components/AccountSelect";
import { IconDelete, IconPlus } from "@arco-design/web-react/icon";
import { DateUtil, NumericUtil, TermUtil } from "@/utils";
import _ from "lodash";

const Row = Grid.Row;
const Col = Grid.Col;
const FormItem = Form.Item;
const TextArea = Input.TextArea;
const TabPane = Tabs.TabPane;

export const InvoiceForm = forwardRef((props, ref) => {
    const { value } = props;
    const [form] = Form.useForm();
    const [soList, setSOList] = useState();
    const [items, setItems] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [totalRevenue, setTotalRevenue] = useState(0);

    const calcDueDate = useCallback(() => {
        const date = form.getFieldValue("invoiceDate");
        const term = form.getFieldValue("term");
        form.setFieldValue("dueDate", DateUtil.format(TermUtil.dueDate(date, term)));
    }, [form]);

    const getItems = useCallback(async () => {
        const nonInventoryItems = await ItemAPI.getNonInventoryItems();
        let fulfilledItems = [];
        if (value) {
            fulfilledItems = _.map(value.items, (item) => {
                return {
                    id: item.itemId,
                    itemName: item.itemName,
                    unit: item.unit
                }
            })
        }
        let items = _.uniqBy(_.concat(fulfilledItems, nonInventoryItems), 'id');
        items = _.sortBy(items, "itemName");
        setItems(items);
    }, [value])

    const onCustomerSelect = useCallback(async (val) => {
        const customer = await CustomerAPI.get(val);
        form.setFieldValue("address", `${customer.billingAddress}, ${customer.billingCity}, ${customer.billingState} ${customer.billingZip}`)
        form.setFieldValue("term", customer.term);
        calcDueDate();
    }, [form, calcDueDate])

    const onItemSelect = async (field, val) => {
        const customerId = form.getFieldValue("customerId");
        const soItem = await InvoiceAPI.getCustomerLastInvoiceItem(customerId, val);
        const item = await ItemAPI.get(val);
        form.setFieldValue(`${field}.description`, item.description);
        form.setFieldValue(`${field}.unit`, item.unit);
        form.setFieldValue(`${field}.cost`, soItem ? soItem.cost : item.salePrice);
    }

    const onItemChange = (field) => {
        const quantity = form.getFieldValue(`${field}.quantity`);
        const cost = form.getFieldValue(`${field}.cost`);
        const amount = cost * quantity;
        form.setFieldValue(`${field}.amount`, amount);
        calcTotal();
    }

    const onRevenueAmountChange = (val) => {
        calcTotal();
    }

    const calcTotal = useCallback(() => {
        const items = form.getFieldValue("items");
        const revenues = form.getFieldValue("revenues");
        const totalItems = _.sumBy(items, "amount") ?? 0;
        const totalRevenues = _.sumBy(revenues, "amount") ?? 0;
        setTotalItems(totalItems);
        setTotalRevenue(totalRevenues);
        form.setFieldValue("totalAmount", totalItems + totalRevenues)
    }, [form]);

    const getFormData = async () => {
        const values = await form.validate();
        return values;
    }

    const getCurrentSOs = useCallback(async (soNo) => {
        const values = await SOAPI.query(1, {
            soNo: soNo,
            status: [1, 2, 3, 4, 5, 6, 7, 8, 9, 11, 12]
        });
        setSOList(values.records);
    }, [])

    useEffect(() => {
        getCurrentSOs();
    }, [getCurrentSOs])

    useEffect(() => {
        getItems();
        if (value) {
            form.setFieldsValue(value);
            calcTotal();

            if (value.customerId) {
                onCustomerSelect(value.customerId);
            }
        }
    }, [calcTotal, calcDueDate, getItems, form, value, onCustomerSelect]);

    useImperativeHandle(ref, () => {
        return {
            getFormData
        }
    })

    return (
        <Form form={form} colon labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
            <FormItem field="id" hidden>
                <Input />
            </FormItem>

            <Row gutter={12}>
                <Col span={12}>
                    <FormItem field="customerId" label="Customer">
                        <CustomerSelect onChange={(val) => onCustomerSelect(val)} />
                    </FormItem>
                </Col>
                <Col span={6}>
                    <FormItem label="Invoice Date" field="invoiceDate"
                        labelCol={{ span: 10 }}
                        wrapperCol={{ span: 14 }}
                        required rules={[{ required: true }]}>
                        <UIDatePicker onChange={() => calcDueDate()} />
                    </FormItem>
                </Col>
                <Col span={6}>
                    <FormItem field="soId" label="SO#"
                        labelCol={{ span: 10 }}
                        wrapperCol={{ span: 14 }}
                    >
                        <UISelect records={soList} labelKey="soNo" />
                    </FormItem>
                </Col>
            </Row>
            <Row gutter={12}>
                <Col span={12}>
                    <FormItem label="Address" field="address">
                        <Input readOnly />
                    </FormItem>
                </Col>
                <Col span={6}>
                    <FormItem label="Terms" field="term"
                        labelCol={{ span: 10 }} wrapperCol={{ span: 14 }}
                        required rules={[{ required: true }]}>
                        <UISelect.DictSelect type="term" onChange={(val) => {
                            calcDueDate();
                        }} />
                    </FormItem>
                </Col>
                <Col span={6}>
                    <FormItem label="Due Date" field="dueDate"
                        labelCol={{ span: 10 }} wrapperCol={{ span: 14 }}
                        required rules={[{ required: true }]}>
                        <UIDatePicker />
                    </FormItem>
                </Col>
            </Row>
            <Row gutter={12}>
                <Col span={18}>
                    <FormItem label="Reference#" field="reference"
                        labelCol={{ span: 4 }}>
                        <Form.List field="reference">
                            {(fields, { add, remove, move }) =>
                                <>
                                    {fields.map((item, index) => {
                                        return (
                                            <FormItem key={item.key} style={{ marginBottom: 0 }}>
                                                <Space className="form-item-row">
                                                    <FormItem field={item.field + ".type"}>
                                                        <UISelect.DictSelect type="reference-type" order="label" style={{ width: 200 }} />
                                                    </FormItem>
                                                    <FormItem field={item.field + ".number"}>
                                                        <Input />
                                                    </FormItem>
                                                    <Button type="text" status="danger" icon={<IconDelete />} onClick={() => remove(index)}></Button>
                                                </Space>
                                            </FormItem>
                                        )
                                    })}
                                    <Button type="text" size="mini" icon={<IconPlus />} onClick={() => add()}>Add Reference</Button>
                                </>
                            }
                        </Form.List>
                    </FormItem>
                </Col>
                <Col span={6}>
                    <FormItem field="totalAmount" label="Total Amount"
                        labelCol={{ span: 10 }} wrapperCol={{ span: 14 }} >
                        <UIInputNumber readOnly />
                    </FormItem>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <FormItem label="Memo" field="memo"
                        labelCol={{ span: 3 }} wrapperCol={{ span: 21 }}>
                        <TextArea />
                    </FormItem>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Tabs defaultActiveTab="1" type="card-gutter" lazyload={false}>
                        <TabPane key="1" title={
                            <Space size={80}>
                                <span>Items:</span>
                                <span>{NumericUtil.currency(totalItems)}</span>
                            </Space>
                        }>

                            <Form.List field="items">
                                {(fields, { add, remove, move }) =>
                                    <>
                                        <table style={{ width: "100%" }}>
                                            <thead>
                                                <tr>
                                                    <th width="250">Item</th>
                                                    <th>Description</th>
                                                    <th width="120">Qty</th>
                                                    <th width="30">U/M</th>
                                                    <th width="120">Price</th>
                                                    <th width="120">Amount</th>
                                                    <th width="50"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {fields.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            <FormItem field={`${item.field}.id`} hidden>
                                                                <Input />
                                                            </FormItem>
                                                            <FormItem field={`${item.field}.itemId`} noStyle>
                                                                <UISelect showSearch records={items} labelKey="itemName" onChange={(val) => onItemSelect(item.field, val)} />
                                                            </FormItem>
                                                        </td>
                                                        <td>
                                                            <FormItem field={`${item.field}.description`} noStyle>
                                                                <Input />
                                                            </FormItem>
                                                        </td>
                                                        <td>
                                                            <FormItem field={`${item.field}.quantity`} noStyle>
                                                                <UIInputNumber onChange={(val) => onItemChange(item.field, val)} />
                                                            </FormItem>
                                                        </td>
                                                        <td>
                                                            <FormItem field={`${item.field}.unit`} noStyle>
                                                                <UOMSelect disabled />
                                                            </FormItem>
                                                        </td>
                                                        <td>
                                                            <FormItem field={`${item.field}.cost`} noStyle>
                                                                <UIInputNumber onChange={(val) => onItemChange(item.field, val)} />
                                                            </FormItem>
                                                        </td>
                                                        <td>
                                                            <FormItem field={`${item.field}.amount`} noStyle>
                                                                <UIInputNumber readOnly />
                                                            </FormItem>
                                                        </td>
                                                        <td>
                                                            <Button type="text" icon={<IconDelete />}
                                                                status="danger"
                                                                onClick={() => remove(index)} />
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <Button type="text" size="mini" icon={<IconPlus />} style={{ margin: "10px" }}
                                            onClick={() => add()}>Add</Button>
                                    </>
                                }
                            </Form.List>
                        </TabPane>
                        <TabPane key="2" title={
                            <Space size={80}>
                                <span>Revenue:</span>
                                <span>{NumericUtil.currency(totalRevenue)}</span>
                            </Space>
                        }>
                            <Form.List field="revenues">
                                {(fields, { add, remove, move }) =>
                                    <>
                                        <table style={{ width: "100%" }}>
                                            <thead>
                                                <tr>
                                                    <th width="250">Account</th>
                                                    <th width="200">Amount</th>
                                                    <th>Memo</th>
                                                    <th width="50"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {fields.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            <FormItem field={`${item.field}.id`} hidden>
                                                                <Input />
                                                            </FormItem>
                                                            <FormItem field={`${item.field}.accountId`} rules={[{ required: true }]} noStyle>
                                                                <AccountSelect type="1" />
                                                            </FormItem>
                                                        </td>
                                                        <td>
                                                            <FormItem field={`${item.field}.amount`} rules={[{ required: true }]} noStyle>
                                                                <UIInputNumber onChange={(val) => onRevenueAmountChange(val)} />
                                                            </FormItem>
                                                        </td>
                                                        <td>
                                                            <FormItem field={`${item.field}.memo`} noStyle>
                                                                <Input />
                                                            </FormItem>
                                                        </td>
                                                        <td>
                                                            <Button type="text" icon={<IconDelete />}
                                                                status="danger"
                                                                onClick={() => remove(index)} />
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <Button type="text" size="mini" icon={<IconPlus />} style={{ margin: "10px" }}
                                            onClick={() => add()}>Add</Button>
                                    </>
                                }
                            </Form.List>
                        </TabPane>

                    </Tabs>
                </Col>
            </Row>
        </Form>
    )
})