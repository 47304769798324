import { useRoutes } from "react-router-dom";
import { AboutUs } from "@/pages/About";
import { ContactUs } from "@/pages/Contact";
import { Pricing } from "@/pages/Pricing";
import { Introduction } from "@/pages/Introduction";
import { Products } from "@/pages/Products";
import { Resource } from "@/pages/Resource";
import { Register } from "@/pages/Register";
import { ChangePassword } from "@/pages/User/changePassword";
import { Login } from "@/pages/Login";
import { ForgotPassword } from "@/pages/Login/forgotPassword";
import { ResetPassword } from "../pages/Login/resetPassword";
import { ERR404 } from "@/pages/Error";
import { Admin } from "@/pages/Admin";
import { Company } from "@/pages/Admin/Company";
import { Settings } from "@/pages/Admin/Settings";
import { BankAudit } from "@/pages/Admin/BankAudit";
import { Home as UserHome } from "@/pages/Home";
import { CompanyProfile } from "@/pages/Home/CompanyProfile";
import { Items, Inventory, Recall } from "@/pages/Home/Material";
import { Vendor, PO, ItemReceipt, Bill } from "@/pages/Home/Purchasing";
import { Customer, SaleOrder, SOPickOrder, SOFulfillment, Invoice } from "@/pages/Home/Sales";
import { ChartOfAccounts, JournalEntry, Transaction, Reconciliation } from "@/pages/Home/Accounting";
import { SalesForecast, IncomeStatement, BalanceSheet, InventoryEvaluation } from "@/pages/Home/Report";
import { BankConnection } from "@/pages/Home/Banking";
import { Warehouse } from "@/pages/Home/Settings";

const routeConfig = [
    { path: "/", element: <Introduction /> },
    { path: "/index.html", element: <Introduction /> },
    { path: "products", element: <Products /> },
    { path: "pricing", element: <Pricing /> },
    { path: "about", element: <AboutUs /> },
    { path: "contact", element: <ContactUs /> },
    { path: "resource", element: <Resource /> },
    { path: "register", element: <Register /> },
    { path: "login", element: <Login /> },
    { path: "forgot-password", element: <ForgotPassword /> },
    { path: "reset-password", element: <ResetPassword /> },
    {
        path: "workspace",
        element: <UserHome />,
        children: [
            { path: "material/items", element: <Items /> },
            // { path: "material/boms", element: <BOMs /> },
            { path: "material/inventory", element: <Inventory /> },
            // { path: "material/inventory_adjustment", element: <InventoryAdjustment /> },
            { path: "material/recall", element: <Recall /> },
            { path: "purchasing/vendor", element: <Vendor /> },
            { path: "purchasing/po", element: <PO /> },
            { path: "purchasing/po/:poId", element: <PO.Info /> },
            { path: "purchasing/receipt", element: <ItemReceipt /> },
            { path: "purchasing/receipt/po/:poId", element: <ItemReceipt.Add /> },
            { path: "purchasing/receipt/:receiptId", element: <ItemReceipt.Info /> },
            { path: "purchasing/bills", element: <Bill /> },
            { path: "purchasing/bills/receipt/:receiptId", element: <Bill.Add /> },
            { path: "purchasing/bills/blank", element: <Bill.Add /> },
            { path: "purchasing/bills/:billId", element: <Bill.Info /> },
            { path: "sales/customer", element: <Customer /> },
            { path: "sales/so", element: <SaleOrder /> },
            { path: "sales/so/:soId", element: <SaleOrder.Info /> },
            { path: "sales/so-pick-order", element: <SOPickOrder /> },
            { path: "sales/so-pick-order/:poId", element: <SOPickOrder.Info /> },
            { path: "sales/so-pick-order/so/:soId", element: <SOPickOrder.Add /> },
            { path: "sales/so-fulfillment", element: <SOFulfillment /> },
            { path: "sales/so-fulfillment/:fulfillmentId", element: <SOFulfillment.Info /> },
            { path: "sales/so-fulfillment/so/:soId", element: <SOFulfillment.Add /> },
            { path: "sales/invoice", element: <Invoice /> },
            { path: "sales/invoice/blank", element: <Invoice.Blank /> },
            { path: "sales/invoice/:invoiceId", element: <Invoice.Info /> },
            { path: "sales/invoice/so/:soId", element: <Invoice.Add /> },
            { path: "accounting/coa", element: <ChartOfAccounts /> },
            { path: "accounting/journal-entry", element: <JournalEntry /> },
            { path: "accounting/journal-entry/:entryId", element: <JournalEntry.Info /> },
            { path: "accounting/journal-entry/new-entry", element: <JournalEntry.Add /> },
            { path: "accounting/transaction", element: <Transaction /> },
            { path: "accounting/reconciliation", element: <Reconciliation /> },
            { path: "accounting/reconciliation/:reconciliationId", element: <Reconciliation.Detail /> },
            { path: "reporting/sales-forecast", element: <SalesForecast /> },
            { path: "reporting/income-statement", element: <IncomeStatement /> },
            { path: "reporting/balance-sheet", element: <BalanceSheet /> },
            { path: "reporting/inventory-evaluation", element: <InventoryEvaluation /> },
            { path: "banking/bank-connection", element: <BankConnection /> },
            { path: "settings/warehouse", element: <Warehouse /> },
            { path: "settings/warehouse/:warehouseId", element: <Warehouse.Info /> },
            { path: "company/profile", element: <CompanyProfile /> },
            { path: "password", element: <ChangePassword /> },
            { index: true, element: <Items /> }
        ]
    },
    {
        path: "admin",
        element: <Admin />,
        children: [
            { path: "company", element: <Company /> },
            { path: "settings", element: <Settings /> },
            { path: "bank-audit", element: <BankAudit /> },
            { path: "password", element: <ChangePassword /> },
            { index: true, element: <Company /> }
        ]
    },
    {
        path: "*",
        element: <ERR404 />,
    },
]

const Routers = () => {
    const elements = useRoutes(routeConfig);
    return (
        <div className="router-items">
            {elements}
        </div>
    );
}

export default Routers;