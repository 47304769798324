import { InputNumber } from "@arco-design/web-react"
import { NumericUtil } from "@/utils";

export const UIInputNumber = (props) => {
    const { value, ...rest } = props;

    return <InputNumber {...rest} value={value} hideControl
        formatter={(val, { userTyping, input }) => {
            if (!val) return val;
            return userTyping ? input : NumericUtil.f(val);
        }}
        parser={(value) => {
            if (!value) return value;
            const rawValue = NumericUtil.value(NumericUtil.f(value));
            return rawValue;
        }}
        onChange={(val) => {
            let rawValue = val;
            if (val) {
                rawValue = NumericUtil.value(NumericUtil.f(val));
            }
            props.onChange && props.onChange(rawValue);
        }}
    />
}