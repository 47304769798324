import { Card, Space } from "@arco-design/web-react";
import { FulfillmentAdd } from "./fulfillment-add";
import { FulfillmentInfo } from "./fulfillment-info";
import { UITable, UISelect } from "@/components/UI";
import { Link } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { SOFulfillmentAPI } from "@/apis";
import SaleOrderLink from "@/components/SaleOrderLink";
import _ from "lodash";

export const SOFulfillment = () => {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [status, setStatus] = useState();

    const getList = useCallback(async () => {
        const values = await SOFulfillmentAPI.query(page, { status: status });
        setData(values.records);
        setTotal(values.total);
    }, [page, status]);

    useEffect(() => {
        getList();
    }, [getList]);

    return (
        <Card title="SO Fulfillments" extra={
            <Space>
                <UISelect.DictSelect type="fulfillment-status" allowAll style={{ width: 200 }}
                    placeholder="Status"
                    onChange={(val) => {
                        setStatus(val);
                    }} />
            </Space>
        }>
            <UITable columns={[
                {
                    title: "SO Fulfillment#",
                    render: (col, record) => {
                        return <Link to={"/workspace/sales/so-fulfillment/" + record.id}>{record.soFulfillmentNo}</Link>
                    }
                },
                { title: "Customer", dataIndex: "customerName" },
                { title: "Status", dataIndex: "statusDesc" },
                {
                    title: "Pick Order#",
                    render: (col, record) => _.join(_.map(record.pickOrders, "pickOrderNo"), ", ")
                },
                { title: "SO#", render: (col, record) => <SaleOrderLink soId={record.soId}>{record.soNo}</SaleOrderLink> },
                { title: "SO Due Date", dataIndex: "soDueDate" },
                { title: "SO Creation Date", dataIndex: "soCreateDate" },
                { title: "Fulfillment Date", dataIndex: "fulfillmentDate" },
            ]} data={data} total={total} onChange={(val) => setPage(val)} />
        </Card>
    )
}

SOFulfillment.Add = FulfillmentAdd;
SOFulfillment.Info = FulfillmentInfo;