import { UISelect } from "@/components/UI";
import { Tag } from "@arco-design/web-react";

const StatusSelect = (props) => {

    return (
        <UISelect.DictSelect {...props} type="status" placeholder="Status"
            onChange={(val) => props.onChange && props.onChange(val)}
        />
    )
}

const StatusTag = (props) => {
    const { value, children } = props;
    return (
        <Tag color={value === 1 ? "green" : "red"}>{children}</Tag>
    );
}

const Status = {
    Select: StatusSelect,
    Tag: StatusTag
}

export default Status;