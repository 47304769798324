
import _ from "lodash";

let storage = window.localStorage;

const getLoginUsers = () => {
    let users = storage.getItem("login.users");
    return users ? JSON.parse(users) : [];
}

const setLoginUser = (username) => {
    let users = getLoginUsers();
    _.pull(users, username);
    users.unshift(username);
    storage.setItem("login.users", JSON.stringify(_.uniq(users)));
}

export const loginStorage = {
    setLoginUser: setLoginUser,
    getLoginUsers: getLoginUsers
}