import { Button, Card, Form, Input, Message, Space } from "@arco-design/web-react"
import { useEffect } from "react";
import { CompanyAPI } from "@/apis";

const FormItem = Form.Item;

export const PersonInfo = (props) => {
    const [form] = Form.useForm();

    const save = async () => {
        const values = await form.validate();
        await CompanyAPI.updateEmailSettings(values);
        Message.success("Person information saved successfully");
    }

    useEffect(() => {
        const values = {
            ...props.value ?? {}
        }
        form.setFieldsValue(values);
    }, [form, props]);

    return (
        <Card title="Person Info" extra={
            <Space>
                <Button type="primary" onClick={() => save()}>Save</Button>
            </Space>
        }>
            <Form form={form} colon
                labelCol={{ span: 3 }}
                wrapperCol={{ span: 5 }}>
                <FormItem label="First Name" field="firstName" required rules={[{ required: true }]}>
                    <Input />
                </FormItem>
                <FormItem label="Last Name" field="lastName" required rules={[{ required: true }]}>
                    <Input />
                </FormItem>
                <FormItem label="Email" field="email" required rules={[{ required: true }]}>
                    <Input />
                </FormItem>
                {/* <FormItem label="Email Server" field="emailServer">
                    <Input />
                </FormItem>
                <FormItem label="Email Port" field="emailPort">
                    <Input />
                </FormItem>
                <FormItem label="Email Username" field="emailUsername">
                    <Input />
                </FormItem>
                <FormItem label="Email Password" field="emailPassword">
                    <Input />
                </FormItem>
                <FormItem label="Email Auth" field="emailAuth" triggerPropName='checked'>
                    <Switch />
                </FormItem> */}
            </Form>

        </Card>
    );
}