import { Button, Card, Collapse, Divider, Empty, Grid, Popover, Tabs } from "@arco-design/web-react";
import { useCallback, useEffect, useState } from "react";
import { AccountAPI, DictAPI } from "@/apis";
import _ from "lodash";
import { IconEdit, IconLock, IconPlus } from "@arco-design/web-react/icon";
import { AccountForm } from "./account-form";

const TabPane = Tabs.TabPane;
const CollapseItem = Collapse.Item;
const Row = Grid.Row;
const Col = Grid.Col;

export const ChartOfAccounts = () => {
    const [categories, setCategories] = useState([]);
    const [activeTypes, setActiveTypes] = useState([]);
    const [accountFormVisible, setAccountFormVisible] = useState(false);
    const [selectedAccount, setSelectedAccount] = useState({});

    const getAccounts = useCallback(async () => {
        let categoryItems = await DictAPI.getItems("coa-category");
        let types = await AccountAPI.getTypes();
        let typesByCategory = _.groupBy(types, type => type.category);
        _.forEach(categoryItems, c => {
            c.types = typesByCategory[c.value]
        });

        let accounts = await AccountAPI.list();
        let accountsByType = _.groupBy(accounts, account => account.accountType);
        _.forEach(types, type => {
            type.accounts = accountsByType[type.id] || [];
            type.count = type.accounts.length;
        });
        let actives = _.flatMap(types.filter(type => type.count > 0), type => type.id);
        setActiveTypes(actives);

        setCategories(categoryItems);
    }, []);

    useEffect(() => {
        getAccounts();
    }, [getAccounts]);

    const NewAccountButton = (props) => {
        return (
            <Button type="secondary" shape="circle" icon={<IconPlus />} size="mini"
                onClick={() => {
                    setSelectedAccount({
                        accountType: props.typeId,
                        source: 1
                    });
                    setAccountFormVisible(true);
                }}
            ></Button>
        );
    };


    return (
        <Card title="Chart of Accounts" >
            <Tabs defaultActiveTab="1">
                {categories.map(category =>
                    <TabPane key={category.value} title={category.label}>
                        <Collapse activeKey={activeTypes} lazyload={true}
                            onChange={(key, keys, e) => setActiveTypes(keys)}>
                            {category.types && category.types
                                .map((type) =>
                                    <CollapseItem header={type.name + " (" + type.count + ")"}
                                        name={type.id} key={type.id} extra={<NewAccountButton typeId={type.id} />}>
                                        {type.accounts.map((account, index) =>
                                            <Row key={account.id}>
                                                {index > 0 &&
                                                    <Divider style={{ marginTop: 5, marginBottom: 5 }} />
                                                }

                                                <Col span={12}>
                                                    <span className="primary-text">
                                                        {account.source === 2 &&
                                                            <Popover content="This Account is created by system."><IconLock /></Popover>
                                                        }
                                                        {account.accountName}
                                                    </span>
                                                    <Button type="text" shape="circle" icon={<IconEdit />} onClick={() => {
                                                        setSelectedAccount(account);
                                                        setAccountFormVisible(true);
                                                    }}></Button><br />
                                                    <span className="normal-text">Account ID: {account.accountNo}</span><br />
                                                </Col>
                                                <Col span={12}>
                                                    <span>{account.description}</span>
                                                </Col>
                                            </Row>
                                        )}
                                        {type.count <= 0 &&
                                            <Empty />
                                        }
                                    </CollapseItem>
                                )}
                        </Collapse>

                    </TabPane>
                )}

            </Tabs>
            {accountFormVisible &&
                <AccountForm data={selectedAccount}
                    onSubmit={() => {
                        setAccountFormVisible(false);
                        getAccounts();
                    }}
                    onCancel={() => setAccountFormVisible(false)} />
            }
        </Card>
    );
}