import { NumericUtil, DateUtil, DateTimeUtil } from "@/utils";
import { useEffect, useState } from "react";
import _ from "lodash";

export const UIText = (props) => {
    const { value, type, format, ...restProps } = props;
    const [content, setContent] = useState();

    useEffect(() => {
        if (_.isNil(value)) {
            return;
        }

        let item = "";
        if (type === "number") {
            item = NumericUtil.f(value);
        } else if (type === "currency") {
            item = NumericUtil.currency(value)
        } else if (type === "date") {
            item = DateUtil.format(value);
        } else if (type === "datetime") {
            item = DateTimeUtil.format(value);
        } else if (format) {
            item = format(value);
        }
        setContent(item);
    }, [value, type, format])

    return (
        <span {...restProps}>{content}</span>
    );
}
