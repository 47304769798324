import { Button, Card, Message, Space } from "@arco-design/web-react";
import { useRef, useState } from "react";
import { InvoiceForm } from "./invoice-form";
import { Link, useNavigate } from "react-router-dom";
import { InvoiceAPI } from "@/apis";
import { DateUtil } from "@/utils";

export const InvoiceBlank = () => {
    const navigate = useNavigate();
    const formRef = useRef();
    const [invoice] = useState({
        invoiceDate: DateUtil.today()
    });

    const save = async () => {
        const values = await formRef.current.getFormData();
        const invoiceId = await InvoiceAPI.add(values);
        Message.success("Invoice created successfully");
        navigate(`/workspace/sales/invoice/${invoiceId}`);

    }

    return (
        <Card title="Add New Invoice" extra={
            <Space>
                <Button type="primary" onClick={() => save()}>Save</Button>
                <Link to={`/workspace/sales/invoice`}>
                    <Button>Cancel</Button>
                </Link>
            </Space>
        }>
            <InvoiceForm value={invoice} ref={formRef} onSubmit={(val) => navigate(`/workspace/sales/invoice/${val}`)} />

        </Card>
    )
}