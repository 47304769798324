import { Tag } from "@arco-design/web-react";
import { DictAPI } from "@/apis";
import { useCallback, useEffect, useState } from "react";
import _ from "lodash";

export const UITag = (props) => {
    const { value, color, type, ...restProps } = props;

    const [tags, setTags] = useState([]);

    const getTag = useCallback((item) => {
        if (_.isString(item)) {
            return { value: item };
        } else {
            return item;
        }
    }, []);

    const getDict = useCallback(async () => {
        const dicts = await DictAPI.getItems(type);
        const items = _.map(_.filter(dicts, item => _.isArray(value) ? _.indexOf(value, item.value) >= 0 : item.value === value), item => { return { value: item.label } });
        setTags(items);
    }, [type, value]);

    useEffect(() => {
        if (value) {
            if (type) {
                getDict();
            } else {
                let items = []
                if (_.isArray(value)) {
                    items = _.map(value, item => getTag(item));
                } else {
                    items = [getTag(value)];
                }
                setTags(items);
            }
        }
    }, [value, type, getTag, getDict]);

    return (
        <>
            {tags.map((item, index) =>
                <Tag key={index} {...restProps}
                    color={color ?? item.color ?? "green"}
                    style={{ marginRight: 5 }}>
                    {item.value}
                </Tag>
            )}
        </>
    );
}
