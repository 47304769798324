import { Button, Card, Form, Input, Message, Space } from "@arco-design/web-react"
import { CompanyAPI } from "@/apis";
import { useEffect } from "react";

const FormItem = Form.Item;
const TextArea = Input.TextArea;

export const EmailTemplates = (props) => {
    const [form] = Form.useForm();

    const save = async () => {
        const values = await form.validate();
        await CompanyAPI.updateInfo(values);
        Message.success("Email templates saved successfully")
    }

    useEffect(() => {
        form.setFieldsValue(props.value);
    }, [form, props]);

    return (
        <Card title="Email Templates" extra={
            <Space>
                <Button type="primary" onClick={() => save()}>Save</Button>
            </Space>
        }>
            <Form form={form} colon labelCol={{ span: 3 }} wrapperCol={{ span: 21 }}>
                <FormItem label="PO Templates" field="poTemplate">
                    <TextArea style={{ minHeight: 200 }} />
                </FormItem>
                <FormItem label="Receipt Templates" field="receiptTemplate" hidden>
                    <TextArea style={{ minHeight: 200 }} />
                </FormItem>
                <FormItem label="Bill Templates" field="billTemplate" hidden>
                    <TextArea style={{ minHeight: 200 }} />
                </FormItem>
                <FormItem label="SO Templates" field="soTemplate">
                    <TextArea style={{ minHeight: 200 }} />
                </FormItem>
                <FormItem label="Pick Order Templates" field="pickOrderTemplate">
                    <TextArea style={{ minHeight: 200 }} />
                </FormItem>
                <FormItem label="SO Fulfillment Templates" field="soFulfillmentTemplate">
                    <TextArea style={{ minHeight: 200 }} />
                </FormItem>
                <FormItem label="Invoice Templates" field="invoiceTemplate">
                    <TextArea style={{ minHeight: 200 }} />
                </FormItem>
            </Form>
        </Card>

    )
}