import { Form, Input } from "@arco-design/web-react";
import { useImperativeHandle, useState } from "react";
import { UISelect } from "@/components/UI";
import Status from "@/components/Status";

const FormItem = Form.Item;
const TextArea = Input.TextArea;

export const WarehouseForm = ({ value, onRef }) => {
    const [initialValues] = useState(value ?? { inventoryStatus: 1 });
    const [form] = Form.useForm();

    const validate = async () => {
        return await form.validate();
    }

    const reset = () => {
        form.resetFields();
    }

    useImperativeHandle(onRef, () => {
        return {
            validate, reset
        }
    })

    return (

        <Form form={form} colon
            initialValues={initialValues}
            labelCol={{ span: 9 }}
            wrapperCol={{ span: 14 }}>
            <FormItem field="id" hidden>
                <Input />
            </FormItem>
            {initialValues.id &&
                <FormItem field="status" label="Status" required>
                    <Status.Select />
                </FormItem>
            }
            <FormItem field="warehouseNumber" label="Warehouse Number" required rules={[{ required: true }]}>
                <Input />
            </FormItem>
            <FormItem field="warehouseName" label="Warehouse Name" required rules={[{ required: true }]}>
                <Input />
            </FormItem>
            <FormItem field="inventoryStatus" label="Inventory" required rules={[{ required: true }]}>
                <UISelect.DictSelect type="inventory-status" />
            </FormItem>
            <FormItem field="note" label="Notes">
                <TextArea></TextArea>
            </FormItem>
        </Form>
    );
}