import { Button, Modal } from "@arco-design/web-react";
import { useState } from "react"
import GLInfo from "@/pages/Home/Accounting/GL/gl-info";


const GLImpactButton = ({ type, from, ...props }) => {
    const [visible, setVisible] = useState(false);
    const [glType] = useState(type);
    const [glFrom] = useState(from);

    return (
        <>
            <Button type="primary" {...props} onClick={() => setVisible(true)}>GL Impact</Button>
            <Modal title="GL Impact"
                style={{ width: 800 }}
                visible={visible}
                footer={
                    <Button onClick={() => setVisible(false)}>Close</Button>
                }
                onCancel={() => setVisible(false)}
            >
                <GLInfo type={glType} from={glFrom} />
            </Modal> </>
    )
}

export default GLImpactButton;