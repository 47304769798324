import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Input, Space, Table } from "@arco-design/web-react";
import { IconClose, IconDelete, IconEdit, IconSave } from "@arco-design/web-react/icon";
import _ from "lodash";
import "./table.css";

const defaultPagination = {
    current: 1,
    pageSize: 10,
    total: 0
}
const EditableTableContext = React.createContext({});
const EditableContext = React.createContext({});
const FormItem = Form.Item;

const EditableRow = (props) => {
    const { children, record, className, ...rest } = props;
    const [form] = Form.useForm();
    const [editing, setEditing] = useState(false);

    return (
        <EditableContext.Provider value={{ editing: editing, form: form, onEditableChange: setEditing }}>
            <Form
                initialValues={record}
                style={{
                    display: 'table-row'
                }}
                form={form}
                children={children}
                wrapper='tr'
                wrapperProps={rest}
                className={`${className} editable-row`}
            />
        </EditableContext.Provider>
    )

}

const EditableCell = (props) => {
    const { children, className, rowData, column } = props;
    const { editing, form, onEditableChange } = useContext(EditableContext);
    const { onRowDelete, onRowSave } = useContext(EditableTableContext);

    const saveRow = async () => {
        let values = await form.validate();
        onRowSave && onRowSave(values);
        onEditableChange(false);
    }

    if (column.dataIndex === "op") {
        if (editing) {
            return (
                <Space size="mini">
                    <Button type="primary" icon={<IconSave />} onClick={saveRow}>
                        Save
                    </Button>
                    <Button icon={<IconClose />} onClick={() => {
                        form.resetFields();
                        onEditableChange(false);
                    }}>
                        Cancel
                    </Button>
                    {column.editableRender && column.editableRender(column, rowData)}
                </Space >
            )
        }

        return (
            <Space size="mini">
                <Button type="primary" icon={<IconEdit />} onClick={() => onEditableChange(true)}>
                    Edit
                </Button>
                <Button status="danger" icon={<IconDelete />} onClick={() => onRowDelete && onRowDelete(rowData)}>
                    Delete
                </Button>
            </Space>
        )
    }

    if (editing) {
        return (
            <FormItem field={column.dataIndex} rules={[{ required: true, message: "Field is required" }]}>
                {column.editableRender ? column.editableRender(column, rowData) : <Input placeholder="Please enter..." />}
            </FormItem>
        )
    }

    return (
        <div className={className}>{children}</div>
    );
}

export const UIEditableTable = (props) => {
    const { columns, onRowDelete, onRowSave, rowKey, ...restProps } = props;
    const [data, setData] = useState(props.data);
    const [pagination, setPagination] = useState(defaultPagination);

    if (_.findIndex(columns, column => column.dataIndex === "op") < 0) {
        columns.push({
            title: "Operations",
            dataIndex: "op",
            width: 100,
            editableRender: () => <FormItem field={props.rowKey}>
                <Input hidden />
            </FormItem>
        })
    }

    useEffect(() => {
        setData(props.data);
        setPagination({
            ...defaultPagination,
            total: props.total
        });
    }, [props]);

    return (
        <EditableTableContext.Provider value={{
            onRowDelete: onRowDelete,
            onRowSave: onRowSave
        }}>
            <Table
                {...restProps}
                key={rowKey}
                columns={columns}
                pagination={pagination}
                data={data}
                onChange={(pagination) => props.onChange(pagination.current)}
                components={{
                    body: {
                        row: EditableRow,
                        cell: EditableCell
                    }
                }} />
        </EditableTableContext.Provider>
    );
}