import { Button, Card, Descriptions, Form, Input, Message, Modal, Space, Upload } from "@arco-design/web-react"
import { WarehouseAPI } from "@/apis";
import { UITable } from "@/components/UI";
import Status from "@/components/Status";
import { createRef, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IconDelete, IconEdit } from "@arco-design/web-react/icon";
import { UISelect } from "@/components/UI";
import { WarehouseForm } from "./warehouse-form";
import { baseUrl } from "@/utils/api";

const FormItem = Form.Item;
const TextArea = Input.TextArea;

const EditWarehouseButton = ({ value, onOk }) => {
    const [visible, setVisible] = useState(false);
    const formRef = createRef();

    const submit = async () => {
        try {
            const values = await formRef.current.validate();
            await WarehouseAPI.update(values.id, values);
            setVisible(false);
            onOk && onOk();
        } catch (error) {
            Message.error(error.message);
        }
    }

    return (
        <>
            <Button type="primary" onClick={() => {
                setVisible(true);
            }}>Edit</Button>

            <Modal title="Warehouse" visible={visible}
                style={{ width: 550 }}
                afterClose={() => formRef.current.reset()}
                onCancel={() => setVisible(false)}
                onOk={() => submit()}
            >
                <WarehouseForm onRef={formRef} value={value} />
            </Modal>
        </>
    )
}

const AddLocationButton = (props) => {
    const { warehouseId, warehouseStatus } = props;
    const [warehouseNotAvailable] = useState(warehouseStatus && warehouseStatus === 2)
    const [visible, setVisible] = useState(false);
    const [form] = Form.useForm();
    const [initialValues] = useState({
        status: warehouseNotAvailable ? 2 : 1
    })

    const submit = async () => {
        try {
            const values = await form.validate();
            if (values.id) {
                await WarehouseAPI.updateLocation(warehouseId, values.id, values);
                Message.success("Warehouse location updated successfully")
            } else {
                await WarehouseAPI.addLocation(warehouseId, values);
                Message.success("Warehouse location created successfully")
                setVisible(false);
                props.onOk && props.onOk();
            }
        } catch (error) {
            Message.error(error.message);
        }
    }

    useEffect(() => {

    }, []);

    return (
        <>
            <Button type="primary" onClick={() => {
                setVisible(true);
            }}>Add New Location</Button>

            <Modal title="Add New Location" visible={visible}
                afterClose={() => form.resetFields()}
                onCancel={() => setVisible(false)}
                onOk={submit}
            >
                <Form form={form} colon
                    initialValues={initialValues}
                    labelCol={{ span: 8 }} wrapperCol={{ span: 15 }}>
                    <FormItem field="id" hidden>
                        <Input />
                    </FormItem>
                    <FormItem field="name" label="Location" required>
                        <Input />
                    </FormItem>
                    <FormItem field="status" label="Inventory Status" required>
                        <UISelect.DictSelect type="inventory-status" disabled={warehouseNotAvailable} />
                    </FormItem>
                    <FormItem field="notes" label="Notes">
                        <TextArea></TextArea>
                    </FormItem>
                </Form>
            </Modal>
        </>
    );
}

const EditLocationButton = (props) => {
    const { value } = props;
    const [warehouseId] = useState(value.warehouseId);
    const [visible, setVisible] = useState(false);
    const [form] = Form.useForm();

    const submit = async () => {
        try {
            const values = await form.validate();
            await WarehouseAPI.updateLocation(warehouseId, values.id, values);
            Message.success("Warehouse location updated successfully")
            setVisible(false);
            props.onOk && props.onOk();
        } catch (error) {
            Message.error(error.message);
        }
    }

    useEffect(() => {
        if (value) {
            form.setFieldsValue(value);
        }
    }, [form, value]);

    return (
        <>
            <Button type="text" icon={<IconEdit />} onClick={() => {
                setVisible(true);
            }} />

            <Modal title="Edit Location" visible={visible}
                onCancel={() => setVisible(false)}
                onOk={submit}
            >
                <Form form={form} colon
                    labelCol={{ span: 8 }} wrapperCol={{ span: 15 }}>
                    <FormItem field="id" hidden>
                        <Input />
                    </FormItem>
                    <FormItem field="name" label="Location" required>
                        <Input />
                    </FormItem>
                    <FormItem field="status" label="Inventory Status" required>
                        <UISelect.DictSelect type="inventory-status" />
                    </FormItem>
                    <FormItem field="notes" label="Notes">
                        <TextArea></TextArea>
                    </FormItem>
                </Form>
            </Modal>
        </>
    );
}

export const WarehouseInfo = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [warehouse, setWarehouse] = useState({});
    const [warehouseStatus, setWarehouseStatus] = useState();
    const warehouseId = params.warehouseId;

    const [locations, setLocations] = useState([]);
    const [totalLocations, setTotalLocations] = useState(0);
    const [pageLocation, setPageLocation] = useState(1);

    const getWarehouse = useCallback(async () => {
        const value = await WarehouseAPI.get(warehouseId);
        setWarehouse(value);
        setWarehouseStatus(value.inventoryStatus);
    }, [warehouseId]);

    const getLocations = useCallback(async () => {
        const value = await WarehouseAPI.getLocations(warehouseId, pageLocation);
        setLocations(value.records);
        setTotalLocations(value.total);
    }, [warehouseId, pageLocation]);

    const onUploadLocationsChange = (fileList, file) => {
        if (file.status === "done") {
            Message.success("Upload locations successfully");
            getLocations();
        }
    }

    const back = () => {
        navigate("/workspace/settings/warehouse");
    }

    useEffect(() => {
        getWarehouse();
    }, [getWarehouse]);

    useEffect(() => {
        getLocations();
    }, [getLocations]);

    return (
        <Card title={<Space>
            {warehouse.warehouseName}
            <Status.Tag value={warehouse.status}>{warehouse.statusDesc}</Status.Tag>
        </Space>} extra={
            <Space>
                <EditWarehouseButton value={warehouse} onOk={() => getWarehouse()} />
                {warehouseStatus &&
                    <AddLocationButton warehouseId={warehouseId} warehouseStatus={warehouseStatus} onOk={() => getLocations()} />
                }
                <Upload withCredentials
                    headers={{ 'falcontoken': localStorage.getItem("login.token") }}
                    action={`${baseUrl}/warehouse/${warehouseId}/locations`}
                    onChange={onUploadLocationsChange}
                    showUploadList={false}>
                    <Button type="primary">Import Locations</Button>
                </Upload>
                <Button onClick={() => window.location = `${baseUrl}/WarehouseLocationTemplate.xlsx`}>Download Location Template</Button>
                <Button onClick={() => back()}>Back</Button>
            </Space>
        }>
            <Descriptions colon=": " data={[{
                label: "Warehouse Number",
                value: warehouse.warehouseNumber
            }, {
                label: "Warehouse Name",
                value: warehouse.warehouseName
            }, {
                label: "Inventory Status",
                value: warehouse.inventoryStatusName
            }, {
                label: "Notes",
                value: warehouse.note
            }]} />

            <UITable columns={[
                { title: "Location", dataIndex: "name" },
                { title: "Inventory Status", dataIndex: "statusDesc" },
                { title: "Notes", dataIndex: "notes" },
                {
                    title: "Operations", render: (col, record) => <Space>
                        <EditLocationButton value={record} onOk={() => getLocations()} />
                        <Button type="text" status="danger" icon={<IconDelete />} />
                    </Space>
                }
            ]}
                data={locations} total={totalLocations} onChange={(val) => { setPageLocation(val) }}
            />

        </Card >
    )
}