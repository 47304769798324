import { useCallback, useEffect, useState } from "react";
import { WarehouseAPI } from "@/apis";
import { UISelect } from "@/components/UI";
import _ from "lodash";
import { debounce } from "lodash";
import { Button, Divider } from "@arco-design/web-react";
import { Link } from "react-router-dom";

const WarehouseSelect = (props) => {
    const { type, addLink, ...restProps } = props;
    const [options, setOptions] = useState([]);

    useEffect(() => {
        const getOptions = async () => {
            const values = await WarehouseAPI.actives();
            setOptions(_.orderBy(values, ['warehouseName'], ['asc']));
        }

        getOptions();
    }, [type]);

    return (
        <UISelect {...restProps}
            showSearch
            filterOption={(inputValue, option) =>
                _.toLower(option.props.value).indexOf(inputValue.toLowerCase()) >= 0 ||
                option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
            }
            dropdownRender={(menu) => (
                <div>
                    {menu}
                    {addLink &&
                        <>
                            <Divider style={{ margin: 0 }} />
                            <Link to={`/workspace/settings/warehouse`}>
                                <Button type="text" size="small" style={{ marginLeft: 22, padding: '6px 0' }}>Add Warehouse</Button>
                            </Link>
                        </>
                    }
                </div>
            )}
            records={options}
            labelKey="warehouseName"
            onChange={(val) => props.onChange && props.onChange(val)}>
        </UISelect>
    );
}

const UILocationSelect = (props) => {
    const { warehouseId, onlyActive, ...restProps } = props;
    const [options, setOptions] = useState([]);
    const [onlyActiveLocations] = useState(onlyActive ?? true);

    const getOptions = useCallback(async (locationName) => {
        const search = debounce(async (warehouseId, locationName) => {
            if (warehouseId) {
                if (onlyActiveLocations) {
                    const values = await WarehouseAPI.activeLocations(warehouseId, locationName);
                    setOptions(_.orderBy(values, ['name'], ['asc']));
                } else {
                    const values = await WarehouseAPI.locations(warehouseId, locationName);
                    setOptions(_.orderBy(values.records, ['name'], ['asc']));
                }
            }
        }, 100);
        await search(warehouseId, locationName);
    }, [warehouseId, onlyActiveLocations]);

    useEffect(() => {
        getOptions();
    }, [getOptions]);

    return (
        <UISelect {...restProps}
            showSearch
            allowClear
            filterOption={false}
            onSearch={(val) => {
                getOptions(val);
            }}
            records={options}
            labelKey="name"
            onChange={(val) => props.onChange && props.onChange(val)}>
        </UISelect>
    );
}

WarehouseSelect.LocationSelect = UILocationSelect;

export default WarehouseSelect;