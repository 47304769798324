import { Button, Card, Checkbox, Collapse, Divider, Form, Message, Modal, Popconfirm, Space, Switch, Typography } from "@arco-design/web-react"
import { BankAPI } from "@/apis";
import { usePlaidLink } from 'react-plaid-link';
import { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import { IconDelete, IconEdit, IconInfoCircleFill } from "@arco-design/web-react/icon";
import { UIText, UIDatePicker } from "@/components/UI";
import AccountSelect from "@/components/AccountSelect";

const CollapseItem = Collapse.Item;
const { Title, Paragraph, Text } = Typography;

export const BankConnection = () => {
    const [linkToken, setLinkToken] = useState(null);
    const [institutions, setInstitutions] = useState([]);

    const connect = useCallback(async () => {
        const data = await BankAPI.connect();
        setLinkToken(data.token);
    }, [])

    const getInstitutions = useCallback(async () => {
        let institutions = await BankAPI.institutions();

        const accounts = await BankAPI.accounts();
        const accountsByInstitution = _.groupBy(accounts, 'institutionId');

        _.forEach(institutions, (o) => {
            o.accounts = accountsByInstitution[o.institutionId];
        })

        institutions = _.filter(institutions, (o) => {
            return _.size(o.accounts) > 0;
        });

        setInstitutions(institutions);
    }, []);

    const disconnect = async (bankAccountId) => {
        await BankAPI.delete(bankAccountId);
        Message.success("Account deleted successfully");
        getInstitutions();
    }

    useEffect(() => {
        connect();
    }, [connect])

    useEffect(() => {
        getInstitutions();
    }, [getInstitutions])

    return (
        <Card title="Bank Connection" extra={
            <Space>
                {linkToken && <PlaidLink value={linkToken} onSuccess={() => getInstitutions()} />}
            </Space>
        }>
            {_.size(institutions) > 0 &&
                <Collapse
                >
                    {institutions.map((item) => {
                        return (
                            <CollapseItem key={item.institutionId} name={item.institutionId} header={item.institutionName} extra={
                                <Space>
                                </Space>
                            }
                            >
                                {item.accounts && item.accounts.map((account, index) => (
                                    <Typography key={index}>
                                        {index > 0 &&
                                            <Divider />
                                        }
                                        <Title heading={6}>
                                            {account.institutionAccountName}
                                            <Popconfirm focusLock title="Confirm" content="Are you sure you want to delete?"
                                                onOk={() => disconnect(account.id)}>
                                                <Button type="text" status="danger" icon={<IconDelete />} />
                                            </Popconfirm>
                                        </Title>
                                        <Paragraph>
                                            <Space direction="vertical">
                                                <Text>{_.capitalize(account.subType)} **** {account.mask}</Text>
                                                <Text>Current Balance: <UIText type="currency" value={account.balance} /></Text>
                                            </Space>
                                        </Paragraph>
                                        <Space>
                                            <SyncTransactionSwitch account={account} />
                                            <span>Automatically Sync Transactions From {account.syncStartDate}</span>
                                            <TransactionSyncStartDateLink
                                                account={account}
                                                startDate={account.syncStartDate}
                                                onSubmit={() => {
                                                    getInstitutions();
                                                }}
                                            />
                                        </Space>
                                    </Typography>
                                ))}
                            </CollapseItem>
                        )
                    })}
                </Collapse >
            }
        </Card >
    )
}

const PlaidLink = (props) => {
    const { value } = props;
    const [form] = Form.useForm();
    const [visible, setVisible] = useState(false);
    const [publicToken, setPublicToken] = useState(null);
    const [metadata, setMetadata] = useState(null);

    const onSuccess = useCallback(async (publicToken, metadata) => {
        setPublicToken(publicToken)
        setMetadata(metadata)
        setVisible(true);
    }, []);

    const addAccount = async () => {
        const values = await form.validate();
        const bank = {
            institutionId: metadata.institution.institution_id,
            institutionName: metadata.institution.name
        };
        await BankAPI.addAccount({
            publicToken: publicToken,
            bank: bank,
            bankAccounts: _.map(metadata.accounts, item => ({
                institutionAccountId: item.id,
                institutionAccountName: item.name,
                type: item.type,
                subType: item.subtype,
                mask: item.mask,
                autoTransSync: false,
                syncStartDate: values.startDate
            }))
        });

        props.onSuccess && props.onSuccess();
    }

    const config = {
        token: value,
        onSuccess
    }
    const { open, ready } = usePlaidLink(config);

    return (
        <>
            <Button type="primary" onClick={() => open()} disabled={!ready}>Connect Account</Button>
            <Modal title="Transaction Import Confirm"
                visible={visible}
                style={{ minWidth: 500 }}
                onOk={() => {
                    try {
                        addAccount();
                    } finally {
                        setVisible(false);
                    }
                }}>
                <Form form={form} colon>
                    <Form.Item label="From when the import should happend"
                        field="startDate"
                        layout="vertical"
                        labelCol={{ span: 7 }} wrapperCol={{ span: 17 }}
                        required rules={[{ required: true }]}>
                        <UIDatePicker />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

const TransactionSyncStartDateLink = (props) => {
    const { account } = props;
    const [form] = Form.useForm();
    const [visible, setVisible] = useState(false);

    const save = async () => {
        const values = await form.validate();
        await BankAPI.updateSyncStartDate(account.id, values.startDate);
        props.onSubmit && props.onSubmit();
        setVisible(false);
    }

    useEffect(() => {
        form.setFieldValue("startDate", account.syncStartDate);
    }, [form, account])

    return (
        <>
            <Button type="text" onClick={() => {
                setVisible(true);
            }}
                icon={<IconEdit />} size="mini">
                Edit date
            </Button>

            <Modal title="Transaction Import Confirm"
                visible={visible}
                closeIcon={false}
                onCancel={() => {
                    setVisible(false);
                }}
                onOk={() => save()}>
                <Form form={form} layout="vertical">
                    <Form.Item
                        label="From when the import should happend"
                        field="startDate"
                        labelCol={{ span: 7 }} wrapperCol={{ span: 17 }}
                        required rules={[{ required: true }]}>
                        <UIDatePicker />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

const SyncTransactionSwitch = ({ account, onCancel, onOk }) => {
    const [autoTransSync, setAutoTransSync] = useState(account.autoTransSync);
    const [visible, setVisible] = useState(false);
    const [useExistingAccount, setUseExistingAccount] = useState(false);
    const [accountId, setAccountId] = useState();
    const [accountType] = useState(account.type === "credit" ? "10" : "5");
    const [loading, setLoading] = useState(false);

    const toggleAutoTransSync = async (accountId, open) => {
        setAutoTransSync(open);
        try {
            setLoading(true);
            if (open === true && account.accountId == null) {
                setVisible(true);
                return;
            }

            await BankAPI.changeAutoTransSync(accountId, open);
            setLoading(false);
        } catch (error) {
            Message.error(error.message);
            setLoading(false);
        }
    }

    return (
        <>
            <Switch checked={autoTransSync}
                loading={loading}
                onChange={(val) => toggleAutoTransSync(account.id, val)} />
            <Modal visible={visible} title="Confirm"
                onCancel={() => {
                    onCancel && onCancel();
                    setVisible(false);
                    setLoading(false);
                    setAutoTransSync(false);
                }}
                onOk={async () => {
                    try {
                        await BankAPI.bindSystemAccount(account.id, useExistingAccount ? accountId : "");
                        await BankAPI.changeAutoTransSync(account.id, true);
                        setVisible(false);
                        setLoading(false);
                        onOk && onOk();
                    } catch (error) {
                        Message.error(error.message);
                    }
                }}>
                <p>
                    <IconInfoCircleFill style={{ color: "#165DFF", marginRight: 5 }} />You can use an existing bank account or create new account.
                </p>
                <p>
                    <Space>
                        <Checkbox checked={useExistingAccount} onChange={async (val) => {
                            setUseExistingAccount(val);
                        }}></Checkbox>
                        <AccountSelect placeholder="Select from existing accounts" disabled={!useExistingAccount} style={{ width: 320 }}
                            value={accountId} type={accountType}
                            onChange={(val) => setAccountId(val)} />
                    </Space>
                </p>
            </Modal>
        </>
    )
}