import { createSlice } from "@reduxjs/toolkit";

const initialValue = {
    fullName: '',
    companyId: null,
    companyName: null,
    roles: [],
    isAdmin: false,
    loaded: false
}

export const userInfoSlice = createSlice({
    name: 'userInfo',
    initialState: initialValue,
    reducers: {
        updateUserInfo: (state, action) => {
            state.fullName = action.payload.fullName;
            state.companyId = action.payload.companyId;
            state.companyName = action.payload.companyName;
            state.roles = action.payload.roles;
            state.isAdmin = action.payload.isAdmin;
            state.loaded = true;
        },
        clearUserInfo: (state) => {
            state.fullName = '';
            state.companyId = null;
            state.companyName = null;
            state.roles = [];
            state.isAdmin = false;
            state.loaded = false;
        }
    }
})

export const { updateUserInfo, clearUserInfo } = userInfoSlice.actions
export default userInfoSlice.reducer