import { Button, Card, Message, Modal, Space } from "@arco-design/web-react"
import { UITable } from "@/components/UI";
import Status from "@/components/Status";
import { createRef, useEffect, useState } from "react";
import { WarehouseAPI } from "@/apis";
import { WarehouseForm } from "./warehouse-form";
import { WarehouseInfo } from "./warehouse-info";
import { useCallback } from "react";
import { Link } from "react-router-dom";

const AddWarehouseButton = ({ onOk }) => {

    const formRef = createRef();
    const [visible, setVisible] = useState(false);

    const submit = async () => {
        try {
            const values = await formRef.current.validate();
            await WarehouseAPI.add(values);
            Message.success("Warehouse created successfully")
            setVisible(false);
            onOk && onOk();
        } catch (error) {
            Message.error(error.message);
        }
    }

    return (
        <>
            <Button type="primary" onClick={() => {
                setVisible(true);
            }}>Add New Warehouse</Button>

            <Modal title="Add New Warehouse" visible={visible}
                style={{ width: 550 }}
                afterClose={() => formRef.current.reset()}
                onCancel={() => setVisible(false)}
                onOk={() => submit()}
            >
                <WarehouseForm onRef={formRef} />
            </Modal>
        </>
    )
}

export const Warehouse = () => {
    const [status, setStatus] = useState();
    const [page, setPage] = useState(1);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);

    const getWarehouses = useCallback(async () => {
        const values = await WarehouseAPI.query(page, { status: status });
        setData(values.records);
        setTotal(values.total);
    }, [page, status]);

    useEffect(() => {
        getWarehouses();
    }, [getWarehouses]);

    return (
        <Card title="Warehouse" extra={
            <Space>
                <Status.Select style={{ width: 100 }} value={status} allowAll
                    onChange={(val) => {
                        setStatus(val);
                    }} />
                <AddWarehouseButton onOk={() => getWarehouses()} />
            </Space>
        }>
            <UITable columns={[
                { title: "Warehouse Number", dataIndex: "warehouseNumber" },
                { title: "Warehouse Name", render: (col, record) => <Link to={`/workspace/settings/warehouse/${record.id}`} >{record.warehouseName}</Link> },
                {
                    title: "Status",
                    render: (col, record) => <Status.Tag value={record.status}>{record.statusDesc}</Status.Tag>

                },
                { title: "Inventory", dataIndex: "inventoryStatusName" },
                { title: "Notes", dataIndex: "note" },
            ]} data={data} total={total} onChange={pageNum => setPage(pageNum)} />

        </Card>
    )
}

Warehouse.Info = WarehouseInfo;