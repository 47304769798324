import { Button, Card, Descriptions, Message, Modal, Space, Tabs } from "@arco-design/web-react"
import { BillAPI, VendorAPI } from "@/apis";
import { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { UITable, UIText, UITag } from "@/components/UI";
import { NumericUtil, RefUtil, FileUtil } from "@/utils";
import GLInfo from "@/pages/Home/Accounting/GL/gl-info";
import { BillForm } from "./bill-form";
import SendEmailButton from "@/components/SendEmailButton";

const TabPane = Tabs.TabPane;

const PaymentLink = ({ billId, children }) => {
    const [visible, setVisible] = useState(false);
    const [data, setData] = useState();

    const getPayments = async () => {
        const values = await BillAPI.payments(billId);
        setData(values);
    }

    return (
        <>
            <Button type="text" onClick={() => setVisible(true)}>{children}</Button>
            <Modal visible={visible}
                style={{ width: 650 }}
                afterOpen={() => getPayments()}
                onOk={() => setVisible(false)}
                onCancel={() => setVisible(false)}
                title="Payments"
            >
                <UITable columns={[{
                    title: "Account Name",
                    dataIndex: "accountName"
                }, {
                    title: "Payment Date",
                    dataIndex: "date"
                }, {
                    title: "Payment Amount",
                    render: (col, record) => <UIText type="currency" value={record.amount * -1} />
                }]}
                    data={data} />
            </Modal>
        </>
    )
}

export const BillInfo = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [bill, setBill] = useState({});
    const [glImpactVisible, setGLImpactVisible] = useState(false);
    const [editing, setEditing] = useState(false);
    const [printLoading, setPrintLoading] = useState(false);
    const formRef = useRef(null);

    const getInfo = useCallback(async () => {
        const values = await BillAPI.get(params.billId);
        setBill(values);
    }, [params]);

    const save = async () => {
        try {
            const values = await formRef.current.submit();
            await BillAPI.update(bill.id, values);
            Message.success("Bill updated successfully");
            setEditing(false);
            getInfo();
        } catch (error) {
            Message.error(error.message);
        }
    }

    const back = () => {
        navigate("/workspace/purchasing/bills");
    }

    const deleteBill = async () => {
        await BillAPI.delete(params.billId);
        Message.success("Bill voided successfully");
        back();
    }

    useEffect(() => {
        getInfo();
    }, [getInfo]);

    return (
        <Card title={<Space>
            <span>{`Bill #` + bill.billNo}</span>
            <UITag color={bill.status === 6 ? "red" : "green"} value={bill.statusDesc}></UITag>
        </Space>} extra={editing
            ? <Space>
                <Button type="primary" onClick={() => save()}>Save</Button>
                <Button onClick={() => setEditing(false)}>Cancel</Button>
            </Space>
            : <Space>
                {bill.status < 6 &&
                    <Button type="primary" onClick={() => setEditing(true)}>Edit</Button>
                }
                {bill.status < 6 && bill.paidAmount <= 0 &&
                    <Button type="primary" status="danger" onClick={() => deleteBill()}>Void Bill</Button>
                }
                {bill.status < 6 &&
                    <Button type="primary" onClick={() => setGLImpactVisible(true)}>GL Impact</Button>
                }
                {bill.status !== 6 &&
                    <Button type="primary" onClick={async () => {
                        setPrintLoading(true);
                        try {
                            const res = await BillAPI.print(bill.id);
                            FileUtil.download(res.data, `${bill.billNo}.pdf`);
                        } catch (error) {
                            Message.error(error.message);
                        } finally {
                            setPrintLoading(false);
                        }
                    }} loading={printLoading}>Print</Button>
                }
                {bill.status !== 6 &&
                    <SendEmailButton getEmailContent={async () => {
                        return await BillAPI.getEmail(bill.id);
                    }} getToList={async () => {
                        const vendor = await VendorAPI.get(bill.vendorId);
                        return vendor.contacts;
                    }} sendEmail={async (val) => {
                        await BillAPI.sendEmail(bill.id, val);
                    }} />
                }
                <Button onClick={() => back()}>Back</Button>
            </Space>
        }>
            {!editing ?
                <>
                    <Descriptions colon=":" layout="inline-horizontal" data={[
                        { label: "Vendor", value: bill.vendorName },
                        { label: "Bill Date", value: bill.billDate },
                        { label: "Paid Date", value: bill.processDate },
                        { label: "Address", value: bill.vendorAddress },
                        { label: "Item Receipt#", value: <Link to={`/workspace/purchasing/receipt/${bill.receiptId}`}>{bill.receiptNo}</Link> },
                        { label: "PO#", value: <Link to={`/workspace/purchasing/po/${bill.poId}`}>{bill.poNo}</Link> },
                        { label: "Invoice#", value: bill.invoiceNo },
                        { label: "Terms", value: bill.termName },
                        { label: "Reference#", value: RefUtil.format(bill.reference) },
                        { label: "Due Date", value: bill.dueDate },
                        { label: "Total Amount", value: NumericUtil.currency(bill.totalAmount) },
                        {
                            label: "Payment Amount",
                            value: <PaymentLink billId={bill.id}>
                                <UIText type="currency" value={bill.paidAmount} />
                            </PaymentLink>
                        },
                        { label: "Balance Due", value: NumericUtil.currency(bill.totalAmount - bill.paidAmount) },
                        { label: "Memo", value: bill.memo, span: 2 },
                    ]} />

                    <Tabs defaultActiveTab="2" type="card-gutter">
                        <TabPane key="1" title={
                            <Space size={80}>
                                <span>Expenses</span>
                                <span>{NumericUtil.currency(bill.totalExpense)}</span>
                            </Space>
                        }>

                            <UITable columns={[
                                { title: "Account", dataIndex: "accountName" },
                                { title: "Amount", render: (col, record) => <UIText type="currency" value={record.amount} /> },
                                { title: "Memo", dataIndex: "memo" },
                            ]} data={bill.expenses || []} />
                        </TabPane>
                        <TabPane key="2" title={
                            <Space size={80}>
                                <span>Items</span>
                                <span>{NumericUtil.currency(bill.totalItems)}</span>
                            </Space>
                        }>
                            <UITable columns={[
                                { title: "Item", dataIndex: "itemName" },
                                { title: "Description", dataIndex: "description" },
                                { title: "Qty", render: (col, record) => <UIText type="number" value={record.quantity} /> },
                                { title: "U/M", dataIndex: "unitName" },
                                { title: "Cost", render: (col, record) => <UIText type="number" value={record.cost} /> },
                                { title: "Amount", render: (col, record) => <UIText type="currency" value={record.amount} /> },
                            ]} data={bill.items} />
                        </TabPane>
                    </Tabs>
                    <Modal title="GL Impact"
                        style={{ width: 800 }}
                        visible={glImpactVisible}
                        onCancel={() => setGLImpactVisible(false)}
                        onOk={() => setGLImpactVisible(false)}>
                        <GLInfo type="2" from={params.billId}
                        />
                    </Modal>
                </>
                :
                <BillForm ref={formRef} value={bill} />
            }
        </Card >
    )
}