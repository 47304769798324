import { Button, Descriptions, Link, Modal } from "@arco-design/web-react";
import { ItemAPI, POAPI, InventoryAPI } from "@/apis";
import { useState } from "react";
import { NumericUtil } from "@/utils";
import { UITable, UIText } from "@/components/UI";

export const ItemLink = ({ value, children }) => {
    const [visible, setVisible] = useState(false);
    const [itemId] = useState(value);
    const [itemInfo, setItemInfo] = useState({});
    const [inventoryStock, setInventoryStock] = useState([]);
    const [poListTotal, setPOListTotal] = useState(0);
    const [poList, setPOList] = useState([]);
    const [cogsAccountLabel, setCogsAccountLabel] = useState("COGS Account");

    const getInfo = async () => {
        const info = await ItemAPI.get(itemId);
        setItemInfo(info);
        setCogsAccountLabel(info.itemType === 2 ? "COGS/Expense Account" : "COGS Account");
    }

    const getInventoryStock = async () => {
        const stock = await InventoryAPI.stock(itemId);
        setInventoryStock(stock);
    }

    const getPOs = async (page) => {
        const poInfo = await POAPI.queryByItemId(page ?? 1, itemId);
        setPOList(poInfo.records);
        setPOListTotal(poInfo.total);
    }

    return (
        <>
            <Link onClick={() => {
                getInfo();
                getInventoryStock();
                getPOs();
                setVisible(true);
            }}>{children}</Link>
            <Modal visible={visible} style={{ width: 800 }}
                title={children}
                footer={
                    <Button type="primary" onClick={() => setVisible(false)}>Close</Button>
                }
                onCancel={() => setVisible(false)}>
                <Descriptions column={2} colon=':'
                    labelStyle={{ paddingRight: 36 }}
                    data={[{
                        span: 2,
                        label: "Item Type",
                        value: itemInfo.itemTypeDesc
                    }, {
                        span: 2,
                        label: "Item Number",
                        value: itemInfo.itemNo
                    }, {
                        span: 2,
                        label: "Item Name",
                        value: itemInfo.itemName
                    }, {
                        span: 2,
                        label: "Description",
                        value: itemInfo.description
                    }, {
                        span: 2,
                        label: "Standard Cost",
                        value: NumericUtil.currency(itemInfo.stdCost)
                    }, {
                        span: 2,
                        label: "Unit of Measure(UOM)",
                        value: itemInfo.unitDesc
                    }, {
                        span: 2,
                        label: "Default Margin",
                        value: itemInfo.defaultMargin
                    }, {
                        span: 2,
                        label: "Sales Price",
                        value: NumericUtil.currency(itemInfo.salePrice)
                    }, {
                        span: 2,
                        label: cogsAccountLabel,
                        value: itemInfo.cogsAccount ? itemInfo.cogsAccount.accountName : ""
                    }, {
                        span: 2,
                        label: "Income Account",
                        value: itemInfo.incomeAccount ? itemInfo.incomeAccount.accountName : ""
                    }, {
                        span: 2,
                        label: "Inventory Account",
                        value: itemInfo.inventoryAccount ? itemInfo.inventoryAccount.accountName : ""
                    }, {
                        span: 2,
                        label: "Safety Stock Level(UOM)",
                        value: itemInfo.safetyStockLevelUom ?? ""
                    }, {
                        label: "Preferred Vendor",
                        value: itemInfo.preferredVendor ? itemInfo.preferredVendor.vendorName : ""
                    }, {
                        label: "Lead Time",
                        value: itemInfo.preferredVendorLeadTime
                    }, {
                        label: "Vendor",
                        value: itemInfo.vendor ? itemInfo.vendor.vendorName : ""
                    }, {
                        label: "Lead Time",
                        value: itemInfo.vendorLeadTime
                    }]} />

                <UITable columns={[
                    { title: "Warehouse", dataIndex: "warehouseName" },
                    { title: "Location", dataIndex: "locationName" },
                    { title: "Lot#", dataIndex: "lot" },
                    { title: "Expiration Date", dataIndex: "expiredDate" },
                    { title: "Inventory OnHand", render: (col, record) => <UIText type="number" value={record.onHand} /> },
                    { title: "Inventory Status", dataIndex: "status" },
                ]}
                    data={inventoryStock}
                />

                <UITable style={{ marginTop: 10 }} columns={[
                    { title: "Vendor Name", dataIndex: "vendorName" },
                    { title: "PO#", dataIndex: "poNo" },
                    { title: "Date", render: (col, record) => <UIText type="date" value={record.poDate} /> },
                    { title: "Qty", render: (col, record) => <UIText type="number" value={record.quantity} /> },
                    { title: "Rate", render: (col, record) => <UIText type="number" value={record.rate} /> }
                ]}
                    data={poList}
                    total={poListTotal}
                    onChange={(page) => getPOs(page)}
                />
            </Modal>
        </>
    )
}