import { Button, Card, Descriptions, Link, Message, Modal, Popconfirm, Space, Tabs } from "@arco-design/web-react"
import { InvoiceAPI, CustomerAPI } from "@/apis";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UITable, UIText, UITag } from "@/components/UI";
import { NumericUtil, RefUtil, FileUtil } from "@/utils";
import GLImpactButton from "@/components/GLImpactButton";
import { InvoiceForm } from "./invoice-form";
import SaleOrderLink from "@/components/SaleOrderLink";
import SendEmailButton from "@/components/SendEmailButton";

const TabPane = Tabs.TabPane;

const PaymentLink = ({ invoiceId, children }) => {
    const [visible, setVisible] = useState(false);
    const [data, setData] = useState();

    const getPayments = async () => {
        const values = await InvoiceAPI.payments(invoiceId);
        setData(values);
    }

    return (
        <>
            <Link onClick={() => setVisible(true)}>{children}</Link>
            <Modal visible={visible}
                style={{ width: 650 }}
                afterOpen={() => getPayments()}
                onOk={() => setVisible(false)}
                onCancel={() => setVisible(false)}
                title="Payments"
            >
                <UITable columns={[{
                    title: "Account Name",
                    dataIndex: "accountName"
                }, {
                    title: "Payment Date",
                    dataIndex: "date"
                }, {
                    title: "Payment Amount",
                    render: (col, record) => <UIText type="currency" value={record.amount} />
                }]}
                    data={data} />
            </Modal>
        </>
    )
}

export const InvoiceInfo = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [invoice, setInvoice] = useState({});
    const [hasPayment, setHasPayment] = useState(false);
    const [editing, setEditing] = useState(false);
    const [printLoading, setPrintLoading] = useState(false);
    const formRef = useRef();

    const getInfo = useCallback(async () => {
        const values = await InvoiceAPI.get(params.invoiceId);
        setInvoice(values);
        setHasPayment(values.paidAmount > 0);
    }, [params]);

    const back = () => {
        navigate("/workspace/sales/invoice");
    }

    const voidInvoice = async () => {
        await InvoiceAPI.voidInvoice(params.invoiceId);
        Message.success("Invoice voided successfully");
        getInfo();
    }

    const save = async () => {
        try {
            const values = await formRef.current.getFormData();
            await InvoiceAPI.update(values.id, values);
            Message.success("Invoice updated successfully");
            getInfo();
            setEditing(false);
        } catch (error) {
            Message.error(error.message);
        }
    }

    useEffect(() => {
        getInfo();
    }, [getInfo]);

    return (
        <Card title={
            <Space>
                <span>Invoice #{invoice.invoiceNo}</span>
                <UITag value={invoice.statusName} />
            </Space>
        }
            extra={
                !editing ?
                    <Space>
                        {invoice.status < 5 &&
                            <Button type="primary" onClick={() => setEditing(true)}>Edit</Button>
                        }
                        {invoice.status !== 6 && !hasPayment &&
                            <Popconfirm title="Are you sure you want to void invoice?"
                                onOk={() => voidInvoice()}>
                                <Button type="primary" status="danger">Void Invoice</Button>
                            </Popconfirm>
                        }
                        {invoice.status !== 6 &&
                            <GLImpactButton type="4" from={params.invoiceId} />
                        }
                        {invoice.status !== 6 &&
                            <Button type="primary" onClick={async () => {
                                setPrintLoading(true);
                                try {
                                    const res = await InvoiceAPI.print(invoice.id);
                                    FileUtil.download(res.data, `${invoice.invoiceNo}.pdf`)
                                } catch (error) {
                                    Message.error(error.message);
                                } finally {
                                    setPrintLoading(false);
                                }
                            }} loading={printLoading}>Print</Button>
                        }
                        {invoice.status !== 6 &&
                            <SendEmailButton getEmailContent={async () => {
                                return await InvoiceAPI.getEmail(invoice.id);
                            }} getToList={async () => {
                                const customer = await CustomerAPI.get(invoice.customerId);
                                return customer.contacts;
                            }} sendEmail={async (val) => {
                                await InvoiceAPI.sendEmail(invoice.id, val);
                            }} />
                        }
                        <Button onClick={() => back()}>Back</Button>
                    </Space >
                    :
                    <Space>
                        <Button type="primary" onClick={() => save()}>Save</Button>
                        <Button onClick={() => setEditing(false)}>Cancel</Button>
                    </Space>
            }>
            {!editing ?
                <>
                    <Descriptions colon=":" layout="inline-horizontal" data={[
                        { label: "Customer", value: invoice.customerName },
                        { label: "Invoice Date", value: invoice.invoiceDate },
                        { label: "Address", value: invoice.address },
                        { label: "SO#", value: <SaleOrderLink soId={invoice.soId}>{invoice.soNo}</SaleOrderLink> },
                        { label: "Reference#", value: RefUtil.format(invoice.reference) },
                        { label: "Terms", value: invoice.termName },
                        { label: "Due Date", value: invoice.dueDate },
                        { label: "Total Amount", value: <UIText type="currency" value={invoice.totalAmount} /> },
                        {
                            label: "Payment Amount",
                            value: <PaymentLink invoiceId={invoice.id}>
                                <UIText type="currency" value={invoice.paidAmount} />
                            </PaymentLink>
                        },
                        { label: "Balance Due", value: <UIText type="currency" value={invoice.totalAmount - invoice.paidAmount} /> },
                        { label: "Memo", value: invoice.memo, span: 2 },
                    ]} />

                    <Tabs defaultActiveTab="1" type="card-gutter">
                        <TabPane key="1" title={
                            <Space size={80}>
                                <span>Items:</span>
                                <span>{NumericUtil.currency(invoice.totalItem)}</span>
                            </Space>
                        }>
                            <UITable columns={[
                                { title: "Item", dataIndex: "itemName" },
                                { title: "Description", dataIndex: "description" },
                                { title: "Qty", render: (col, record) => <UIText type="number" value={record.quantity} /> },
                                { title: "U/M", dataIndex: "unitName" },
                                { title: "Price", render: (col, record) => <UIText type="number" value={record.cost} /> },
                                { title: "Amount", render: (col, record) => <UIText type="currency" value={record.amount} /> },

                            ]} data={invoice.items} />

                        </TabPane>
                        <TabPane key="2" title={
                            <Space size={80}>
                                <span>Revenue:</span>
                                <span>{NumericUtil.currency(invoice.totalRevenue)}</span>
                            </Space>
                        }>
                            <UITable columns={[
                                { title: "Account", dataIndex: "accountName" },
                                { title: "Amount", render: (col, record) => <UIText type="currency" value={record.amount} /> },
                                { title: "Memo", dataIndex: "memo" },

                            ]} data={invoice.revenues} />
                        </TabPane>
                    </Tabs>
                </>
                :
                <>
                    <InvoiceForm ref={formRef} value={invoice} />
                </>
            }
        </Card >
    )
}
