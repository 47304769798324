import { useEffect, useState } from "react";
import { ItemAPI } from "@/apis";
import { UISelect } from "@/components/UI";

const UOMSelect = (props) => {
    const { type, ...restProps } = props;
    const [options, setOptions] = useState([]);

    useEffect(() => {
        const getOptions = async () => {
            const values = await ItemAPI.uoms();
            setOptions(values);
        }

        getOptions();
    }, [type]);

    return (
        <UISelect {...restProps}
            records={options}
            labelKey="name"
            onChange={(val) => props.onChange && props.onChange(val)}>
        </UISelect>
    );
}

export default UOMSelect;