import { Form, Input, Message, Modal } from "@arco-design/web-react";
import { useState } from "react";
import { CompanyAPI } from "@/apis";

const FormItem = Form.Item;

export const CompanyForm = (props) => {
    const [form] = Form.useForm();
    const [data, setData] = useState(props.data);

    const onSubmit = async () => {
        let values = await form.validate();
        if (!values.id) {
            await CompanyAPI.add(values);
            Message.success("Company created successfully!");
        } else {
            await CompanyAPI.update(values.id, values);
            Message.success("Company updated successfully!");
        }

        props.onSubmit && props.onSubmit();
    }

    return (
        <Modal title="Company"
            visible
            onOk={onSubmit}
            onCancel={props.onCancel}>
            <Form initialValues={data} form={form}
                onValuesChange={(_, values) => {
                    setData(values);
                }}
                layout="vertical">
                <FormItem label="ID" hidden field="id"><Input /></FormItem>
                <FormItem label="Company Name" field="companyName"
                    rules={[{ required: true }]}>
                    <Input />
                </FormItem>
            </Form>
        </Modal >
    );
}
