import { useEffect, useState } from "react";
import { Table } from "@arco-design/web-react";
import _ from "lodash";

const defaultPagination = {
    total: 0,
    pageSize: 20,
    current: 1
};

export const UITable = (props) => {
    const { rowKey, pageSize, total, ...restProps } = props;
    const [pagination, setPagination] = useState(defaultPagination);

    const changePage = (val) => {
        const { current, pageSize } = val;
        setPagination({ ...pagination, current, pageSize });
    }

    useEffect(() => {
        if (_.isNil(total)) {
            setPagination(false);
        } else {
            const size = pageSize || defaultPagination.pageSize;
            const val = {
                ...defaultPagination,
                size,
                total
            };
            setPagination(val);
        }
    }, [total, pageSize])

    return (
        <Table
            {...restProps}
            rowKey={rowKey ?? "id"}
            pagination={pagination}
            onChange={(pagination) => {
                changePage(pagination)
                props.onChange(pagination.current);
            }} />
    );
}

export * from "./editable-table";
