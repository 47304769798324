import { Button, Card, Descriptions, Message, Modal, Popconfirm, Space } from "@arco-design/web-react"
import { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom"
import { ItemReceiptAPI, VendorAPI } from "@/apis";
import { UITable, UIText, UITag } from "@/components/UI";
import { RefUtil } from "@/utils/reference";
import { ReceiptForm } from "./receipt-form";
import Address from "@/components/Address";
import SendEmailButton from "@/components/SendEmailButton";
import GLInfo from "@/pages/Home/Accounting/GL/gl-info";
import { FileUtil } from "@/utils";

export const ReceiptInfo = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [info, setInfo] = useState({});
    const [editing, setEditing] = useState(false);
    const formRef = useRef(null);
    const [glImpactVisible, setGLImpactVisible] = useState(false);
    const [printLoading, setPrintLoading] = useState(false);

    const getInfo = useCallback(async () => {
        const values = await ItemReceiptAPI.get(params.receiptId);
        setInfo(values);
    }, [params]);

    const back = () => {
        navigate("/workspace/purchasing/receipt");
    }

    const deleteReceipt = async () => {
        try {
            await ItemReceiptAPI.delete(params.receiptId);
            Message.success("Item receipt deleted successfully");
            back();
        } catch (error) {
            Message.error(error.message);
        }
    }

    useEffect(() => {
        getInfo();
    }, [getInfo]);

    return <Card title={<Space>
        <span>{info.receiptNo}</span>
        <UITag value={info.statusDesc} />
    </Space>} extra={editing
        ? <Space>
            <Button type="primary" onClick={async () => {
                const values = await formRef.current.getFormData();
                try {
                    await ItemReceiptAPI.update(values.id, values);
                    Message.success("Receipt updated successfuly");
                    getInfo();
                    setEditing(false);
                } catch (error) {
                    if (error.status === "908001") {
                        Message.error("Failed to update receipt because quantity is less than fulfilled!")
                    } else {
                        Message.error(error.message);
                    }
                }
            }}>Save</Button>
            <Button onClick={() => {
                setEditing(false);
            }}>Cancel</Button>
        </Space>
        : <Space>
            <Button type="primary" onClick={() => {
                setEditing(true);
            }}>Edit</Button>
            {info.status === 1 &&
                <Popconfirm title="Are you sure you want to delete?"
                    onOk={() => deleteReceipt(params.receiptId)}>
                    <Button type="primary" status="danger" >Delete Item Receipt</Button>
                </Popconfirm>
            }
            {info.status !== 3 &&
                <Button type="primary" onClick={() => navigate("/workspace/purchasing/bills/receipt/" + params.receiptId)}>Create Bill</Button>
            }
            <Button type="primary" onClick={() => setGLImpactVisible(true)}>GL Impact</Button>
            <Button type="primary" onClick={async () => {
                setPrintLoading(true);
                try {
                    const res = await ItemReceiptAPI.print(info.id);
                    FileUtil.download(res.data, `${info.poNo}.pdf`);
                } catch (error) {
                    Message.error(error.message);
                } finally {
                    setPrintLoading(false);
                }
            }} loading={printLoading}>Print</Button>
            <SendEmailButton getEmailContent={async () => {
                return await ItemReceiptAPI.getEmail(info.id);
            }} getToList={async () => {
                const vendor = await VendorAPI.get(info.vendorId);
                return vendor.contacts;
            }} sendEmail={async (val) => {
                await ItemReceiptAPI.sendEmail(info.id, val);
            }} />
            <Button onClick={() => back()}>Back</Button>
        </Space>
    }>
        {!editing
            ? <>
                <Descriptions colon=":"
                    data={[{
                        label: "Vendor",
                        value: info.vendorName
                    }, {
                        label: "Drop Ship",
                        value: info.dropShipName
                    }, {
                        label: "Ship to",
                        value: <Address value={{
                            name: info.shipToContactName,
                            address: info.shipToAddress,
                            city: info.shipToCity,
                            state: info.shipToState,
                            zip: info.shipToZip
                        }} inline />
                    }, {
                        label: "PO No",
                        value: <Link to={`/workspace/purchasing/po/${info.poId}`}>{info.poNo}</Link>
                    }, {
                        label: "Receive Date",
                        value: info.receiveDate
                    }, {
                        label: "Reference#",
                        value: RefUtil.format(info.reference)
                    }, {
                        label: "Warehouse Name",
                        value: info.warehouseName
                    }, {
                        label: "Memo",
                        value: info.memo
                    }]} />

                <UITable columns={[
                    { title: "Item Number", dataIndex: "itemNo" },
                    { title: "Item Name", dataIndex: "itemName" },
                    { title: "Description", dataIndex: "description" },
                    { title: "Qty", dataIndex: "quantity" },
                    { title: "U/M", dataIndex: "unitName" },
                    { title: "Rate", dataIndex: "rate" },
                    { title: "Amount", render: (col, record) => <UIText type="currency" value={record.amount} /> },
                    { title: "Location", dataIndex: "locationName" },
                    { title: "Lot#", dataIndex: "lot" },
                    { title: "Expiration Date", dataIndex: "expiredDate" },
                ]}
                    data={info.items}
                />
                <Modal title="GL Impact"
                    style={{ width: 800 }}
                    visible={glImpactVisible}
                    onCancel={() => setGLImpactVisible(false)}
                    onOk={() => setGLImpactVisible(false)}>
                    <GLInfo type="1" from={params.receiptId}
                    />
                </Modal>
            </>
            : <ReceiptForm ref={formRef} value={info} />
        }
    </Card>
}