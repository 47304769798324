import { Button, Form, Grid, Input, Modal, Select } from "@arco-design/web-react"
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import { UIInputNumber, UIDatePicker, UITable, UIText } from "@/components/UI";
import UOMSelect from "@/components/UOMSelect";
import { SOAPI, ItemReceiptAPI } from "@/apis";
import { IconDelete, IconPlus, IconZoomIn } from "@arco-design/web-react/icon";
import _ from "lodash";
import { NumericUtil } from "@/utils";

const FormItem = Form.Item;
const Row = Grid.Row;
const Col = Grid.Col;

export const FulfillmentForm = forwardRef((props, ref) => {
    const { value } = props;
    const [form] = Form.useForm();
    const [soItems, setSOItems] = useState();
    const [items, setItems] = useState();

    useImperativeHandle(ref, () => {
        return {
            getFormValues
        }
    })

    const getSOItems = useCallback(async () => {
        const so = await SOAPI.get(value.soId);
        setSOItems(so.items);
        let items = _.sortBy(so.items, "itemName");
        items = _.filter(items, (item) => item.itemType === 1);
        setItems(_.map(items, (item) => ({
            label: `${item.itemName}(${NumericUtil.currency(item.rate)})`,
            value: item.id
        })));
    }, [value])

    const onLotSelect = (field, lot) => {
        form.setFieldValue(`${field}.warehouseId`, lot.warehouseId);
        form.setFieldValue(`${field}.warehouseName`, lot.warehouseName);
        form.setFieldValue(`${field}.locationId`, lot.locationId);
        form.setFieldValue(`${field}.locationName`, lot.locationName);
        form.setFieldValue(`${field}.warehouseLocation`, `${lot.warehouseName ?? ""}-${lot.locationName ?? ""}`);
        form.setFieldValue(`${field}.lot`, lot.lot);
        form.setFieldValue(`${field}.expiredDate`, lot.expiredDate);
    }

    const getFormValues = async () => {
        const values = await form.validate();
        return values;
    }

    useEffect(() => {
        getSOItems();
        let values = { ...value };
        _.forEach(values.items, (item) => {
            item.warehouseLocation = `${item.warehouseName ?? ""}-${item.locationName ?? ""}`;
        })
        form.setFieldsValue(values);
    }, [form, value, getSOItems]);

    return (
        <>
            <Form form={form} colon
                labelCol={{ span: 0 }}
                wrapperCol={{ span: 24 }}>
                <FormItem>
                    <Row gutter={8}>
                        <Col span={8} offset={16}>

                        </Col>
                    </Row>
                    <Row gutter={8}>
                        <Col span={4}>
                            <FormItem field="id" hidden>
                                <Input />
                            </FormItem>
                            <FormItem field="soId" hidden>
                                <Input />
                            </FormItem>
                            <FormItem label="SO#" field="soNo" labelCol={{ span: 6 }}>
                                <Input readOnly />
                            </FormItem>
                        </Col>

                        <Col span={6}>
                            <FormItem label="SO Due Date" field="soDueDate" labelCol={{ span: 12 }}>
                                <Input readOnly />
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label="SO Creation Date" field="soCreateDate" labelCol={{ span: 12 }}>
                                <Input readOnly />
                            </FormItem>
                        </Col>
                        <Col span={8}>
                            <FormItem label="Fulfillment Date" field="fulfillmentDate" labelCol={{ span: 10 }}>
                                <UIDatePicker />
                            </FormItem>
                        </Col>
                    </Row>
                </FormItem>
                <FormItem label="Items" labelCol={{ span: 1 }}>
                    <Form.List field="items">
                        {(fields, { add, remove }) =>
                            <>
                                <table width="100%">
                                    <thead>
                                        <tr>
                                            <th>Line#</th>
                                            <th >Item Number</th>
                                            <th>Item Name</th>
                                            <th>Description</th>
                                            <th>PKS#</th>
                                            <th width="100">SO Qty</th>
                                            <th width="100">Qty to Fulfill</th>
                                            <th >UOM</th>
                                            <th width="150">Warehouse - Location</th>
                                            <th width="170">Lot#</th>
                                            <th width="95">Expiration</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {fields.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        <span>#{index + 1}</span>
                                                        <FormItem field={item.field + ".id"} hidden>
                                                            <Input />
                                                        </FormItem>
                                                    </td>
                                                    <td>
                                                        <FormItem field={item.field + ".itemId"} hidden>
                                                            <Input />
                                                        </FormItem>
                                                        <FormItem field={item.field + ".itemNo"} noStyle>
                                                            <Input readOnly />
                                                        </FormItem>
                                                    </td>
                                                    <td>
                                                        {form.getFieldValue(`${item.field}.sopoId`) == null ?
                                                            <FormItem field={item.field + ".soItemId"} noStyle>
                                                                <Select options={items}
                                                                    showSearch
                                                                    filterOption={(inputValue, option) => option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0}
                                                                    triggerProps={{
                                                                        autoAlignPopupWidth: false,
                                                                        autoAlignPopupMinWidth: true
                                                                    }}
                                                                    onChange={(val) => {
                                                                        const soItem = _.find(soItems, o => o.id === val);
                                                                        form.setFieldValue(`${item.field}.itemId`, soItem.itemId);
                                                                        form.setFieldValue(`${item.field}.itemNo`, soItem.itemNo);
                                                                        form.setFieldValue(`${item.field}.description`, soItem.description);
                                                                        form.setFieldValue(`${item.field}.soQty`, soItem.quantity);
                                                                        form.setFieldValue(`${item.field}.unit`, soItem.unit);
                                                                    }} />
                                                            </FormItem>
                                                            :
                                                            <>
                                                                <FormItem field={item.field + ".soItemId"} hidden>
                                                                    <Input />
                                                                </FormItem>
                                                                <FormItem field={item.field + ".itemName"} noStyle>
                                                                    <Input readOnly />
                                                                </FormItem>
                                                            </>
                                                        }
                                                    </td>
                                                    <td>
                                                        <FormItem field={item.field + ".description"} noStyle>
                                                            <Input readOnly />
                                                        </FormItem>
                                                    </td>
                                                    <td>
                                                        <FormItem field={item.field + ".sopoNo"} noStyle>
                                                            <Input readOnly />
                                                        </FormItem>
                                                        <FormItem field={item.field + ".sopoId"} hidden>
                                                            <Input readOnly />
                                                        </FormItem>
                                                        <FormItem field={item.field + ".sopoItemId"} hidden>
                                                            <Input readOnly />
                                                        </FormItem>
                                                    </td>
                                                    <td>
                                                        <FormItem field={item.field + ".soQty"} noStyle>
                                                            <UIInputNumber readOnly />
                                                        </FormItem>
                                                    </td>
                                                    <td>
                                                        <FormItem field={item.field + ".qtyFulfilled"} required rules={[{ required: true }]} noStyle>
                                                            <UIInputNumber readOnly={form.getFieldValue(`${item.field}.sopoId`) != null} />
                                                        </FormItem>
                                                    </td>
                                                    <td>
                                                        <FormItem field={item.field + ".unit"} noStyle>
                                                            <UOMSelect disabled />
                                                        </FormItem>
                                                    </td>
                                                    <td>
                                                        <FormItem field={item.field + "warehouseId"} hidden>
                                                            <Input />
                                                        </FormItem>
                                                        <FormItem field={item.field + "warehouseName"} hidden>
                                                            <Input />
                                                        </FormItem>
                                                        <FormItem field={item.field + ".locationId"} hidden>
                                                            <Input />
                                                        </FormItem>
                                                        <FormItem field={item.field + ".locationName"} hidden>
                                                            <Input />
                                                        </FormItem>
                                                        <FormItem field={item.field + ".warehouseLocation"} noStyle>
                                                            <Input />
                                                        </FormItem>
                                                    </td>
                                                    <td>
                                                        <FormItem field={item.field + ".receiptItemId"} noStyle>
                                                            <ReceiptSelect
                                                                readOnly={form.getFieldValue(`${item.field}.sopoId`) != null}
                                                                getItemId={() => {
                                                                    return form.getFieldValue(`${item.field}.itemId`);
                                                                }}
                                                                getQtyFulfilled={() => {
                                                                    return form.getFieldValue(`${item.field}.qtyFulfilled`);
                                                                }}
                                                                onSelect={(val) => onLotSelect(item.field, val)} />
                                                        </FormItem>
                                                    </td>
                                                    <td>
                                                        <FormItem field={item.field + ".expiredDate"} noStyle>
                                                            <Input readOnly />
                                                        </FormItem>
                                                    </td>
                                                    <td>
                                                        <FormItem hidden={form.getFieldValue(`${item.field}.sopoId`) != null}
                                                            noStyle={form.getFieldValue(`${item.field}.sopoId`) == null}>
                                                            <Button type="text" status="danger" icon={<IconDelete />}
                                                                onClick={() => remove(index)}></Button>
                                                        </FormItem>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                <FormItem>
                                    <Button type="text" icon={<IconPlus />} onClick={() => add()}>Add Item</Button>
                                </FormItem>
                            </>
                        }
                    </Form.List>
                </FormItem>
            </Form>
        </>
    )
})

const ReceiptSelect = ({ getItemId, getQtyFulfilled, onChange, onSelect, value, readOnly }) => {
    const [data, setData] = useState([]);
    const [visible, setVisible] = useState(false);
    const [lot, setLot] = useState();

    const getReceiptItem = useCallback(async () => {
        if (value) {
            const values = await ItemReceiptAPI.item(value);
            setLot(values.lot);
        }
    }, [value]);

    const getLots = useCallback(async () => {
        let itemId = getItemId();
        if (itemId) {
            const values = await ItemReceiptAPI.remaining(itemId);
            setData(values);
        }
    }, [getItemId])

    useEffect(() => {
        getReceiptItem();
    }, [getReceiptItem])

    return (
        <>
            <Input.Search readOnly searchButton={!readOnly} value={lot} onSearch={() => {
                if (readOnly) return;
                setVisible(true);
            }} />
            <Modal visible={visible} style={{ width: 800 }}
                afterOpen={getLots}
                closeIcon={false}
                footer={null}
                onCancel={() => setVisible(false)}>
                <UITable columns={[
                    { title: "Warehouse", dataIndex: "warehouseName" },
                    { title: "Location", dataIndex: "locationName" },
                    { title: "Qty", render: (col, record) => <UIText type="number" value={record.remainingQty} /> },
                    { title: "Lot#", dataIndex: "lot" },
                    { title: "Received Date", dataIndex: "receivedDate" },
                    { title: "Expiration Date", dataIndex: "expiredDate" },
                    {
                        title: "", width: 20, render: (col, record) => {
                            const qtyFulfilled = getQtyFulfilled();
                            if (record.remainingQty >= qtyFulfilled) {
                                return (
                                    <Button type="text" icon={<IconZoomIn />} onClick={() => {
                                        setVisible(false);
                                        onChange && onChange(record.id);
                                        onSelect && onSelect(record);
                                    }} />
                                )

                            }
                        }
                    },
                ]}
                    data={data}
                />
            </Modal>
        </>
    )
}