import { Card, Tabs } from "@arco-design/web-react";
import { UITable, UIText, UIInputNumber } from "@/components/UI";
import { useCallback, useEffect, useState } from "react";
import { ReportAPI } from "@/apis";
import _ from "lodash";

const TabPane = Tabs.TabPane;

export const SalesForecast = () => {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);

    const getSalesItems = useCallback(async () => {
        const res = await ReportAPI.getSalesItems(page);
        setData(res.records);
        setTotal(res.total);
    }, [page])

    const updateForecast = async (itemId, val) => {
        await ReportAPI.updateItemForecast(itemId, {
            forecast: val
        })
        let newData = _.slice(data);
        _.find(newData, o => o.itemId === itemId).forecast = val;
        setData(newData);
    }

    const updateQtyOnHand = async (itemId, val) => {
        await ReportAPI.updateItemForecast(itemId, {
            qtyOnHand: val
        })
        let newData = _.slice(data);
        _.find(newData, o => o.itemId === itemId).qtyOnHand = val;
        setData(newData);
    }

    useEffect(() => {
        getSalesItems();
    }, [getSalesItems])

    return (
        <Card title="Sales Forecast">
            <Tabs defaultActiveTab="1">
                <TabPane key="1" title="Sales Items">
                    <UITable columns={[{
                        title: "Part Number",
                        dataIndex: "itemNo"
                    }, {
                        title: "Part Name",
                        dataIndex: "itemName"
                    }, {
                        title: "Description",
                        dataIndex: "description"
                    }, {
                        title: "Forecast",
                        render: (col, record) => <UIInputNumber value={record.forecast} onBlur={(e) => updateForecast(record.itemId, e.target.value)} />
                    }, {
                        title: "Safety Level",
                        render: (col, record) => <UIText type="number" value={record.safetyLevel} />
                    }, {
                        title: "Qty Onhand",
                        render: (col, record) => <UIInputNumber value={record.qtyOnHand} onBlur={(e) => updateQtyOnHand(record.itemId, e.target.value)} />
                    }, {
                        title: "Open SO",
                        render: (col, record) => <UIText type="number" value={record.openSO} />
                    }, {
                        title: "Qty Available",
                        render: (col, record) => <UIText type="number" value={record.qtyAvailable} />
                    }, {
                        title: "Open PO/WO",
                        render: (col, record) => <UIText type="number" value={record.openPOWO} />
                    }, {
                        title: "Net Qty",
                        render: (col, record) => <UIText type="number" value={record.netQty} />
                    }]}
                        rowKey="itemId" data={data} total={total}
                        onChange={(val) => setPage(val)}
                    />
                </TabPane>
            </Tabs>
        </Card>
    )
}