import { Form, Input, Message, Modal } from "@arco-design/web-react";
import { useEffect, useState } from "react";
import { AccountAPI } from "@/apis";
import { UISelect } from "@/components/UI";

const DictSelect = UISelect.DictSelect;
const FormItem = Form.Item;

export const AccountForm = (props) => {
    const [form] = Form.useForm();
    const [defaultValue] = useState(props.data);

    const onSubmit = async () => {
        let formData = await form.validate();
        if (!formData.id) {
            await AccountAPI.add(formData);
            Message.success("Account created successfully");
        } else {
            await AccountAPI.update(formData.id, formData);
            Message.success("Account updated successfully");
        }

        props.onSubmit && props.onSubmit();
    }

    useEffect(() => {
    }, []);

    return (
        <Modal title={props.data.id ? "Account" : "Create New Account"} visible
            onOk={onSubmit}
            onCancel={props.onCancel}>
            <Form form={form}
                initialValues={defaultValue}
                labelCol={{ span: 7 }} wrapperCol={{ span: 16 }}>
                <FormItem label="ID" field="id" hidden>
                    <Input />
                </FormItem>
                <FormItem label="Company ID" field="companyId" hidden>
                    <Input />
                </FormItem>
                <FormItem label="Account Type" field="accountType">
                    <DictSelect type="account-type" />
                </FormItem>
                <FormItem label="Account ID" field="accountNo">
                    <Input />
                </FormItem>
                <FormItem label="Account Name" field="accountName" rules={[{ required: true, message: "Account name is required" }]}>
                    <Input readOnly={props.data && props.data.source !== 1} />
                </FormItem>
                <FormItem label="Description" field="description">
                    <Input.TextArea />
                </FormItem>
            </Form>
        </Modal >
    );
}