import { Select } from "@arco-design/web-react";
import { useCallback, useEffect, useState } from "react"
import { InventoryAPI } from "@/apis";
import _ from "lodash";

export const LotSelect = ({ itemId, onChange, ...props }) => {
    const [data, setData] = useState([]);

    const searchItem = useCallback(async () => {
        if (!itemId) {
            setData([]);
        } else {
            const result = await InventoryAPI.lots({ itemId });
            let lots = _.map(result, (item) => ({
                label: item.lot,
                value: item.lot
            }));
            lots = _.filter(lots, item => !_.isEmpty(item.value));
            setData(lots);
        }
    }, [itemId]);

    useEffect(() => {
        searchItem();
    }, [searchItem]);

    return (
        <Select {...props}
            onChange={onChange}
            showSearch
            filterOption={(inputValue, option) =>
                option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0}
            options={data}>
        </Select>
    )
}