import { useParams } from "react-router-dom";
import { SOPOAPI, SOAPI } from "@/apis";
import { UIText } from "@/components/UI";
import { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import "./print.css";

export const PickOrderInfoPrint = () => {
    const params = useParams();
    const [info, setInfo] = useState({});

    const getInfo = useCallback(async () => {
        const sopoInfo = await SOPOAPI.get(params.poId);
        const soInfo = await SOAPI.get(sopoInfo.soId);
        _.forEach(sopoInfo.items, (item) => {
            item.soItemQty = _.find(soInfo.items, (soItem) => soItem.id === item.soItemId).quantity;
        })
        setInfo(sopoInfo);
    }, [params]);

    useEffect(() => {
        getInfo();
    }, [getInfo])

    return (
        <div>
            <div className="print-container">
                <h2>SO Pick Order #{info.pickOrderNo}</h2>
                <table width="100%">
                    <tbody>
                        <tr>
                            <td><span className="print-field" width="50">SO#:</span>{info.soNo}</td>
                            <td><span className="print-field">Customer Name:</span>{info.customerName}</td>
                            <td><span className="print-field">SO Due Date:</span>{info.soDueDate}</td>
                            <td><span className="print-field">SO Creation Date:</span>{info.soCreateDate}</td>
                        </tr>
                        <tr>
                            <td colSpan={4}><span className="print-field">Address:</span>{info.address}</td>
                        </tr>
                    </tbody>
                </table>

                <table className="print-table" style={{ marginTop: 20 }}>
                    <thead>
                        <tr>
                            <th>Line#</th>
                            <th>Item Number</th>
                            <th>Item Name</th>
                            <th>Description</th>
                            <th>SO Qty</th>
                            <th>Pick Qty</th>
                            <th>UOM</th>
                            <th>Warehouse</th>
                            <th>Location</th>
                            <th>Lot#</th>
                            <th>Expiration</th>
                        </tr>
                    </thead>
                    <tbody>
                        {info.items && info.items.map((item, index) => (
                            <tr key={index}>
                                <td>#{index + 1}</td>
                                <td>{item.itemNo}</td>
                                <td>{item.itemName}</td>
                                <td>{item.description}</td>
                                <td><UIText type="number" value={item.soItemQty} /></td>
                                <td><UIText type="number" value={item.pickQty} /></td>
                                <td>{item.unitName}</td>
                                <td>{item.warehouseName}</td>
                                <td>{item.locationName}</td>
                                <td>{item.lot}</td>
                                <td>{item.expiredDate}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
