import { Form, Grid, Input, Upload, Typography, Button, Space, Message } from "@arco-design/web-react"
import { TransactionAPI } from "@/apis";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import { UISelect, UIInputNumber, UIDatePicker, UIText } from "@/components/UI";
import AccountSelect from "@/components/AccountSelect";
import TransactionCategorySelect from "@/components/TransactionCategorySelect";
import { baseUrl } from "@/utils/api";
import _ from "lodash";
import { IconDelete } from "@arco-design/web-react/icon";
import { FileUtil } from "@/utils";
import Decimal from "decimal.js";

const { Text } = Typography;

const Row = Grid.Row;
const Col = Grid.Col;
const FormItem = Form.Item;
const TextArea = Input.TextArea;

export const TransactionForm = forwardRef(({ value }, ref) => {
    const [form] = Form.useForm();
    const [imported, setImported] = useState(false);
    const [syncDate, setSyncDate] = useState();
    const [transId] = useState(value?.id);
    const [type, setType] = useState();
    const [amount, setAmount] = useState(value?.amount);
    const [receipts, setReceipts] = useState([]);

    const initForm = useCallback(async () => {
        form.clearFields();
        setType(value.type);
        if (value.category) {
            form.setFieldValue("transCategory", { category: value.category, categoryValue: value.categoryValue, valueType: value.categoryValueType })
        }

        if (value.id) {
            const values = await TransactionAPI.get(value.id);
            values.amount = Math.abs(values.amount);
            setImported(values.imported);
            setSyncDate(values.createTime);
            setReceipts(values.receipts);
            values.receiptFiles = _.map(values.receipts, o => {
                return {
                    id: o.id,
                    docId: o.docId,
                    uid: o.id,
                    name: o.document.docName
                }
            });
            _.forEach(values.splits, (o) => {
                o.amount = Math.abs(o.amount);
                o.splitTransactionCategory = {
                    category: o.category,
                    categoryValue: o.categoryValue,
                    valueType: o.categoryValueType
                }
            })
            form.setFieldsValue(values);
        } else {
            form.setFieldsValue(value);
        }
    }, [form, value])

    const updateReceipts = (file, fileList) => {
        const values = _.filter(fileList, (o) => o.percent === 100);
        const receipts = _.map(values, o => {
            if (o.id) {
                return {
                    id: o.id,
                    docId: o.docId
                }
            }

            return {
                docId: o.response.data.id
            }
        })
        setReceipts(receipts);
    }

    const getFormValues = async () => {
        const values = await form.validate();
        values.receipts = receipts;

        if (_.size(values.splits) === 1) {
            throw new Error("There must be at least two or more split transactions, please add more.");
        }

        let splitTotalAmount = new Decimal(0);
        for (const i in values.splits) {
            splitTotalAmount = splitTotalAmount.plus(values.splits[i].amount);
        }
        if (!splitTotalAmount.equals(0) && !splitTotalAmount.equals(values.amount)) {
            throw new Error("Total split amount must be equal than transaction's amount");
        }

        return values;
    }

    useImperativeHandle(ref, () => ({
        getFormValues
    }));

    useEffect(() => {
        initForm();
    }, [initForm]);

    return (
        <>
            <Form form={form} colon layout="vertical">
                <FormItem field="id" hidden>
                    <Input />
                </FormItem>
                <Row gutter={10}>
                    <Col span={12}>
                        <FormItem label="Date" field="date" required rules={[{ required: true }]}>
                            <UIDatePicker disabled={imported} />
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem label="Description" field="description">
                            <Input />
                        </FormItem>
                    </Col>
                </Row>
                <Row gutter={10}>
                    <Col span={12}>
                        {imported ?
                            <FormItem label="Account" field="accountName" required>
                                <Input readOnly />
                            </FormItem>
                            :
                            <FormItem label="Account" field="accountId" required rules={[{ required: true }]}>
                                <AccountSelect showSearch type={[5, 10]} />
                            </FormItem>
                        }
                    </Col>
                    <Col span={12}>
                        <FormItem label="Type" field="type" required rules={[{ required: true }]}>
                            <UISelect.DictSelect type="transaction-type" disabled />
                        </FormItem>
                    </Col>
                </Row>
                <Row gutter={10}>
                    <Col span={12}>
                        <FormItem label="Amount" field="amount" required rules={[{ required: true }]}>
                            <UIInputNumber disabled={imported} min={0} onChange={(val) => setAmount(val)} />
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem label="Category" field="transCategory">
                            <TransactionCategorySelect style={{ width: 250 }} placeholder="Please Select..."
                                type={type === 1 ? "income" : "expense"}
                                amount={amount}
                                transactionId={transId}
                                onChange={val => {
                                    form.setFieldValue("category", val.category);
                                    form.setFieldValue("categoryValue", val.categoryValue);
                                    form.setFieldValue("categoryValueType", val.categoryValueType);
                                }} />
                        </FormItem>
                        <FormItem field="category" hidden>
                            <Input />
                        </FormItem>
                        <FormItem field="categoryValue" hidden>
                            <Input />
                        </FormItem>
                        <FormItem field="categoryValueType" hidden>
                            <Input />
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span={20}>
                        <FormItem label="Split transaction">
                            <Form.List field="splits" label="Split transaction">
                                {(fields, { add, remove }) =>
                                    <>
                                        {fields.map((item, index) => {
                                            return (
                                                <Space key={index}>
                                                    <FormItem field={`${item.field}.amount`} label="Split amount" rules={[{ required: true }]}>
                                                        <UIInputNumber min={0} />
                                                    </FormItem>
                                                    <FormItem label="Category" style={{ width: 200 }} field={`${item.field}.splitTransactionCategory`}>
                                                        <TransactionCategorySelect type={type === 1 ? "income" : "expense"}
                                                            onChange={(val) => {
                                                                form.setFieldValue(`${item.field}.category`, val.category);
                                                                form.setFieldValue(`${item.field}.categoryValue`, val.categoryValue);
                                                                form.setFieldValue(`${item.field}.categoryValueType`, val.categoryValueType);
                                                            }} />
                                                    </FormItem>
                                                    <FormItem>
                                                        <Button style={{ marginTop: 30 }} type="text" status="danger" icon={<IconDelete />} onClick={() => remove(index)} />
                                                    </FormItem>
                                                    <FormItem field={`${item.field}.category`} hidden>
                                                        <Input />
                                                    </FormItem>
                                                    <FormItem field={`${item.field}.categoryValue`} hidden>
                                                        <Input />
                                                    </FormItem>
                                                    <FormItem field={`${item.field}.categoryValueType`} hidden>
                                                        <Input />
                                                    </FormItem>
                                                </Space>
                                            )
                                        })}
                                        <FormItem style={{ marginBottom: 0 }}>
                                            <Button type="outline" shape="round" size="mini" onClick={() => {
                                                const totalAmount = form.getFieldValue("amount");
                                                const splittedAmount = _.sumBy(form.getFieldValue("splits"), "amount");
                                                const amount = totalAmount - splittedAmount;
                                                add({
                                                    amount: amount.toFixed(5),
                                                    splitTransactionCategory: {
                                                        category: form.getFieldValue("category"),
                                                        categoryValue: form.getFieldValue("categoryValue"),
                                                        ValueType: form.getFieldValue("categoryValueType"),
                                                    },
                                                    category: form.getFieldValue("category"),
                                                    categoryValue: form.getFieldValue("categoryValue"),
                                                    categoryValueType: form.getFieldValue("categoryValueType"),
                                                })
                                            }}>Split transaction</Button>
                                        </FormItem>
                                    </>
                                }
                            </Form.List>
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <FormItem label="Notes" field="note">
                        <TextArea />
                    </FormItem>
                </Row>
                <Row>
                    <FormItem label="Receipt" field="receiptFiles"
                        triggerPropName="fileList">
                        <Upload
                            withCredentials={true}
                            drag
                            multiple
                            name="file"
                            headers={{ 'falcontoken': localStorage.getItem("login.token") }}
                            action={baseUrl + "/transaction/receipt"}
                            onChange={(fileList, file) => {
                                updateReceipts(file, fileList);
                            }}
                            onRemove={(file, fileList) => {
                                updateReceipts(file, fileList);
                            }}
                            showUploadList={{
                                removeIcon: <IconDelete style={{ color: 'var(--color-danger-light-4)' }} />,
                                fileName: (file) => (
                                    <Button size="small" type="text" onClick={() => {
                                        try {
                                            const res = TransactionAPI.downloadReceipt(file.docId)
                                            FileUtil.download(res.data, file.name);
                                        } catch (error) {
                                            Message.error(error.message);
                                        }
                                    }}>{file.name}</Button>
                                )
                            }}
                        />
                    </FormItem>
                </Row>
                {imported &&
                    <Row>
                        <Text>Synced at&nbsp;
                            <UIText type="datetime" value={syncDate} />
                        </Text>
                    </Row>
                }
            </Form>
        </>
    )
})