import { Button, Card, Message, Modal, Space } from "@arco-design/web-react";
import { UITable, UISelect, UIText } from "@/components/UI";
import { useCallback, useEffect, useRef, useState } from "react";
import { SOAPI } from "@/apis";
import { SaleOrderForm } from "./so-form";
import { SaleOrderInfo } from "./so-info";
import SaleOrderLink from "@/components/SaleOrderLink";
import { useNavigate } from "react-router-dom";
import { DateUtil } from "@/utils";

export const SaleOrder = () => {
    const [status, setStatus] = useState('Open');
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const navigate = useNavigate();

    const getSOs = useCallback(async () => {
        let statusList;
        switch (status) {
            case 'Open':
                statusList = [1, 2, 3, 4, 5, 6, 7, 8, 11];
                break;
            case 'Closed':
                statusList = [9, 10, 12];
                break;
            case 'Short Closed':
                statusList = 6;
                break;
            case 'Voided':
                statusList = 10;
                break;
            case 'Created':
                statusList = 1;
                break;
            case 'Issued':
                statusList = 2;
                break;
            case 'Picked':
                statusList = 3;
                break;
            case 'Partially Fulfilled':
                statusList = 4;
                break;
            case 'Partially Fulfilled Invoiced':
                statusList = 5;
                break;
            case 'Fulfilled':
                statusList = 7;
                break;
            case 'Fulfilled Partially Invoiced':
                statusList = 8;
                break;
            case 'Fulfilled Invoiced':
                statusList = 9;
                break;
            case 'Partial Invoiced':
                statusList = 11;
                break;
            case 'Invoiced':
                statusList = 12;
                break;
            default:
                statusList = null;
        }
        const values = await SOAPI.query(page, { status: statusList });
        setData(values.records);
        setTotal(values.total);

    }, [status, page]);

    useEffect(() => {
        getSOs();
    }, [getSOs]);

    return (
        <Card title="Sales Order" extra={
            <Space>
                <UISelect value={status} options={[
                    "Open",
                    "Closed",
                    "Short Closed",
                    "Voided",
                    "Created",
                    "Issued",
                    "Picked",
                    "Partially Fulfilled",
                    "Partially Fulfilled Invoiced",
                    "Fulfilled",
                    "Fulfilled Partially Invoiced",
                    "Fulfilled Invoiced",
                    "Paritial Invoiced",
                    "Invoiced",
                ]} allowAll placeholder="Status" style={{ width: 200 }}
                    onChange={(val) => {
                        setStatus(val);
                    }} />
                <AddSOButton onOk={(id) => navigate(`/workspace/sales/so/${id}`)} />
            </Space>
        }>
            <UITable columns={[
                {
                    title: "SO#",
                    render: (col, record) =>
                        <SaleOrderLink soId={record.id}>{record.soNo}</SaleOrderLink>
                },
                { title: "Status", dataIndex: "statusName" },
                { title: "Customer", dataIndex: "customerName" },
                { title: "SO Date", dataIndex: "soDate" },
                { title: "Due Date", dataIndex: "dueDate" },
                { title: "Total Amount", render: (col, record) => <UIText type="currency" value={record.totalAmount} /> },
            ]} total={total} data={data} onChange={pageNum => setPage(pageNum)}
            />
        </Card>
    );
}

const AddSOButton = ({ onOk }) => {
    const [visible, setVisible] = useState(false);
    const [so, setSO] = useState();
    const formRef = useRef(null);

    const submit = async () => {
        try {
            const values = await formRef.current.getFormData();
            const id = await SOAPI.add(values);
            Message.success("Sale Order created successfully");
            setVisible(false);
            onOk && onOk(id);
        } catch (error) {
            Message.error(error.message);
        }
    }

    return (
        <>
            <Button type="primary" onClick={() => {
                setSO({
                    soDate: DateUtil.today(),
                });
                setVisible(true);
            }}>Create New SO</Button>
            <Modal title="Create New SO" visible={visible} style={{ width: 1000 }}
                onCancel={() => {
                    setVisible(false);
                }}
                onOk={async () => submit()}>
                <SaleOrderForm value={so} ref={formRef} />
            </Modal>
        </>
    )
}

SaleOrder.Info = SaleOrderInfo;