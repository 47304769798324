import { Card } from "@arco-design/web-react";
import { PickOrderAdd } from "./pick-order-add";
import { PickOrderInfo } from "./pick-order-info";
import { PickOrderInfoPrint } from "./pick-order-print";
import { UITable } from "@/components/UI";
import { Link } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { SOPOAPI } from "@/apis";
import SaleOrderLink from "@/components/SaleOrderLink";

export const SOPickOrder = () => {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);

    const getList = useCallback(async () => {
        const values = await SOPOAPI.query(page);
        setData(values.records);
        setTotal(values.total);
    }, [page]);

    useEffect(() => {
        getList();
    }, [getList]);

    return (
        <Card title="Pick Order">
            <UITable columns={[
                {
                    title: "Pick Order#", dataIndex: "pickOrderNo",
                    render: (col, record) =>
                        <Link to={"/workspace/sales/so-pick-order/" + record.id}>{record.pickOrderNo}</Link>
                },
                { title: "Status", dataIndex: "statusDesc" },
                { title: "Customer Name", dataIndex: "customerName" },
                { title: "Fulfillment#", dataIndex: "fulfillment.soFulfillmentNo" },
                {
                    title: "SO#",
                    render: (col, record) =>
                        <SaleOrderLink soId={record.soId}>{record.soNo}</SaleOrderLink>
                },
                { title: "SO Due Date", dataIndex: "soDueDate" },
                { title: "SO Creation Date", dataIndex: "soCreateDate" },
            ]} data={data} total={total} onChange={(val) => setPage(val)} />
        </Card>
    )
}

SOPickOrder.Add = PickOrderAdd;
SOPickOrder.Info = PickOrderInfo;
SOPickOrder.Print = PickOrderInfoPrint;