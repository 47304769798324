import { Button, Card, Message, Space } from "@arco-design/web-react"
import { useRef } from "react"
import { JournalEntryForm } from "./journal-entry-form"
import { useNavigate } from "react-router-dom"

export const JournalEntryAdd = () => {
    const navigate = useNavigate();
    const formRef = useRef(null);

    const save = async () => {
        const result = await formRef.current.submit();
        if (result) {
            Message.success("Journal entry created successfully");
            navigate("/workspace/accounting/journal-entry");
        }
    }

    return (
        <Card title="Add New Journal Entry" extra={
            <Space>
                <Button type="primary" onClick={save}>Save</Button>
            </Space>
        }>
            <JournalEntryForm ref={formRef} />
        </Card>
    )
}