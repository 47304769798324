import { Grid, Layout, Statistic, Typography } from "@arco-design/web-react"
import { Header } from '@/components/Header';
import { useLogin } from '@/pages/Login/useLogin';
import { IconCheck } from "@arco-design/web-react/icon";
import "./index.css";
const { Title, Paragraph } = Typography;
const { Row, Col } = Grid;

export const Pricing = () => {
    useLogin(true)

    return (
        <div className="container">
            <Layout>
                <Layout.Header>
                    <Header />
                </Layout.Header>
                <Layout.Content style={{ "paddingTop": "65px", marginLeft: 100, marginRight: 100 }}>
                    <div style={{ width: 1300 }}>
                        <Title>Pricing</Title>
                        <Row justify="center">
                            <Col span={4}>
                                <Paragraph className="pricing-header">
                                    <Statistic value="Free" />
                                </Paragraph>
                                <Paragraph className="pricing-content">
                                    <ul style={{ listStyle: "none" }}>
                                        <li>
                                            <IconCheck />Revenue &lt;500k
                                        </li>
                                        <li>
                                            <IconCheck />All Features
                                        </li>
                                    </ul>
                                </Paragraph>
                            </Col>
                            <Col span={4}>
                                <Paragraph className="pricing-header">
                                    <Statistic value="$19.99" suffix="User/Month" />
                                </Paragraph>
                                <Paragraph className="pricing-content">
                                    <ul style={{ listStyle: "none" }}>
                                        <li>
                                            <IconCheck />Revenue 500k - 2m
                                        </li>
                                        <li>
                                            <IconCheck />All Features
                                        </li>
                                        <li>
                                            <IconCheck />Customization
                                        </li>
                                    </ul>
                                </Paragraph>
                            </Col>
                            <Col span={4}>
                                <Paragraph className="pricing-header">
                                    <Statistic value="$39.99" suffix="User/Month" />
                                </Paragraph>
                                <Paragraph className="pricing-content">
                                    <ul style={{ listStyle: "none" }}>
                                        <li>
                                            <IconCheck />Revenue 2m - 5m
                                        </li>
                                        <li>
                                            <IconCheck />All Features
                                        </li>
                                        <li>
                                            <IconCheck />Customization
                                        </li>
                                    </ul>
                                </Paragraph>
                            </Col>
                            <Col span={4}>
                                <Paragraph className="pricing-header">
                                    <Statistic value="$59.99" suffix="User/Month" />
                                </Paragraph>
                                <Paragraph className="pricing-content">
                                    <ul style={{ listStyle: "none" }}>
                                        <li>
                                            <IconCheck />Revenue &gt; 5m
                                        </li>
                                        <li>
                                            <IconCheck />All Features
                                        </li>
                                        <li>
                                            <IconCheck />Customization
                                        </li>
                                    </ul>
                                </Paragraph>
                            </Col>
                        </Row>

                    </div>
                </Layout.Content>
            </Layout>
        </div >
    )
}
