import { Grid } from "@arco-design/web-react"
import { CompanyInfo } from "./company-info";
import { PersonInfo } from "./person-info";
import { Users } from "./users";
import { EmailTemplates } from "./email-templates";
import { useEffect, useState } from "react";
import { CompanyAPI } from "@/apis";

const Row = Grid.Row;
const Col = Grid.Col;

export const CompanyProfile = () => {
    const [companyInfo, setCompanyInfo] = useState({});

    useEffect(() => {
        const getCompanyInfo = async () => {
            const values = await CompanyAPI.info();
            setCompanyInfo(values);
        }
        getCompanyInfo();
    }, [])

    return (
        <div>
            <Row style={{ marginBottom: 16 }}>
                <Col span={24}>
                    <CompanyInfo value={companyInfo} />
                </Col>
            </Row>
            <Row style={{ marginBottom: 16 }}>
                <Col span={24}>
                    <PersonInfo value={companyInfo.companyEmail} />
                </Col>
            </Row>
            <Row style={{ marginBottom: 16 }}>
                <Col span={24}>
                    <Users />
                </Col>
            </Row>
            <Row style={{ marginBottom: 16 }}>
                <Col span={24}>
                    <EmailTemplates value={companyInfo} />
                </Col>
            </Row>
        </div>
    )
}