import { Button, Form, Grid, Input, InputNumber, Space, Tabs } from "@arco-design/web-react"
import { ItemReceiptAPI, VendorAPI, ItemAPI, POAPI, BillAPI } from "@/apis";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import { UIText, UISelect, UIDatePicker, UIInputNumber } from "@/components/UI";
import { IconDelete, IconPlus } from "@arco-design/web-react/icon";
import { DateUtil } from "@/utils";
import AccountSelect from "@/components/AccountSelect";
import VendorSelect from "@/components/VendorSelect";
import _ from "lodash";
import { TermUtil } from "@/utils/term";

const Row = Grid.Row;
const Col = Grid.Col;
const FormItem = Form.Item;
const TextArea = Input.TextArea;
const TabPane = Tabs.TabPane;
const DictSelect = UISelect.DictSelect;

export const BillForm = forwardRef((props, ref) => {
    const { value, ...restProps } = props;
    const [form] = Form.useForm();
    const [poList, setPOList] = useState();
    const [totalExpenses, setTotalExpenses] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [items, setItems] = useState([]);
    const [receiptItemQuantityAndAmount, setReceiptItemQuantityAndAmount] = useState([]);

    const getPOList = useCallback(async () => {
        if (!value.receiptId) {
            const unbilledPOs = await POAPI.getNotBilled();
            setPOList(unbilledPOs);
        }
    }, [value])
    const onVendorChanged = async (val) => {
        const vendor = await VendorAPI.get(val);
        form.setFieldValue("vendorAddress", `${vendor.billingAddress}, ${vendor.billingCity}, ${vendor.billingState} ${vendor.billingZip}`);
        if (vendor.term) {
            const billDate = form.getFieldValue("billDate");
            const dueDate = TermUtil.dueDate(billDate, vendor.term);
            form.setFieldValue("term", vendor.term);
            form.setFieldValue("dueDate", DateUtil.format(dueDate));
        }
    }

    const onReceiptChanged = useCallback(async (receiptId) => {
        var items = [];
        if (receiptId) {
            const receipt = await ItemReceiptAPI.get(receiptId);
            form.setFieldValue("receiptNo", receipt.receiptNo);
            form.setFieldValue("poNo", receipt.poNo);

            const grouped = _.chain(receipt.items)
                .groupBy("itemId")
                .map((value, key) => {
                    return {
                        key,
                        quantity: _.sumBy(value, 'quantity'),
                        amount: _.sumBy(value, 'amount')
                    }
                })
                .value();
            setReceiptItemQuantityAndAmount(grouped);

            items = _.concat(items, receipt.items);
        } else {
            const nonInventoryItems = await ItemAPI.getNonInventoryItems();
            items = _.concat(items, _.map(nonInventoryItems, (item) => {
                return {
                    itemId: item.id,
                    itemName: item.itemName,
                    itemNo: item.itemNo,
                    unit: item.unit,
                    unitName: item.unitDesc,
                    rate: item.stdCost,
                    description: item.description
                }
            }));
        }

        setItems(items);
    }, [form]);

    const onItemChanged = async (index, val) => {
        const vendorId = form.getFieldValue("vendorId");
        const billItem = await BillAPI.getVendorLastItem(vendorId, val);
        const item = _.find(items, o => o.itemId === val);
        if (item) {
            form.setFieldValue(`items[${index}].description`, item.description);
            form.setFieldValue(`items[${index}].unit`, item.unit);
            form.setFieldValue(`items[${index}].unitName`, item.unitName);
            // form.setFieldValue(`items[${index}].quantity`, item.quantity);
            form.setFieldValue(`items[${index}].cost`, billItem ? billItem.cost : item.rate);
            // form.setFieldValue(`items[${index}].amount`, item.amount);
            // calcItemAmount(index);
        }
    }

    const calcDueDate = () => {
        const date = form.getFieldValue("billDate");
        const term = form.getFieldValue("term");

        form.setFieldValue("dueDate", DateUtil.format(TermUtil.dueDate(date, term)));
    }

    const calcTotalExpense = () => {
        const expenseItems = form.getFieldValue("expenses");
        const total = _.sumBy(expenseItems, item => item.amount);
        setTotalExpenses(total);
    }

    const calcItemAmount = (index) => {
        const updateItem = form.getFieldValue(`items[${index}]`);
        const amount = updateItem.quantity * updateItem.cost;
        form.setFieldValue(`items[${index}].amount`, amount);
        const items = form.getFieldValue("items");
        const total = _.sumBy(items, item => item.amount);
        setTotalItems(total);
    }

    const calcTotalAmount = useCallback(() => {
        const total = totalItems + totalExpenses;
        form.setFieldValue("totalAmount", total);
    }, [form, totalItems, totalExpenses]);

    const submit = async () => {
        const values = await form.validate();

        if (values.receiptId) {
            const grouped = _.chain(values.items)
                .groupBy("itemId")
                .map((value, key) => {
                    return {
                        key,
                        quantity: _.sumBy(value, 'quantity'),
                        amount: _.sumBy(value, 'amount')
                    }
                })
                .value();

            _.forEach(grouped, o => {
                const item = _.find(receiptItemQuantityAndAmount, item => item.key === o.key);
                if (item.quantity !== o.quantity || item.amount !== o.amount) {
                    throw new Error("The total Qty AND Amount of each item needs to equal the Qty and Amount from the item receipt.");
                }
            });
        }

        return values;
    }

    const init = useCallback(async () => {
        onReceiptChanged(value.receiptId);
        form.setFieldsValue(value);

        setTotalItems(_.sumBy(value.items, (o) => o.amount));
        setTotalExpenses(_.sumBy(value.expenses, (o) => o.amount));
    }, [form, value, onReceiptChanged]);

    useImperativeHandle(ref, () => ({ submit }));

    useEffect(() => {
        getPOList();
    }, [getPOList]);

    useEffect(() => {
        init();
    }, [init]);

    useEffect(() => {
        calcTotalAmount();
    }, [calcTotalAmount]);

    return (
        <Form {...restProps}
            form={form} colon
            labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
            <FormItem field="id" hidden>
                <Input readOnly />
            </FormItem>
            <Row gutter={12}>
                <Col span={10}>
                    <FormItem label="Vendor" field="vendorId" required rules={[{ required: true }]}>
                        <VendorSelect onChange={(val) => onVendorChanged(val)} />
                    </FormItem>
                </Col>
                <Col span={8}>
                    <FormItem label="Bill Date" field="billDate" required rules={[{ required: true }]}
                        labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                        <UIDatePicker onChange={() => calcDueDate()} />
                    </FormItem>
                </Col>
                <Col span={6}>
                    <FormItem label="Paid Date" field="processDate"
                        labelCol={{ span: 10 }} wrapperCol={{ span: 14 }}>
                        <UIDatePicker />
                    </FormItem>
                </Col>
            </Row>
            <Row gutter={12}>
                <Col span={10}>
                    <FormItem label="Address" field="vendorAddress">
                        <Input />
                    </FormItem>
                </Col>
                <Col span={8}>
                    <FormItem label="Item Receipt#" field="receiptId"
                        hidden>
                        <Input readOnly />
                    </FormItem>
                    <FormItem label="Item Receipt#" field="receiptNo"
                        hidden={value.receiptId == null}
                        labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                        <Input readOnly />
                    </FormItem>
                    {value.receiptId == null
                        ? <FormItem label="PO#" field="poId"
                            labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                            <UISelect records={poList} labelKey="poNo" allowClear />
                        </FormItem>
                        : <FormItem label="PO#" field="poNo"
                            labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                            <Input readOnly />
                        </FormItem>
                    }
                </Col>
                <Col span={6}>
                    <FormItem label="Invoice#" field="invoiceNo" required rules={[{ required: true }]}
                        labelCol={{ span: 10 }} wrapperCol={{ span: 14 }}>
                        <Input />
                    </FormItem>
                </Col>
            </Row>
            <Row gutter={12}>
                <Col span={10}>
                    <FormItem label="Reference#" field="reference">
                        <Form.List field="reference">
                            {(fields, { add, remove, move }) =>
                                <>
                                    {fields.map((item, index) => {
                                        return (
                                            <FormItem key={item.key} style={{ marginBottom: 0 }}>
                                                <Space className="form-item-row">
                                                    <FormItem field={item.field + ".type"}>
                                                        <DictSelect type="reference-type" order="label" style={{ width: 200 }} showSearch />
                                                    </FormItem>
                                                    <FormItem field={item.field + ".number"}>
                                                        <Input />
                                                    </FormItem>
                                                    <Button type="text" status="danger" icon={<IconDelete />} onClick={() => remove(index)}></Button>
                                                </Space>
                                            </FormItem>
                                        )
                                    })}
                                    <Button type="text" icon={<IconPlus />} onClick={() => add()}>Add Reference</Button>
                                </>
                            }
                        </Form.List>
                    </FormItem>
                </Col>
                <Col span={8}>
                    <FormItem label="Terms" field="term" required rules={[{ required: true }]}
                        labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                        <DictSelect type="term" onChange={(val) => {
                            calcDueDate();
                        }} />
                    </FormItem>
                </Col>
                <Col span={6}>
                    <FormItem label="Due Date" field="dueDate" required rules={[{ required: true }]}
                        labelCol={{ span: 10 }} wrapperCol={{ span: 14 }}>
                        <UIDatePicker />
                    </FormItem>
                </Col>
            </Row>
            <Row>
                <Col span={18}>
                    <FormItem label="Memo" field="memo" labelCol={{ span: 3 }} wrapperCol={{ span: 21 }}>
                        <TextArea />
                    </FormItem>
                </Col>
                <Col span={6}>
                    <FormItem label="Total Amount" field="totalAmount"
                        labelCol={{ span: 10 }} wrapperCol={{ span: 14 }}>
                        <Input readOnly />
                    </FormItem>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Tabs defaultActiveTab="2" type="card-gutter" lazyload={false}>
                        <TabPane key="1" title={
                            <Space size={80}>
                                <span>Expenses</span>
                                <UIText type="currency" value={totalExpenses} />
                            </Space>
                        }>
                            <Form.List field="expenses" >
                                {(fields, { add, remove, move }) =>
                                    <div style={{ marginLeft: 10, marginRight: 10 }}>
                                        <table width="100%">
                                            <thead>
                                                <tr>
                                                    <th width={280}>Account</th>
                                                    <th width={200}>Amount</th>
                                                    <th>Memo</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {fields.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>
                                                                <FormItem field={`${item.field}.id`} hidden>
                                                                    <Input />
                                                                </FormItem>
                                                                <FormItem field={`${item.field}.accountId`} noStyle required rules={[{ required: true }]}>
                                                                    <AccountSelect category={2} />
                                                                </FormItem>
                                                            </td>
                                                            <td>
                                                                <FormItem field={`${item.field}.amount`} noStyle required rules={[{ required: true }]}>
                                                                    <UIInputNumber onChange={(val) => calcTotalExpense()} />
                                                                </FormItem>
                                                            </td>
                                                            <td>
                                                                <FormItem field={`${item.field}.memo`} noStyle required rules={[{ required: true }]}>
                                                                    <Input />
                                                                </FormItem>
                                                            </td>
                                                            <td>
                                                                <FormItem noStyle>
                                                                    <Button type="text" status="danger" icon={<IconDelete />}
                                                                        onClick={() => {
                                                                            remove(index);
                                                                            calcTotalExpense();
                                                                        }}></Button>
                                                                </FormItem>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                        <FormItem>
                                            <Button type="text" icon={<IconPlus />} onClick={() => add()} size="mini">Add Expense</Button>
                                        </FormItem>
                                    </div>
                                }
                            </Form.List>
                        </TabPane>
                        <TabPane key="2" title={
                            <Space size={80}>
                                <span>Items</span>
                                <UIText type="currency" value={totalItems} />
                            </Space>
                        }>
                            <Form.List field="items" >
                                {(fields, { add, remove, move }) =>
                                    <div style={{ marginLeft: 10, marginRight: 10 }}>
                                        <table width="100%">
                                            <thead>
                                                <tr>
                                                    <th>Item</th>
                                                    <th>Description</th>
                                                    <th width={120}>Qty</th>
                                                    <th width={80}>U/M</th>
                                                    <th width={120}>Cost</th>
                                                    <th width={150}>Amount</th>
                                                    <td width={20}></td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {fields.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>
                                                                <FormItem field={`${item.field}.id`} hidden>
                                                                    <Input />
                                                                </FormItem>
                                                                <FormItem field={`${item.field}.itemId`} noStyle required rules={[{ required: true }]}>
                                                                    <UISelect records={items} valueKey="itemId" labelKey="itemName" onChange={(val) => onItemChanged(index, val)} />
                                                                </FormItem>
                                                            </td>
                                                            <td>
                                                                <FormItem field={`${item.field}.description`} noStyle required rules={[{ required: true }]}>
                                                                    <Input />
                                                                </FormItem>
                                                            </td>
                                                            <td>
                                                                <FormItem field={`${item.field}.quantity`} noStyle required rules={[{ required: true }]}>
                                                                    <InputNumber onChange={() => calcItemAmount(index)} />
                                                                </FormItem>
                                                            </td>
                                                            <td>
                                                                <FormItem field={`${item.field}.unit`} hidden>
                                                                    <Input />
                                                                </FormItem>
                                                                <FormItem field={`${item.field}.unitName`} noStyle required rules={[{ required: true }]}>
                                                                    <Input readOnly />
                                                                </FormItem>
                                                            </td>
                                                            <td>
                                                                <FormItem field={`${item.field}.cost`} noStyle required rules={[{ required: true }]}>
                                                                    <UIInputNumber onChange={() => calcItemAmount(index)} />
                                                                </FormItem>
                                                            </td>
                                                            <td>
                                                                <FormItem field={`${item.field}.amount`} noStyle required rules={[{ required: true }]}>
                                                                    <InputNumber readOnly />
                                                                </FormItem>
                                                            </td>
                                                            <td>
                                                                <FormItem noStyle>
                                                                    <Button type="text" status="danger" icon={<IconDelete />}
                                                                        onClick={() => remove(index)}></Button>
                                                                </FormItem>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                        <FormItem>
                                            <Button type="text" icon={<IconPlus />} onClick={() => add()} size="mini">Add Item</Button>
                                        </FormItem>
                                    </div>
                                }
                            </Form.List>
                        </TabPane>
                    </Tabs>
                </Col>
            </Row>
        </Form >
    )
})