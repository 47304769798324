import { Button, Card, Form, Input, Message, Modal } from "@arco-design/web-react"
import { UserAPI } from "@/apis";

const FormItem = Form.Item;

export const ChangePassword = () => {
    const [form] = Form.useForm();

    const changePassword = async (val) => {
        try {
            await UserAPI.changePassword({ oldPassword: val.oldPassword, newPassword: val.newPassword });
            Message.success("Password updated successfully");
        } catch (error) {
            if (error.status === "902001") {
                Message.error("Old password is wrong!");
            } else {
                Message.error(error.message);
            }
        }
    }

    const onSubmit = (val) => {
        Modal.confirm({
            title: "Confirm",
            content: "Are you sure to update password?",
            onOk: () => {
                changePassword(val);
                form.clearFields();
            }
        });
    }

    return (
        <Card title="Change Password">
            <Form colon form={form} onSubmit={onSubmit} style={{ width: 500 }} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} >
                <FormItem label="Old Password" field="oldPassword" required rules={[{ required: true }]}>
                    <Input.Password />
                </FormItem>

                <FormItem label="New Password" field="newPassword" required rules={[{ required: true }, {
                    validator: (v, cb) => {
                        if (!/^(?![^a-zA-Z]+$)(?!\\D+$).{6,32}$/.test(v)) {
                            return cb("The password must be at least 6 characters and less than 32 characters with a mixture of numbers and letters");
                        }
                        cb(null);
                    }
                }]}>
                    <Input.Password />
                </FormItem>

                <FormItem label="Confirm Password" field="confirmPassword" required
                    dependencies={['newPassword']}
                    rules={[{
                        validator: (v, cb) => {
                            if (!v) {
                                return cb('Confirm password is required')
                            } else if (form.getFieldValue('newPassword') !== v) {
                                return cb('Confirm password must be equal with password')
                            }
                            cb(null)
                        }
                    }]}>
                    <Input.Password />
                </FormItem>

                <FormItem wrapperCol={{ offset: 8 }}>
                    <Button type="primary" htmlType="submit">Submit</Button>
                </FormItem>
            </Form>
        </Card>
    )
}