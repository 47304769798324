import { Button, Form, Grid, Input, Space } from "@arco-design/web-react";
import { IconDelete, IconPlus } from "@arco-design/web-react/icon";
import { UISelect } from "@/components/UI";
import Status from "@/components/Status";
import { forwardRef, useEffect, useImperativeHandle } from "react";

const FormItem = Form.Item;
const Row = Grid.Row;
const Col = Grid.Col;

export const VendorForm = forwardRef(({ value }, ref) => {
    const [form] = Form.useForm();

    const copyAddressFromBilling = () => {
        const billingAddress = form.getFieldValue("billingAddress");
        const billingCity = form.getFieldValue("billingCity");
        const billingState = form.getFieldValue("billingState");
        const billingZip = form.getFieldValue("billingZip");
        return {
            address: billingAddress,
            city: billingCity,
            state: billingState,
            zip: billingZip
        }
    }

    const getFormData = async () => {
        return await form.validate();
    }

    useEffect(() => {
        form.setFieldsValue(value);
    }, [form, value]);


    useImperativeHandle(ref, () => ({
        getFormData
    }))

    return (
        <Form form={form}
            colon
            labelAlign="left"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
        >
            <FormItem label="ID" field="id" hidden>
                <Input />
            </FormItem>
            <Row>
                <Col span={12}>
                    {!value.id &&
                        <FormItem
                            required rules={[{ required: true }]}
                            labelCol={{ span: 7 }}
                            wrapperCol={{ span: 17 }}
                            label="Status" field="status">
                            <Status.Select />
                        </FormItem>
                    }
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <FormItem
                        labelCol={{ span: 7 }}
                        wrapperCol={{ span: 17 }}
                        label="Vendor Name" field="vendorName" rules={[{ required: true }]} >
                        <Input />
                    </FormItem>
                </Col>
                <Col span={12}>
                    <FormItem label="Term" field="term" labelAlign="right">
                        <UISelect.DictSelect type="term" />
                    </FormItem>
                </Col>
            </Row>
            <FormItem layout="vertical" label="Main Contact" required>
                <Form.List field="contacts">
                    {(fields, { add, remove, move }) => {
                        return (
                            <>
                                {fields.map((item, index) =>
                                    <Space key={item.key} className="form-item-row">
                                        <FormItem field={item.field + ".id"} hidden>
                                            <Input />
                                        </FormItem>
                                        <FormItem field={item.field + ".firstName"} label="First name" noStyle={{ showErrorTip: true }} rules={[{ required: true }]}>
                                            <Input placeholder="First Name" />
                                        </FormItem>
                                        <FormItem field={item.field + ".lastName"} label="Last name" noStyle={{ showErrorTip: true }} rules={[{ required: true }]}>
                                            <Input placeholder="Last Name" />
                                        </FormItem>
                                        <FormItem field={item.field + ".tags"} label="Tag" noStyle={{ showErrorTip: true }} rules={[{ required: true }]}>
                                            <UISelect.DictMultiSelect type="tag" placeholder="Tags" style={{ width: 280 }} />
                                        </FormItem>
                                        <FormItem field={item.field + ".email"} label="Email" noStyle={{ showErrorTip: true }} rules={[{ required: true }, { type: "email", message: "Invalid email type" }]}>
                                            <Input placeholder="Email" style={{ width: 280 }} />
                                        </FormItem>
                                        <FormItem field={item.field + ".phone"} label="Phone" noStyle={{ showErrorTip: true }}>
                                            <Input placeholder="Phone" />
                                        </FormItem>
                                        <Button icon={<IconDelete />} type="text" status="danger"
                                            onClick={() => remove(index)}></Button>
                                    </Space>
                                )}

                                <Button type="text" icon={<IconPlus />} onClick={() => add()}>Add more...</Button>
                            </>
                        )
                    }}
                </Form.List>
            </FormItem>
            <FormItem layout="vertical" label="Billing" required>
                <Space>
                    <FormItem hidden></FormItem>
                    <FormItem field="billingAddress" label="Address" noStyle={{ showErrorTip: true }} rules={[{ required: true }]}>
                        <Input placeholder="Address" style={{ width: 480 }} />
                    </FormItem>
                    <FormItem field="billingCity" label="City" noStyle={{ showErrorTip: true }} rules={[{ required: true }]}>
                        <Input placeholder="City" />
                    </FormItem>
                    <FormItem field="billingState" label="State" noStyle={{ showErrorTip: true }} rules={[{ required: true }]}>
                        <Input placeholder="State" />
                    </FormItem>
                    <FormItem field="billingZip" label="Zip" noStyle={{ showErrorTip: true }} rules={[{ required: true }]}>
                        <Input placeholder="Zip" />
                    </FormItem>
                </Space>
            </FormItem>
            <FormItem layout="vertical" label="Shipping" required>
                <Form.List field="shipAddresses">
                    {(fields, { add, remove, move }) => {
                        return (
                            <>
                                <Button type="text" onClick={() => {
                                    const address = copyAddressFromBilling();
                                    remove(0);
                                    add(address);
                                }}>Copy from Billing</Button>
                                {fields.map((item, index) =>
                                    <Space key={item.key} className="form-item-row">
                                        <FormItem field={item.field + ".id"} hidden><Input /></FormItem>
                                        <FormItem field={item.field + ".contactName"} rules={[{ required: true, message: "Name is required" }]}>
                                            <Input placeholder="Ship to Name" />
                                        </FormItem>
                                        <FormItem field={item.field + ".address"} rules={[{ required: true, message: "Address is required" }]}>
                                            <Input placeholder="Address" style={{ width: 320 }} />
                                        </FormItem>
                                        <FormItem field={item.field + ".city"} rules={[{ required: true, message: "City is required" }]}>
                                            <Input placeholder="City" />
                                        </FormItem>
                                        <FormItem field={item.field + ".state"} rules={[{ required: true, message: "State is required" }]}>
                                            <Input placeholder="State" />
                                        </FormItem>
                                        <FormItem field={item.field + ".zip"} rules={[{ required: true, message: "Zip is required" }]}>
                                            <Input placeholder="Zip" />
                                        </FormItem>
                                        <Button icon={<IconDelete />} type="text" status="danger"
                                            onClick={() => remove(index)}></Button>
                                    </Space>
                                )}
                                <Button type="text" icon={< IconPlus />} onClick={() => add()}>Add more...</Button>
                            </>
                        )
                    }}
                </Form.List>
            </FormItem>
        </Form>
    );
})