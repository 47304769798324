import { TransactionAPI, GLAPI, JournalAPI } from "@/apis";
import { UIText } from "@/components/UI";
import { Grid, Link, Modal, Typography, Table } from "@arco-design/web-react";
import { useState } from "react";

const Row = Grid.Row;
const Col = Grid.Col;

const AccountTransactionLink = (props) => {
    const { accountId, startDate, endDate } = props;

    const [visible, setVisible] = useState(false);

    const [transactionData, setTransactionData] = useState([]);
    const [transactionPagination, setTransactionPagination] = useState();

    const [glData, setGLData] = useState([]);
    const [glPagination, setGLPagination] = useState();

    const [journalEntryData, setJournalEntryData] = useState([]);
    const [journalEntryPagination, setJournalEntryPagination] = useState();

    const getTransactions = async (page) => {
        const values = await TransactionAPI.query(page, { accountIds: [accountId], startDate: startDate, endDate: endDate });
        setTransactionData(values.records);
        setTransactionPagination((pagination) => ({ ...pagination, current: page, total: values.total }));
    }

    const getGL = async (page) => {
        const values = await GLAPI.query(page, null, null, { accountId: accountId, startDate: startDate, endDate: endDate })
        setGLData(values.records);
        setGLPagination((pagination) => ({ ...pagination, current: page, total: values.total }));
    }

    const getJournalEntries = async (page) => {
        const values = await JournalAPI.details(page, { accountId: accountId, startDate: startDate, endDate: endDate })
        setJournalEntryData(values.records);
        setJournalEntryPagination((pagination) => ({ ...pagination, current: page, total: values.total }));
    }

    const openGLOrder = (gl) => {
        let url;
        switch (gl.impactType) {
            case 1:
                url = `/workspace/purchasing/receipt/${gl.impactFrom}`;
                break;
            case 2:
                url = `/workspace/purchasing/bills/${gl.impactFrom}`;
                break;
            case 3:
                url = `/workspace/sales/so-fulfillment/${gl.impactFrom}`;
                break;
            case 4:
                url = `/workspace/sales/invoice/${gl.impactFrom}`;
                break;
            default:
                throw Error("Invalid GL Impact Type");
        }

        const w = window.open('_blank');
        w.location.href = url;
    }

    const getDetails = async () => {
        getTransactions();
        getGL(1);
        getJournalEntries(1);
    };

    return (
        <>
            <Link onClick={() => {
                setVisible(!visible);
            }}>{props.children}</Link>
            <Modal visible={visible} style={{ width: 800 }}
                cancelText={false}
                afterOpen={() => getDetails()}
                onCancel={() => setVisible(false)}
                onOk={() => setVisible(false)}>
                <Row>
                    <Col span={24}>
                        <Typography.Title heading={6}>Transactions</Typography.Title>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Table columns={[
                            { title: "Date", dataIndex: "date" },
                            { title: "Description", dataIndex: "description" },
                            {
                                title: "Amount", render: (col, record) =>
                                    <UIText type="currency" value={record.amount} />
                            },
                        ]}
                            data={transactionData} pagination={transactionPagination} rowKey="id"
                            onChange={(pagination) => {
                                getTransactions(pagination.current);
                            }} />
                    </Col>
                </Row>
                <Row style={{ marginTop: 15 }}>
                    <Col span={24}>
                        <Typography.Title heading={6}>GL Impact</Typography.Title>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Table columns={[
                            { title: "Date", dataIndex: "impactDate" },
                            {
                                title: "Order#", render: (col, record) =>
                                    <Link onClick={() => openGLOrder(record)}>{record.orderNo}</Link>
                            },
                            {
                                title: "Amount", render: (col, record) =>
                                    <UIText type="currency" value={record.totalAmount} />
                            },
                        ]}
                            data={glData} pagination={glPagination} rowKey="id"
                            onChange={(pagination) => {
                                getGL(pagination.current);
                            }} />
                    </Col>
                </Row>
                <Row style={{ marginTop: 15 }}>
                    <Col span={24}>
                        <Typography.Title heading={6}>Journal Entry</Typography.Title>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Table columns={[
                            { title: "Date", dataIndex: "date" },
                            {
                                title: "Debit", render: (col, record) =>
                                    <UIText type="currency" value={record.debit} />
                            },
                            {
                                title: "Credit", render: (col, record) =>
                                    <UIText type="currency" value={record.credit} />
                            },
                        ]}
                            data={journalEntryData} pagination={journalEntryPagination} rowKey="id"
                            onChange={(pagination) => {
                                getJournalEntries(pagination.current);
                            }} />
                    </Col>
                </Row>
            </Modal>
        </>
    )
}

export default AccountTransactionLink;