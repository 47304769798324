import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { SOAPI, SOPOAPI, SOFulfillmentAPI, ItemReceiptAPI } from "@/apis";
import { useCallback, useEffect, useRef, useState } from "react";
import { DateUtil } from "@/utils";
import { FulfillmentForm } from "./fulfillment-form";
import { Alert, Button, Card, Message, Space } from "@arco-design/web-react";
import _ from "lodash";

export const FulfillmentAdd = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [searchParams] = useSearchParams();
    const [value, setValue] = useState();
    const ref = useRef(null);

    const loadFulfillment = useCallback(async () => {
        const so = await SOAPI.get(params.soId);
        let items = _.filter(so.items, (item) => item.itemType === 1);
        let fulfillmentItems = _.map(items, (item) => {
            return {
                soItemId: item.id,
                itemId: item.itemId,
                itemName: item.itemName,
                itemNo: item.itemNo,
                description: item.description,
                soQty: item.quantity,
                qtyFulfilled: item.quantity,
                unit: item.unit,
                unitName: item.unitName
            }
        });

        const fulfilledItems = await SOAPI.fulfillmentItems(params.soId);
        const itemFulfilledQty = _.chain(fulfilledItems)
            .groupBy('itemId')
            .reduce((result, value, key) => {
                result[key] = result[key] ?? 0 + _.sumBy(value, 'qtyFulfilled');
                return result;
            }, {})
            .value() || [];

        fulfillmentItems.forEach(item => {
            item.qtyFulfilled -= itemFulfilledQty[item.itemId] ?? 0;
        });


        let pickOrders = await SOPOAPI.getUnfulfilledBySO(params.soId);
        const sopoId = searchParams.get("sopoId");
        if (sopoId) {
            pickOrders = _.filter(pickOrders, (o) => o.id === parseInt(sopoId));
        }
        const pickOrderItems = _.chain(pickOrders)
            .flatMap('items')
            .map((item) => {
                return {
                    soItemId: item.soItemId,
                    itemId: item.itemId,
                    itemName: item.itemName,
                    itemNo: item.itemNo,
                    description: item.description,
                    sopoId: item.soPickOrderId,
                    sopoNo: _.find(pickOrders, o => o.id === item.soPickOrderId).pickOrderNo,
                    sopoItemId: item.id,
                    soQty: _.find(items, o => o.itemId === item.itemId).quantity,
                    qtyFulfilled: item.pickQty,
                    unit: item.unit,
                    unitName: item.unitName,
                    warehouseId: _.find(pickOrders, o => o.id === item.soPickOrderId).warehouseId,
                    warehouseName: _.find(pickOrders, o => o.id === item.soPickOrderId).warehouseName,
                    locationId: item.locationId,
                    locationName: item.locationName,
                    lot: item.lot,
                    expiredDate: item.expiredDate,
                    receiptItemId: item.receiptItemId
                }
            })
            .value();
        const itemPickedQty = _.chain(pickOrderItems)
            .groupBy('itemId')
            .reduce((result, value, key) => {
                result[key] = result[key] ?? 0 + _.sumBy(value, 'qtyFulfilled');
                return result;
            }, {})
            .value() || [];
        for (const item of fulfillmentItems) {
            item.qtyFulfilled -= itemPickedQty[item.itemId] ?? 0;
            if (item.qtyFulfilled <= 0)
                continue;
            const inventoryItems = await ItemReceiptAPI.remaining(item.itemId);
            for (const inventoryItem of inventoryItems) {
                if (inventoryItem.availableQty >= item.qtyFulfilled) {
                    item.warehouseId = inventoryItem.warehouseId;
                    item.warehouseName = inventoryItem.warehouseName;
                    item.locationId = inventoryItem.locationId;
                    item.locationName = inventoryItem.locationName;
                    item.lot = inventoryItem.lot;
                    item.expiredDate = inventoryItem.expiredDate;
                    item.receiptItemId = inventoryItem.id;
                    break;
                }
            }
        };

        if (sopoId) {
            fulfillmentItems = pickOrderItems;
        } else {
            fulfillmentItems = _.concat(pickOrderItems, fulfillmentItems);
        }

        let fulfillment = {
            soId: so.id,
            soNo: so.soNo,
            soDueDate: so.dueDate,
            soCreateDate: so.soDate,
            fulfillmentDate: DateUtil.today(),
            items: _.sortBy(_.filter(fulfillmentItems, item => item.qtyFulfilled > 0), "itemName")
        }
        setValue(fulfillment);
    }, [params, searchParams])

    const save = async () => {
        try {
            const values = await ref.current.getFormValues();
            const id = await SOFulfillmentAPI.add(values);
            Message.success("SO fulfillment created successfully");
            navigate(`/workspace/sales/so-fulfillment/${id}`);
        } catch (error) {
            Message.error(error.message);
        }
    }


    useEffect(() => {
        loadFulfillment();
    }, [loadFulfillment])

    return (
        <>
            <Alert style={{ marginBottom: 10 }} content="Psst: Items from Pick Orders can not be changed on fulfillment page. To make any changes of items from Pick Orders, go back to Pick Order, make the changes, and refulfill the Pick Order." />

            <Card title="SO Fulfillment" extra={
                <Space>
                    <Button type="primary" onClick={() => save()}>Save</Button>
                    <Link to={`/workspace/sales/so/${params.soId}`}>
                        <Button>Cancel</Button>
                    </Link>
                </Space>
            }>
                {value &&
                    <FulfillmentForm ref={ref} value={value} />
                }
            </Card>
        </>
    )
}