import { Button, Card, Space } from "@arco-design/web-react";
import { UITable, UISelect, UIText } from "@/components/UI";
import { BillAPI } from "@/apis";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BillAdd } from "./bill-add";
import { BillInfo } from "./bill-info";

export const Bill = () => {
    const [page, setPage] = useState(1);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [status, setStatus] = useState("Open");

    const getBills = useCallback(async () => {
        let statusList;
        switch (status) {
            case 'Open':
                statusList = [1, 2, 4, 5];
                break;
            case 'Current':
                statusList = 1;
                break;
            case 'Current Partial':
                statusList = 2;
                break;
            case 'Paid':
                statusList = 3;
                break;
            case 'Past Due':
                statusList = 4;
                break;
            case 'Past Due Partial':
                statusList = 5;
                break;
            case 'Voided':
                statusList = 6;
                break;
            default:
                break;
        }
        const values = await BillAPI.query(page, { status: statusList });
        setData(values.records);
        setTotal(values.total);
    }, [page, status]);

    useEffect(() => {
        getBills();
    }, [getBills])

    return (
        <Card title="Bills" extra={
            <Space>
                <UISelect value={status}
                    options={["Open", "Current", "Current Partial", "Paid", "Past Due", "Past Due Partial", "Voided"]}
                    allowAll placeholder="Status" style={{ width: 100 }} onChange={(val) => {
                        setStatus(val);
                    }} />
                <Link to={"/workspace/purchasing/bills/blank"}>
                    <Button type="primary">Add New Bill</Button>
                </Link>
            </Space>
        }>
            <UITable columns={[
                {
                    title: "Bill#", render: (col, record, index) => {
                        return (
                            <Link to={"/workspace/purchasing/bills/" + record.id}>{record.billNo}</Link>
                        )
                    }
                },
                { title: "Status", dataIndex: "statusDesc" },
                { title: "Bill Date", dataIndex: "billDate" },
                { title: "Due Date", dataIndex: "dueDate" },
                { title: "Vendor", dataIndex: "vendorName" },
                { title: "Total Amount", render: (col, record) => <UIText type="currency" value={record.totalAmount} /> },
                { title: "Balance", render: (col, record) => <UIText type="currency" value={record.totalAmount - record.paidAmount} /> },
                { title: "Invoice#", dataIndex: "invoiceNo" },
                { title: "Paid Date", dataIndex: "processDate" },
                { title: "Clear Date", dataIndex: "clearDate" },
            ]} data={data} total={total} onChange={(val) => setPage(val)} />
        </Card>
    );

};

Bill.Add = BillAdd;
Bill.Info = BillInfo;