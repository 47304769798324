import { Card, Space, Select, Table } from "@arco-design/web-react";
import { useCallback, useEffect, useState } from "react";
import { CompanyAPI, BankAPI } from "@/apis";
import _ from "lodash";

export const BankAudit = () => {
    const [companies, setCompanies] = useState();
    const [audits, setAudits] = useState();
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [companyId, setCompanyId] = useState();

    const getCompanies = useCallback(async () => {
        const companyList = await CompanyAPI.active();
        setCompanies(_.map(companyList, (item) => ({
            label: item.companyName,
            value: item.id
        })));
    }, [])

    const getBankAudits = useCallback(async () => {
        const res = await BankAPI.getAudits({ page: page, companyId: companyId });
        setAudits(res.records);
        setTotal(res.total);
    }, [page, companyId]);

    useEffect(() => {
        getCompanies();
    }, [getCompanies]);

    useEffect(() => {
        getBankAudits();
    }, [getBankAudits]);

    return (
        <Card title="Bank Audit" extra={
            <Space>
                <Select options={companies}
                    style={{ width: 200 }}
                    placeholder="Company"
                    allowClear
                    showSearch
                    filterOption={(inputValue, option) =>
                        option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                    }
                    onChange={(val) => { setCompanyId(val); }}></Select>
            </Space>
        }>
            <Table columns={[{
                title: "Company Name", dataIndex: "companyName"
            }, {
                title: "Bank", dataIndex: "institutionName"
            }, {
                title: "Access Token", dataIndex: "accessToken"
            }, {
                title: "Create Time", dataIndex: "createTime"
            }, {
                title: "Delete Time", dataIndex: "deleteTime"
            }, {
                title: "Request ID", dataIndex: "itemRemoveRequestId"
            }]} rowKey="id" data={audits} total={total} onChange={(val) => setPage(val)} />
        </Card>
    )
}
