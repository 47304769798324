import {
    Form,
    Input,
    Button,
    Space,
    AutoComplete,
} from '@arco-design/web-react';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateUserInfo } from '@/store/userInfoSlice';
import { UserAPI } from '@/apis';
import { loginStorage } from './loginStorage';

const defaultLoginParams = {}

export const LoginForm = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();

    const [form] = Form.useForm();
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [userOptions, setUserOptions] = useState([]);

    const afterLoginSuccess = useCallback(async () => {
        let userInfo = await UserAPI.isLogged();
        if (userInfo == null) {
            return;
        }
        loginStorage.setLoginUser(userInfo.userName);
        dispatch(updateUserInfo(userInfo));

        if (userInfo.isAdmin) {
            navigate('/admin');
        } else {
            const path = searchParams.get("callback");
            if (path) {
                navigate(path);
                return;
            }
            navigate('/workspace');
        }
    }, [dispatch, navigate, searchParams]);

    const login = async (params) => {
        setErrorMessage('');
        setLoading(true);

        try {
            let token = await UserAPI.login(params.userName, params.password);
            localStorage.setItem("login.token", token);
            afterLoginSuccess();
        } catch {
            setErrorMessage("Username or Password is incorrect");
        } finally {
            setLoading(false);
        }
    }

    const onSubmitClick = async () => {
        let values = await form.validate();
        login(values);
    }

    useEffect(() => {
        const users = loginStorage.getLoginUsers();
        setUserOptions(users);
        // if (users && users.length > 0) {
        //     form.setFieldValue("userName", users[0]);
        // }
    }, [form])

    useEffect(() => {
        afterLoginSuccess();
    }, [afterLoginSuccess]);


    return (
        <div className='login-form-wrapper'>
            <div className='login-form-title'>Login to FalconSys</div>
            <div className='login-form-sub-title'>
            </div>
            <div className='login-form-error-msg'>{errorMessage}</div>
            <Form
                className='login-form'
                layout="vertical"
                form={form}
                initialValues={defaultLoginParams}
            >
                <Form.Item
                    field="userName"
                    rules={[{ required: true, message: 'Username cannot be empty' }]}
                >
                    <AutoComplete
                        allowClear
                        placeholder="Username"
                        data={userOptions}
                        onPressEnter={onSubmitClick}
                    />
                </Form.Item>
                <Form.Item
                    field="password"
                    rules={[{ required: true, message: "Password cannot be empty" }]}
                >
                    <Input.Password
                        placeholder='Password'
                        onPressEnter={onSubmitClick}
                    />
                </Form.Item>
                <Space size={16} direction="vertical">
                    <Button type="primary" long onClick={onSubmitClick} loading={loading}>
                        Login
                    </Button>
                    <Link to={`/forgot-password`}>Forgot your password?</Link>
                </Space>
            </Form>
        </div>);
}