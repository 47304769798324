import { Button, Card, Descriptions, Space, Message, Popconfirm, Alert } from "@arco-design/web-react"
import { useNavigate, useParams } from "react-router-dom";
import { SOFulfillmentAPI, SOAPI, CustomerAPI } from "@/apis";
import { UITable, UIText, UITag } from "@/components/UI";
import { useCallback, useEffect, useRef, useState } from "react";
import { FulfillmentForm } from "./fulfillment-form";
import { FileUtil } from "@/utils";
import GLImpactButton from "@/components/GLImpactButton";
import SaleOrderLink from "@/components/SaleOrderLink";
import SendEmailButton from "@/components/SendEmailButton";
import _ from "lodash";

export const FulfillmentInfo = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [info, setInfo] = useState({});
    const [so, setSO] = useState();
    const [editing, setEditing] = useState(false);
    const [printLoading, setPrintLoading] = useState(false);
    let ref = useRef(null)

    const back = () => {
        navigate("/workspace/sales/so-fulfillment");
    }

    const getInfo = useCallback(async () => {
        const values = await SOFulfillmentAPI.get(params.fulfillmentId);
        setInfo(values);

        const so = await SOAPI.get(values.soId);
        setSO(so);
    }, [params]);

    const voidFulfillment = async () => {
        try {
            await SOFulfillmentAPI.voidFulfillment(params.fulfillmentId);
            Message.success("Fulfillment voided successfully");
            getInfo();
        } catch (err) {
            Message.error(err.message);
        }
    }

    const update = async () => {
        try {
            const values = await ref.current.getFormValues();
            await SOFulfillmentAPI.update(values.id, values);
            Message.success("SO fulfillment updated successfully");
            getInfo();
            setEditing(false);
        } catch (error) {
            Message.error(error.message);
        }
    }

    useEffect(() => {
        getInfo();
    }, [getInfo])

    return (
        <>
            <Alert style={{ marginBottom: 10 }} content="Psst: Items from Pick Orders can not be changed on fulfillment page. To make any changes of items from Pick Orders, go back to Pick Order, make the changes, and refulfill the Pick Order." />
            <Card title={
                <Space>
                    <span>SO Fulfillment #{info.soFulfillmentNo}</span>
                    <UITag value={info.statusDesc} />
                </Space>
            } extra={
                !editing
                    ? <Space>
                        {so?.status < 9 && info.status < 4 &&
                            <Button type="primary" onClick={() => {
                                navigate(`/workspace/sales/invoice/so/${so.id}?fulfillmentId=${info.id}`);
                            }}>Create Invoice</Button>
                        }
                        {info.status < 4 &&
                            <Button type="primary" onClick={() => setEditing(true)}>Edit</Button>
                        }
                        {info.status !== 4 &&
                            <Popconfirm title="Are you sure you want to void fulfillment?"
                                onOk={() => voidFulfillment()}>
                                <Button type="primary" status="danger">Void</Button>
                            </Popconfirm>
                        }
                        {info.status < 4 &&
                            <GLImpactButton type="3" from={params.fulfillmentId} />
                        }
                        {info.status !== 4 &&
                            <Button type="primary" onClick={async () => {
                                setPrintLoading(true);
                                try {
                                    const res = await SOFulfillmentAPI.print(info.id);
                                    FileUtil.download(res.data, `${info.soFulfillmentNo}.pdf`);
                                } catch (error) {
                                    Message.error(error.message);
                                } finally {
                                    setPrintLoading(false);
                                }
                            }} loading={printLoading}>Print</Button>
                        }
                        {info.status !== 4 &&
                            <SendEmailButton getEmailContent={async () => {
                                return await SOFulfillmentAPI.getEmail(info.id);
                            }} getToList={async () => {
                                const customer = await CustomerAPI.get(so.customerId);
                                return customer.contacts;
                            }} sendEmail={async (val) => {
                                await SOFulfillmentAPI.sendEmail(info.id, val);
                            }} />
                        }
                        <Button onClick={() => back()}>Back</Button>
                    </Space>
                    : <Space>
                        <Button type="primary" onClick={() => update()}>Save</Button>
                        <Button onClick={() => setEditing(false)}>Cancel</Button>
                    </Space>
            }>
                {!editing ? <>
                    <Descriptions colon=":"
                        data={[{
                            label: "SO#",
                            value: <SaleOrderLink soId={info.soId}>{info.soNo}</SaleOrderLink>
                        }, {
                            label: "SO Due Date",
                            value: info.soDueDate
                        }, {
                            label: "SO Creation Date",
                            value: info.soCreateDate
                        }, {
                            label: "Pick Order#",
                            value: _.join(_.map(info.pickOrders, "pickOrderNo"), ", ")
                        }, {
                            label: "Fulfillment Date",
                            value: info.fulfillmentDate
                        }]} />

                    <UITable columns={[
                        {
                            title: "Line#", render: (col, record, index) => {
                                return <span>{index + 1}</span>
                            }
                        },
                        { title: "Item Number", dataIndex: "itemNo" },
                        { title: "Item Name", dataIndex: "itemName" },
                        { title: "Description", dataIndex: "description" },
                        { title: "SO Qty", render: (col, record) => <UIText type="number" value={record.soQty} /> },
                        { title: "Qty Fulfilled", render: (col, record) => <UIText type="number" value={record.qtyFulfilled} /> },
                        { title: "UOM", dataIndex: "unitName" },
                        { title: "Warehouse", dataIndex: "warehouseName" },
                        { title: "Location", dataIndex: "locationName" },
                        { title: "Lot#", dataIndex: "lot" },
                        { title: "Expiration", dataIndex: "expiredDate" },
                    ]}
                        data={info.items}
                    />
                </>
                    : <FulfillmentForm ref={ref} value={info} />
                }
            </Card >
        </>
    )
}