import { Button, Card, Message, Space } from "@arco-design/web-react"
import { SOAPI, InvoiceAPI, SOFulfillmentAPI } from "@/apis";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { InvoiceForm } from "./invoice-form";
import _ from "lodash";
import { DateUtil } from "@/utils";

export const InvoiceAdd = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [searchParams] = useSearchParams();
    const soId = params.soId;
    const formRef = useRef();
    const [invoice, setInvoice] = useState();
    const [fulfillmentIds, setFulfillmentIds] = useState([]);
    const [saving, setSaving] = useState(false);

    const save = async () => {
        try {
            setSaving(true);
            const values = await formRef.current.getFormData();
            values.fulfillmentIds = fulfillmentIds;
            const invoiceId = await InvoiceAPI.add(values);
            Message.success("Invoice created successfully");
            navigate(`/workspace/sales/invoice/${invoiceId}`);
        } catch (error) {
            Message.error(error.message);
        } finally {
            setSaving(false);
        }
    }

    useEffect(() => {
        const init = async () => {
            const so = await SOAPI.get(soId);
            const invoice = {
                customerId: so.customerId,
                soId: so.id,
                soNo: so.soNo,
                invoiceDate: DateUtil.today(),
                reference: so.reference,
                revenues: [],
            }

            const fulfillmentIds = searchParams.getAll("fulfillmentId");
            setFulfillmentIds(fulfillmentIds);

            let inventoryItems = [];
            if (fulfillmentIds && fulfillmentIds.length > 0) {
                for (const index in fulfillmentIds) {
                    const fulfillment = await SOFulfillmentAPI.get(fulfillmentIds[index]);
                    const items = _.map(fulfillment.items, (o) => ({
                        itemId: o.itemId,
                        itemName: o.itemName,
                        description: o.description,
                        quantity: o.qtyFulfilled,
                        unit: o.unit,
                        cost: _.find(so.items, (soItem) => soItem.itemId === o.itemId).rate
                    }));
                    inventoryItems = _.concat(inventoryItems, items);
                    invoice.invoiceDate = fulfillment.fulfillmentDate;
                }

                _.forEach(inventoryItems, (o) => {
                    o.amount = o.quantity * o.cost;
                })
            } else {
                inventoryItems = _.map(_.filter(so.items, (item) => item.itemType === 1), (v) => ({
                    itemId: v.itemId,
                    itemName: v.itemName,
                    description: v.description,
                    quantity: v.quantity,
                    unit: v.unit,
                    cost: v.rate,
                    amount: v.amount
                }));
            }

            // Non-Inventory Items
            let invoicedItems = await InvoiceAPI.getItemsBySoId(soId);
            invoicedItems = _.reduce(_.groupBy(invoicedItems, 'itemId'), (result, val, key) => {
                result[key] = _.sumBy(val, 'quantity');
                return result;
            }, {})

            let nonInventoryItems = await SOAPI.getNonInventoryItems(soId);
            nonInventoryItems = _.map(nonInventoryItems, (item) => ({
                itemId: item.itemId,
                itemName: item.itemName,
                description: item.description,
                quantity: item.quantity,
                unit: item.unit,
                cost: item.rate,
                amount: item.amount
            }));
            _.forEach(nonInventoryItems, (item) => {
                item.quantity -= invoicedItems[item.itemId] ?? 0;
                item.amount = item.quantity * item.cost;
            });
            nonInventoryItems = _.filter(nonInventoryItems, (item) => item.quantity > 0);

            let totalItems = _.concat(inventoryItems, nonInventoryItems);
            invoice.items = _.sortBy(totalItems, "itemName");
            setInvoice(invoice);
        }

        init();
    }, [soId, searchParams]);

    return (
        <Card title="Invoice" extra={
            <Space>
                <Button type="primary" onClick={() => save()} loading={saving}>Save</Button>
                <Link to={`/workspace/sales/so/${soId}`}>
                    <Button>Cancel</Button>
                </Link>
            </Space>
        }>
            <InvoiceForm ref={formRef} value={invoice} fulfillmentIds={fulfillmentIds} />
        </Card>
    )
}