import './error.css';
import { Result, Button } from '@arco-design/web-react';
import { useNavigate } from 'react-router-dom';

export const ERR404 = () => {
    const navigate = useNavigate();

    return (
        <div>
            <Result
                status='404'
                subTitle='Whoops, that page is gone. '
                extra={[
                    <Button key='home' style={{ margin: '0 16px' }} onClick={() => navigate("/workspace")}>
                        Home
                    </Button>,
                    <Button key='back' type='primary' onClick={() => navigate(-1)}>
                        Back
                    </Button>,
                ]}
            ></Result>
        </div>
    )
}