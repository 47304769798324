import { Button, Form, Input, Layout, Link, Message, Space, Typography } from "@arco-design/web-react"
import { Header } from '@/components/Header';
import { useLogin } from '@/pages/Login/useLogin';
import { useCallback, useRef, useState } from "react";
import { ContactAPI } from "@/apis";
import Captcha from "react-captcha-code";
import _ from "lodash";

const { Title, Paragraph } = Typography;
const FormItem = Form.Item;

export const ContactUs = () => {
    useLogin(true)

    const [submitting, setSubmitting] = useState(false);
    const [form] = Form.useForm();
    const captchaRef = useRef();
    const [captchaCode, setCaptchaCode] = useState();

    const handleCaptchaChange = useCallback((captcha) => {
        setCaptchaCode(captcha);
    }, []);

    const onSubmit = async () => {
        const values = await form.validate();

        try {
            setSubmitting(true);
            await ContactAPI.submitMessage(values);
            Message.success("Thank you for contacting us and we will reply to you ASAP.");
            form.clearFields();
            captchaRef.current.refresh();
        } catch (error) {
            Message.error("Error happened, please try again!");
        } finally {
            setSubmitting(false);
        }
    }

    return (
        <div className="container">
            <Layout>
                <Layout.Header>
                    <Header />
                </Layout.Header>
                <Layout.Content className="home-page">
                    <div style={{ marginLeft: 200, width: 500 }}>
                        <Title>Contact Us</Title>
                        <Paragraph>
                            Please feel free to reach out to us via the form below or contact us by:
                        </Paragraph>
                        <Paragraph>
                            Email: <a href="mailto:info@b-ee.net">info@b-ee.net</a>
                        </Paragraph>
                        <Paragraph>
                            Phone: 385-355-4686
                        </Paragraph>
                        <Form title="Contact Us" style={{ marginTop: 35 }} colon labelAlign="left"
                            form={form}>
                            <FormItem label="Name" field="name" required rules={[{ required: true }]}>
                                <Input />
                            </FormItem>
                            <FormItem label="Email" field="email" required rules={[{ required: true }, { type: 'email', message: "Invalid email format" }]}>
                                <Input />
                            </FormItem>
                            <FormItem label="Phone" field="phone" required rules={[{ required: true }]}>
                                <Input />
                            </FormItem>
                            <FormItem label="Message" field="message" required rules={[{ required: true }]}>
                                <Input.TextArea />
                            </FormItem>
                            <FormItem label="Captcha" field="captcha" required rules={[{ required: true }, {
                                validator: (v, cb) => {
                                    if (_.toLower(captchaCode) !== _.toLower(v)) {
                                        return cb("Wrong captcha code");
                                    }

                                    cb(null);
                                }
                            }]}>
                                <Input />
                            </FormItem>
                            <FormItem wrapperCol={{ offset: 5 }}>
                                <Space>
                                    <Captcha ref={captchaRef} charNum={6} onChange={handleCaptchaChange} />
                                    <Link onClick={() => captchaRef.current.refresh()}>Refresh</Link>
                                </Space>
                            </FormItem>
                            <FormItem wrapperCol={{ offset: 5 }}>
                                <Button type="primary" loading={submitting} onClick={onSubmit}>Submit</Button>
                            </FormItem>
                        </Form>
                    </div>
                </Layout.Content>
            </Layout>
        </div>
    )
}