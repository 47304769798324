export const ComponentConfig = {
    Form: {
        validateMessages: {
            required: (_, { label }) => label ? `${label} is required` : "field is required"
        }
    }
}

export const TablePaginationConfig = {
    pageSize: 20
}