import { Card, Space, Select } from "@arco-design/web-react";
import { UITable, UIText } from "@/components/UI";
import { useCallback, useEffect, useState } from "react";
import { SOFulfillmentAPI, InventoryAPI } from "@/apis";
import { ItemSelect } from "@/components/ItemSelect";
import { Link } from "react-router-dom";
import _ from "lodash";

const LotSelect = ({ itemId, onChange, ...props }) => {
    const [data, setData] = useState([]);

    const searchItem = useCallback(async () => {
        if (!itemId) {
            setData([]);
        } else {
            const result = await InventoryAPI.itemFulfilledLots(itemId);
            let lots = _.filter(result, item => !_.isEmpty(item))
            setData(lots.sort());
        }
    }, [itemId]);

    useEffect(() => {
        searchItem();
    }, [searchItem]);

    return (
        <Select {...props}
            onChange={onChange}
            showSearch
            filterOption={(inputValue, option) =>
                option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0}
            options={data}>
        </Select>
    )
}

export const Recall = () => {
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);

    const [itemId, setItemId] = useState();
    const [lot, setLot] = useState();

    const search = useCallback(async () => {
        if (!itemId) return;
        const result = await SOFulfillmentAPI.items(page, { itemId, lot, status: [1, 3] });
        setData(result.records);
        setTotal(result.total);
    }, [page, itemId, lot]);

    useEffect(() => {
        search();
    }, [search]);

    return (
        <Card title="Recall" extra={
            <Space>
                <ItemSelect style={{ width: 180 }} onChange={(val) => {
                    setItemId(val);
                    setLot();
                }} placeholder="Item Name" />
                <LotSelect style={{ width: 180 }} itemId={itemId} value={lot} onChange={setLot} placeholder="Lot" allowClear />
            </Space>
        }>
            <UITable style={{ marginTop: 10 }} columns={[
                { title: "Customer Name", dataIndex: "customerName" },
                { title: "SO#", render: (col, record) => <Link to={`/workspace/sales/so/${record.soId}`}>{record.soNo}</Link> },
                { title: "Fulfillment#", render: (col, record) => <Link to={`/workspace/sales/so-fulfillment/${record.soFulfillmentId}`}>{record.soFulfillmentNo}</Link> },
                { title: "Fulfillment Date", dataIndex: "fulfillmentDate" },
                { title: "Qty", render: (col, record) => <UIText type="number" value={record.qtyFulfilled} /> },
            ]}
                data={data} total={total} onChange={setPage}
            />
        </Card>
    );
}