import './style/App.css';
import '@arco-design/web-react/dist/css/arco.css';
import { BrowserRouter } from 'react-router-dom';
import Routers from "./routers";
import { ComponentConfig, TablePaginationConfig } from './components/UI/config';
import { ConfigProvider } from '@arco-design/web-react';
import enUS from "@arco-design/web-react/es/locale/en-US";

function App() {
  return (
    <div className="App">
      <ConfigProvider componentConfig={ComponentConfig}
        locale={enUS}
        tablePagination={TablePaginationConfig}>
        <BrowserRouter>
          <Routers />
        </BrowserRouter>
      </ConfigProvider>
    </div>
  );
}

export default App;
