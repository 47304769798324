import { Button, Card, Descriptions, Message, Modal, Space, Upload } from "@arco-design/web-react";
import { UITable, UIText, UITag, UIDatePicker, UIEditableTable } from "@/components/UI";
import { useCallback, useEffect, useRef, useState } from "react";
import { VendorAPI } from "@/apis";
import { baseUrl } from "@/utils/api";
import Status from "@/components/Status";
import { IconBook, IconEdit, IconUpload } from "@arco-design/web-react/icon";
import { VendorForm } from "./vendor-form";
import Address from "@/components/Address";

const AddVendorButton = ({ onOk }) => {
    const [visible, setVisible] = useState(false);
    const [vendor, setVendor] = useState();
    const formRef = useRef();

    const submit = async () => {
        try {
            const values = await formRef.current.getFormData();
            const vendorId = await VendorAPI.add(values);
            Message.success("Vendor created successfully");
            setVisible(false);
            onOk && onOk(vendorId);
        } catch (error) {
            Message.error(error.message);
        }
    }

    return (
        <>
            <Button type="primary" onClick={() => {
                setVendor({
                    status: 1,
                    term: 1,
                    contacts: [{}],
                    shipAddresses: [{}]
                });
                setVisible(true);
            }}>Add New Vendor</Button>
            <Modal title="Add New Vendor" visible={visible} style={{ width: 1000 }}
                onCancel={() => setVisible(false)}
                onOk={() => submit()}>
                <VendorForm ref={formRef} value={vendor} />
            </Modal>
        </>
    )
}

const EditVendorButton = ({ vendorId, onOK }) => {
    const [visible, setVisible] = useState(false);
    const [vendor, setVendor] = useState();
    const formRef = useRef();

    const submit = async () => {
        try {
            const values = await formRef.current.getFormData();
            await VendorAPI.update(values.id, values);
            Message.success("Vendor updated successfully");
            onOK && onOK();
            setVisible(false);
        } catch (error) {
            Message.error(error.message);
        }
    }

    return (
        <>
            <Button type="text" size="small" icon={<IconEdit />} onClick={async () => {
                let values = await VendorAPI.get(vendorId);
                setVendor(values);
                setVisible(true);
            }}>Edit</Button>
            <Modal title={vendor?.vendorName} visible={visible} style={{ width: 1000 }}
                onCancel={() => setVisible(false)}
                onOk={() => submit()}>
                <VendorForm ref={formRef} value={vendor} />
            </Modal>
        </>
    )
}

const VendorInfoButton = ({ vendorId, children }) => {
    const [vendor, setVendor] = useState({});
    const [visible, setVisible] = useState(false);

    const getVendor = async () => {
        const info = await VendorAPI.get(vendorId);
        setVendor(info);
    }

    return (
        <>
            <Button type="text" onClick={() => {
                getVendor();
                setVisible(true);
            }}>{children}</Button>
            <Modal title={vendor?.vendorName}
                visible={visible}
                style={{ width: 1000 }}
                onCancel={() => setVisible(false)}
                footer={null}
            >
                <Descriptions column={2} colon=":"
                    labelStyle={{ paddingRight: 36 }}
                    data={[{
                        label: "Vendor Name",
                        value: vendor.vendorName
                    }, {
                        label: "Term",
                        value: vendor.termDesc
                    }]} />
                <Descriptions column={1} layout="vertical" colon=":"
                    labelStyle={{ paddingRight: 36 }}
                    data={[
                        {
                            label: "Main Contact",
                            value: <UITable columns={[
                                { title: "First Name", dataIndex: "firstName" },
                                { title: "Last Name", dataIndex: "lastName" },
                                { title: "Tags", render: (col, record) => <UITag type="tag" value={record.tags} /> },
                                { title: "Email", dataIndex: "email" },
                                { title: "Phone", dataIndex: "phone" },
                            ]} data={vendor.contacts} />
                        }
                    ]} />
                <Descriptions column={1} colon=":"
                    labelStyle={{ paddingRight: 36 }}
                    data={[
                        {
                            label: "Billing",
                            value: <Address value={{
                                address: vendor.billingAddress,
                                city: vendor.billingCity,
                                state: vendor.billingState,
                                zip: vendor.billingZip
                            }} inline />
                        }
                    ]} />
                <Descriptions column={1} layout="vertical" colon=":"
                    labelStyle={{ paddingRight: 36 }}
                    data={[
                        {
                            label: "Shipping",
                            value: <UITable columns={[
                                { title: "Ship to Name", dataIndex: "contactName" },
                                { title: "Address", dataIndex: "address" },
                                { title: "City", dataIndex: "city" },
                                { title: "State", dataIndex: "state" },
                                { title: "Zip", dataIndex: "zip" },
                            ]} data={vendor.shipAddresses} />
                        }
                    ]} />

            </Modal>
        </>
    )
}

const VendorDocButton = ({ vendorId }) => {
    const [visible, setVisible] = useState(false)
    const [data, setData] = useState([]);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const getDocs = async () => {
        let docs = await VendorAPI.getDocuments(vendorId);
        setData(docs);
    }

    const afterDocUpload = (files, file) => {
        switch (file.status) {
            case "error":
                setConfirmLoading(false);
                Message.error("Document upload failed");
                break;
            case "done":
                setConfirmLoading(false);
                getDocs();
                Message.success("Document upload successfully");
                break;
            default:
                break;
        }
    }

    const updateDoc = async (row) => {
        await VendorAPI.updateDocument(row.id, row);
        Message.success("Document updated successfully");
        getDocs();
    }

    const deleteDoc = async (id) => {
        await VendorAPI.deleteDocument(id);
        Message.success("Document deleted successfully");
        setData(data.filter(item => item.id !== id));
    }

    const columns = [
        { title: "Name", dataIndex: "document.docName" },
        {
            title: "Expiration Date", dataIndex: "expiredDate",
            editableRender: (col, record) => (
                <UIDatePicker style={{ width: 200 }} />
            )
        },
    ];

    return (
        <>
            <Button type="text" size="small" icon={<IconBook />}
                onClick={() => {
                    getDocs();
                    setVisible(true);
                }}>Document</Button>
            <Modal title="Vendor Documents" visible={visible}
                style={{ width: 700 }}
                onCancel={() => setVisible(false)}
                footer={
                    <Space>
                        <Button onClick={() => setVisible(false)}>Close</Button>
                        <Upload withCredentials={true} showUploadList={null}
                            headers={{ 'falcontoken': localStorage.getItem("login.token") }}
                            action={`${baseUrl}/vendor/document?vendorId=${vendorId}`}
                            beforeUpload={() => setConfirmLoading(true)}
                            onChange={afterDocUpload}>
                            <Button type="primary" icon={<IconUpload />}
                                loading={confirmLoading}>Upload Document</Button>
                        </Upload>
                    </Space>
                }
            >
                <UIEditableTable columns={columns} data={data} rowKey="id"
                    onRowDelete={row => deleteDoc(row.id)}
                    onRowSave={updateDoc}></UIEditableTable>
            </Modal >
        </>
    );
}

export const Vendor = () => {
    const [statusParams, setStatusParams] = useState(1);
    const [data, setData] = useState();
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);

    const getVendors = useCallback(async () => {
        let res = await VendorAPI.query(page, statusParams);
        setTotal(res.total);
        setData(res.records);
    }, [page, statusParams])

    useEffect(() => {
        getVendors();
    }, [getVendors]);

    const Actions = () => {
        return (
            <Space>
                <Status.Select value={statusParams} style={{ width: 100 }} allowAll
                    onChange={values => setStatusParams(values)}
                />
                <AddVendorButton onOk={() => {
                    getVendors();
                }} />
            </Space>
        );
    };

    const columns = [
        {
            title: "Vendor Name",
            render: (col, record) => <VendorInfoButton vendorId={record.id}>{record.vendorName}</VendorInfoButton>
        },
        {
            title: "Status",
            render: (col, record) => <Status.Tag value={record.status}>{record.statusDesc}</Status.Tag>
        },
        {
            title: "Balance",
            render: (col, record) => (
                <UIText type="currency" value={record.balance} />
            )
        },
        {
            title: "Operations",
            width: 100,
            render: (col, record) => (
                <Space size="mini">
                    <VendorDocButton vendorId={record.id} />
                    <EditVendorButton vendorId={record.id} />
                </Space>
            )
        },
    ];

    return (
        <Card title="Vendor" extra={<Actions />}>
            <UITable columns={columns} data={data} total={total}
                onChange={(pageNum) => {
                    setPage(pageNum);
                }} />
        </Card>
    );
}